.symptom-gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  width: 100%;
}
