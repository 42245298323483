@media (min-width: 1280px) {
  .page-container {
    .content-wrap {
      .order-kit-page {
        .contact-us-form {
          .contact-us-form-items {
            form {
              > div {
                &:nth-child(8) {
                  margin-top: 10px;
                  grid-row: auto;
                  grid-column: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
