@media (min-width: 1280px) {
  .cookie {
    display: flex;
    margin: 55px 65px 36px 150px;

    div{
      margin-right: 65px;

      p,
      a {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .button-container {
      button {
        width: 255px;
      }
    }


  }
}
