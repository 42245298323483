.footer {
  background: white;
  color: $black-1;
  padding: 50px 25px 42.5px;

  .disclaimer {
    &.situational {
      margin: 0 0 25px 0;

      p {
        font-weight: 500;
      }
    }

    p,
    a {
      color: #003565;
      font-family: 'Interstate';
      font-weight: normal;
      letter-spacing: 0;
      opacity: .5;
      font-size: 12px;
      line-height: 20px;
    }

    a {
      text-decoration: underline;
    }
  }

  p,
  a {
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    text-decoration: none;
    color: $black-1;
  }

  .bottom {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    border-top: 2px solid rgba(0, 99, 190, .5);
    padding-top: 25px;
  }

  .logo {
    display: flex;
    margin-bottom: 25px;

    img {
      max-width: 240px;
      min-width: 240px;
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .links {
    margin-top: calc(25px - 7.5px);
    display: flex;
    flex-wrap: wrap;

    a {
      padding: 0;
      color: #0063BE;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    div {
      display: flex;
      font-size: 12px;
      line-height: 19px;
      width: 50%;

      &:last-child {
        a {
          &::after {
            content: "";
            margin: 0;
          }
        }
      }
    }
  }
}

.preparation-container {
  display: flex;

  .date {
    p {
      font-family: 'Interstate';
      color: rgba(0, 53, 101, .5);
      line-height: 20px;
    }
  }

  .legal-number {
    font-size: 12px;
    line-height: 20px;
    font-family: 'Interstate';
    font-weight: normal;
    margin-left: 20px;
    color: rgba(0, 53, 101, .5);

    &:empty {
      margin: 0;
    }
  }
}
