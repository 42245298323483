$card-wrapper-gap: 30px;
$border-padding-on-side: Max(40px, unquote("(100vw - 1400px) / 2"));

@media (min-width: 1280px) {
  .dynamic-page {
    display: flex;

    .lower-section {
      width: 100%;
      max-width: 1400px;

      .logo-container {
        height: 388px;
        width: 388px;
      }

      &.no-max-width {
        .below-hero {
          padding: 50px $border-padding-on-side;

          &-inner-container {
            max-width: 935px;

            h4 {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }

        .condition-select {
          padding: 0 0 50px;
          flex-direction: column-reverse;
          gap: 50px;

          min-height: 550px;
          max-height: unset;
          height: unset;

          .condition-select-inner-container {
            max-width: 1400px;
            margin-top: 0;

            h1 {
              margin-bottom: 20px;
              line-height: 50px;
              font-size: 42px;
            }

            .select {
              max-width: 500px;
              min-width: 500px;
              margin-inline: auto;
            }

            .subtitle {
              display: flex;
              flex-direction: column;
              gap: 20px;
              justify-content: center;
              align-items: flex-start;

              .condition-select-body {
                p {
                  margin: 0;
                  font-size: 18px;
                  line-height: 30px;
                  color: #003565;
                  font-weight: normal;
                }
              }
            }
          }

          .hero-section-image-container {
            display: block;
            width: 100%;
            object-position: unset;
            max-width: 100%;
            object-fit: unset;
            min-height: unset;
            position: relative;

            max-height: 100%;
            overflow: hidden;
            height: unset;

            &::before {
              content: "";
              background: transparent linear-gradient(
                              91deg, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
              top: 0;
              left: 0;
              bottom: 0;
              width: calc(1356 / 1920 * 100%);
              position: absolute;
            }

            &::after {
              content: "";
              bottom: 0;
              left: 0;
              right: 0;
              height: 25px;
              width: 100%;
              position: absolute;
              background: linear-gradient(
                              180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 67%, rgba(255,255,255,1) 100%);
            }

            .hero-section-image {
              max-width: 100%;
              object-position: unset;
              aspect-ratio: 1920 / 551;
              //min-height: 550px;
              min-height: unset; //TODO remove above
              height: unset;
              max-height: unset;
            }
          }
        }

        .section-article-container {
          padding: 50px $border-padding-on-side;

          .section-article-inner-container {
            margin: 0 auto;
            min-width: 935px;
            max-width: 1400px;

            .section-article-title,
            .section-article-description {
              max-width: 935px;
            }

            .section-article-title {
              gap: 10px;

              .section-article-string-1 {
                font-size: 18px;
                line-height: 21px;
              }

              .section-article-string-2 {
                font-size: 36px;
                line-height: 44px;
              }
            }

            .section-article-description {
              & > div {
                &:not(.cta-small-container) {
                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }

              .cta-small-container {
                padding-top: 30px;
              }
            }
          }
        }

        .text-left-symptom-carousel-right {
          padding: 50px $border-padding-on-side;

          .text-left-symptom-carousel-right-inner {
            max-width: 1400px;
            gap: 90px;
            flex-direction: row;
            align-items: center;

            &.flex-row-reverse {
              flex-direction: row-reverse;
            }

            .left-side-container {
              display: flex;
              flex-direction: column;
              gap: 70px;
              width: 100%;

              .cta-small-container {
                padding-top: 10px;

                a {
                  color: $orange-1;
                  font-size: 16px;
                  line-height: 19px;
                  letter-spacing: 0;
                  font-weight: normal;
                  text-transform: uppercase;

                  p {
                    padding-right: 10px;
                    line-height: 22px;
                  }
                }
              }

              .section-article-container {
                padding: 0;

                .section-article-description {
                  & > div {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                  }

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }

              & > section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h4 {
                  font-size: 36px;
                  line-height: 44px;
                }

                & > div {
                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }

            & > section {
              width: 100%;

              &:last-child {
                & > div {
                  border-radius: 20px;
                }

                .symptom-gallery-container {
                  padding: 40px;
                  gap: 20px;

                  .symptom-gallery-item {
                    width: 100%;

                    img {
                      max-width: 120px;
                      max-height: 120px;
                    }

                    .item-title {
                      font-size: 24px;
                      line-height: 30px;
                      margin-left: 45px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2n) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #FFF;
                  }
                }
              }
            }
          }

          &:nth-child(2n + 1) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #F4F8FA;
                  }
                }
              }
            }
          }

          &.accordion-carousel {
            .text-left-symptom-carousel-right-inner {
              & > section {
                .symptom-gallery-container {
                  padding: 0;

                  .symptom-gallery-item {
                    .no-link-image {
                      padding-bottom: 20px;

                      .item-alt {
                        font-size: 18px;
                        line-height: 30px;
                        max-width: calc(100% - 120px);
                        margin-top: -400px;
                        margin-left: 10px;

                        a {
                          width: unset;
                        }
                      }

                      .item-title {
                        margin-left: 15px;
                        max-width: calc(100% - 120px);
                        font-size: 28px;
                        line-height: 32px;

                        &.open {
                          &.closing {
                            & ~ .item-alt {
                              margin-top: -400px;
                            }
                          }
                        }
                      }

                      img {
                        padding: 10px;
                        max-width: 95px;
                        max-height: 95px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .text-top-symptom-carousel-bottom {
          padding: 50px $border-padding-on-side;
          margin: 0;

          .text-top-symptom-carousel-bottom-inner {
            display: flex;
            flex-direction: column;
            gap: 60px;

            .top-side-container {
              display: grid;
              //grid-template-areas: "left right";
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: 44px 20px 1fr;
              gap: 50px;

              &.two-column-body {
                row-gap: 0;

                .simple-section {
                  display: none;
                  gap: 20px;

                  &:nth-child(1),
                  &:nth-child(2) {
                    display: flex;
                  }

                  &:nth-child(1) {
                    grid-area: 1 / 1 / 4 / 2;
                  }

                  &:nth-child(2) {
                    grid-area: 3 / 2 / 4 / 3;

                    .simple-section-title {
                      display: none;
                    }
                  }

                  .simple-section-title {
                    text-align: left;
                  }
                }
              }

              &.one-column-body {
                display: flex;

                .simple-section {
                  display: none;
                  align-items: center;

                  &:nth-child(1) {
                    display: flex;
                  }

                  .simple-section-title {
                    max-width: 730px;
                  }
                }
              }

              .simple-section {
                gap: 60px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                  text-align: center;
                }

                .description-container {
                  gap: 30px;

                  p,
                  li,
                  span,
                  a,
                  strong {
                    font-size: 18px;
                    line-height: 30px;
                  }

                  ul {
                    gap: 25px;
                  }
                }
              }
            }

            & > section {
              .symptom-gallery-container {
                grid-template-columns: repeat(3, 1fr);
                row-gap: 60px;
                column-gap: 50px;

                .symptom-gallery-item {
                  height: 100%;

                  .no-link-image {
                    flex-wrap: wrap;
                    gap: 20px;
                    align-items: flex-start;

                    img {
                      max-width: 95px;
                      max-height: 95px;
                    }

                    .item-title {
                      max-width: 100%;
                      width: 100%;
                      font-size: 28px;
                      line-height: 32px;
                      margin: 0;
                      height: 100%;
                    }

                    .item-alt {
                      font-size: 18px;
                      line-height: 30px;
                      height: 100%;

                      a {
                        width: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .simple-section-with-multiple-title-and-body {
          padding: 50px $border-padding-on-side;

          .simple-section {
            flex-direction: row;
            column-gap: 90px;
            flex-wrap: wrap;
            row-gap: 20px;

            .simple-section-title,
            .simple-section-subtitle {
              max-width: calc(100% / 2 - 90px / 2);
              width: 100%;
              font-size: 36px;
              line-height: 44px;
              letter-spacing: 0;
            }

            .description-container {
              flex-direction: row;
              gap: 90px;

              p,
              span {
                font-size: 18px;
                line-height: 30px;
              }

              .manual-body-1,
              .manual-body-2 {
                gap: 30px;
                max-width: calc(100% / 2 - 90px / 2);
              }
            }
          }
        }

        .content-top-multi-boxes-bottom-outer {
          padding: 50px $border-padding-on-side;

          .content-top-multi-boxes-bottom-inner {
            & > .simple-section {
              gap: 20px;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 30px;

                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }

            .box-wrapper {
              flex-direction: row;
              gap: 25px;

              & > .simple-section {
                gap: 70px;
                width: 100%;

                .simple-section-title {
                  font-size: 18px;
                  line-height: 30px;
                }

                .description-container {
                  padding: 30px;

                  height: 100%;

                  p,
                  span {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }

        .content-with-pros-and-cons-boxes-outer {
          padding: 50px $border-padding-on-side;

          .content-with-pros-and-cons-boxes-inner {
            gap: 60px;

            .row-wrapper {
              gap: 40px;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 28px;
                  line-height: 32px;
                }

                .simple-section-subtitle {
                  font-size: 18px;
                  line-height: 30px;
                }
              }

              .pros-cons-box {
                .header-wrapper {
                  padding: 15px 30px;

                  .simple-section-title {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }

                .description-container {
                  padding: 20px 30px;

                  ul {
                    flex-direction: row;
                    flex-wrap: wrap;
                    row-gap: 15px;
                    column-gap: 20px;

                    li {
                      font-size: 16px;
                      line-height: 22px;
                      width: 100%;
                      max-width: calc(100% / 3 - 20px * 2 / 3);
                    }
                  }
                }
              }
            }

            .content-wrapper {
              gap: 40px;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .simple-section-subtitle {
                  font-size: 18px;
                  line-height: 30px;
                }

                &:nth-child(2) {
                  .simple-section-title {
                    font-size: 28px;
                    line-height: 32px;
                  }
                }
              }
            }

            .box-wrapper {
              flex-direction: row;
              gap: 25px;

              .pros-cons-box {
                width: 100%;

                .header-wrapper {
                  padding: 15px 30px;

                  .simple-section-title {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }

                .description-container {
                  padding: 20px 30px;

                  ul {
                    li {
                      font-size: 16px;
                      line-height: 22px;
                    }
                  }
                }
              }
            }
          }
        }

        .single-card-gallery-outer-container {
          max-width: unset;
          margin: 0;
          padding: 50px $border-padding-on-side;
          border-radius: unset;

          .single-card-gallery-container {
            max-width: 1400px;
            gap: 30px;

            .single-card-text-container {
              padding: 0;

              .single-card-title {
                margin: 0 !important;
                font-weight: bold;
                font-size: 36px;
                letter-spacing: 0;
                line-height: 44px;
                color: #0063BE;
                text-transform: unset;
              }

              .single-card-description {
                p,
                span {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }

            .cards-wrapper {
              margin: 0;
              gap: 30px;
              justify-content: space-around;
            }
          }
        }

        .content-with-image {
          padding: 50px $border-padding-on-side;

          &.split-title {
            .content-with-image-inner-container {
              .text-column {
                .text-container {
                  h4 {
                    &.simple-section-subtitle {
                      font-size: 18px;
                      line-height: 21px;
                      order: 1;
                      margin-bottom: -10px;
                    }

                    &.simple-section-title {
                      order: 2;
                    }
                  }

                  .description-container {
                    order: 3;
                  }

                  .cta-small-container {
                    order: 4;
                  }
                }
              }
            }
          }

          .content-with-image-inner-container {
            max-width: 1400px;
            gap: 80px;
            align-items: center;

            .text-container {
              gap: 20px;

              & > * {
                display: none;
              }

              h4 {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 18px;
                  line-height: 30px;
                }

                ul {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    font-weight: normal;
                    font-size: 18px;
                    letter-spacing: 0;
                    line-height: 30px;
                    margin: 0;

                    strong {
                      font-weight: bold;
                    }
                  }
                }
              }
            }

            .image-container {
              display: flex;
              align-items: center;

              .styled-section-image {
                width: 100%;
              }
            }
          }

          &.image-on-left {
            .content-with-image-inner-container {
              flex-direction: row;
            }
          }

          &.image-on-right {
            .content-with-image-inner-container {
              flex-direction: row-reverse;
            }
          }

          &.tablet-image {

          }

          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: unset;
                margin: unset;

                .styled-section-image {
                  .styled-section-image-inner {

                    .plus-sign {
                      width: 33px;
                      max-width: unset;

                      &.left-plus-sign {
                        left: calc(-35 / 695 * 100%);
                      }

                      &.right-plus-sign {
                        right: calc(-35 / 695 * 100%);
                      }

                      &::after {
                        top: calc(50% - 6px);
                        height: 12px;
                      }

                      &::before {
                        left: calc(50% - 6px);
                        width: 12px;
                      }
                    }

                    .image-wrapper {
                      &::before {
                        top: 2px;
                        bottom: -12px;
                        left: -60px;
                        right: -60px;
                      }

                      img {
                        border-radius: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          &.mobile-image {
            .content-with-image-inner-container {
              .image-container {
                .styled-section-image {
                  max-width: 347px;
                  max-height: 591px;
                  min-width: 310px;

                  .styled-section-image-inner {
                    max-width: 347px;
                    max-height: 591px;

                    .image-wrapper {
                      width: 100%;
                      padding: 17px;

                      img {
                        object-fit: contain;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        & > .simple-section {
          gap: 30px;
          padding: 50px $border-padding-on-side;

          & > * {
            max-width: 1400px;

            &.cta-small-container {
              width: 100%;
            }

            p {
              font-size: 18px;
              line-height: 30px;
            }
          }

          .video-container {
            width: calc(100vw - 200px);
            margin-inline: auto;
          }

          iframe {
            max-width: 1400px;
            margin: 0;
          }
        }

        .full-width-content-with-cta {
          margin: 0;
          padding: 50px $border-padding-on-side;
          display: flex;

          & ~ .full-width-content-with-cta {
            margin-top: 0;
          }

          .full-width-content-with-cta-inner {
            max-width: 1400px;
            margin: 0 auto;
            padding: 50px 75px;
            background: #0063BE;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;

            & > section {
              display: flex;
              flex-direction: column;
              gap: 30px;

              h4 {
                color: #fff;
                text-align: left;
                font-size: 36px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 44px;
                margin: 0;
              }

              .description-container {
                margin: 0;

                p {
                  color: #fff;
                  font-size: 18px;
                  line-height: 30px;
                  letter-spacing: 0;
                  font-weight: normal;
                  margin: 0;
                }

                sup {
                  color: $orange-1;
                }
              }
            }

            .call-to-action-container {
              margin: 0;
              padding: 0;
              display: flex;
              gap: 25px;
              flex-direction: row;

              .cta-container {
                margin: 0;
                padding: 0;
                width: 100%;
                background: transparent;

                .cta-button {
                  background: #fff;
                  box-shadow: none;
                  border-radius: 20px;
                  padding: 11px 30px 10px;
                  width: auto;
                  min-width: 225px;
                  margin-inline: auto;
                  cursor: pointer;

                  span {
                    color: $orange-1;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0;
                    font-weight: normal;
                    text-transform: uppercase;
                    display: block;
                  }
                }

                &:last-child {
                  .cta-button {
                    background: $orange-1;

                    span {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }

        .content-with-multiple-images {
          padding: 50px $border-padding-on-side;
          gap: 25px;
          max-width: 1400px;
          flex-direction: row;
          align-items: center;

          &.image-in-right {
            flex-direction: row-reverse;
          }

          & > * {
            width: 100%;
          }

          .multi-image-container {
            gap: 50px;
            border-radius: 20px;

            .simple-section {
              div {
                &[data-rmiz-wrap="visible"] {
                  flex-direction: row;
                  gap: 80px;

                  img {
                    max-width: 241px;
                    width: 100%;
                  }

                  .single-card-image-text {
                    gap: 20px;
                    width: 100%;

                    .single-card-image-title {
                      font-size: 24px;
                    }

                    .single-card-image-alt {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }

          & > .simple-section {
            .description-container {
              max-width: 95%;

              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }

        #references {
          padding: 50px $border-padding-on-side;

          & > .MuiAccordion-root {
            max-width: 1400px;
            width: 100%;

            .MuiButtonBase-root {
              align-items: center;

              .MuiIconButton-root {
                display: inline-flex;
                margin-top: 0;
              }
            }

            h5 {
              font-size: 18px;
              line-height: 30px;
            }

            .MuiIconButton-root {
              margin-top: 10px;
            }

            ul {
              li {
                font-size: 16px;
                line-height: 25px;

                a {
                  color: $orange-1;
                }
              }
            }
          }
        }

        .attachment-section {
          padding: 50px $border-padding-on-side;
          margin: 0;

          .attachment-section-inner-container {
            max-width: 1400px;
            margin-inline: auto;

            & > * {
              max-width: 935px;
              margin-inline: auto;
              width: 100%;
            }

            .attachment-title {
              h4 {
                font-size: 36px;
                line-height: 44px;
              }
            }

            .attachment-body {
              & > div {
                p,
                span,
                strong,
                li {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }

            .attachment-cards-wrapper {
              margin: 30px auto 0;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              max-width: 1400px;

              .card-wrapper {
                max-width: calc(100% / 3 - 25px * 2 / 3);
                padding: 35px;
                width: 100%;

                .card {
                  height: 100%;

                  .link {
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    max-height: unset;
                    max-width: unset;

                    img {
                      width: 100%;
                      height: auto;
                      border-radius: unset;
                    }

                    .content {
                      margin-top: 10px;

                      .content-title {
                        font-size: 32px;
                        line-height: 39px;
                      }
                    }

                    .read-more {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .hero-section-text {
        position: relative;
        max-width: 100%;
        padding: 0;
        margin: 0;
        //min-height: 550px; //TODO remove
        max-height: unset;
        height: unset;

        .hero-section-text-inner-container {
          position: absolute;
          max-width: 530px;
          width: 100%;
          top: 0;
          bottom: 0;
          justify-content: center;
          left: Max(40px, unquote("(100vw - 1400px) / 2"));
          gap: 20px;
          z-index: 1;
          padding: 0;
          margin-top: 0;

          h1 {
            font-size: 40px;
            line-height: 48px;
          }

          .subtitle {
            font-size: 18px;
            line-height: 30px;
          }
        }

        .hero-section-image-container {
          max-height: 100%;
          overflow: hidden;
          height: unset;

          &::before {
            content: "";
            background: transparent linear-gradient(91deg, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
            top: 0;
            left: 0;
            bottom: 0;
            width: calc(1356 / 1920 * 100%);
            position: absolute;
          }

          &::after {
            content: "";
            bottom: 0;
            left: 0;
            right: 0;
            height: 25px;
            width: 100%;
            position: absolute;
            background: linear-gradient(
                            180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 67%, rgba(255,255,255,1) 100%);
          }

          .hero-section-image {
            max-width: 100%;
            object-position: unset;
            aspect-ratio: 1920 / 551;
            //min-height: 550px;
            min-height: unset; //TODO remove above
            height: unset;
          }
        }

        & + .simple-section {
          padding-inline: 0;
          max-width: 935px;
          margin-inline: auto;
          gap: 20px;

          .simple-section-title {
            font-size: 36px;
            line-height: 44px;
          }

          .description-container {
            p {
              font-size: 18px;
              line-height: 30px;
            }
          }
        }
      }

      #references {
        padding: 50px $border-padding-on-side;
        max-width: unset;

        & > .MuiAccordion-root {
          max-width: 1400px;

          .MuiButtonBase-root {
            align-items: center;

            .MuiIconButton-root {
              display: inline-flex;
              margin-top: 0;
            }
          }

          h5 {
            font-size: 18px;
            line-height: 30px;
          }

          .MuiIconButton-root {
            margin-top: 10px;
          }

          ul {
            li {
              font-size: 16px;
              line-height: 25px;

              a {
                color: $orange-1;
              }
            }
          }
        }
      }

      &.content-page,
      &.main-page {
        padding-bottom: 0;

        & > * {
          &:last-child {
            .cards-wrapper {
              margin-bottom: 0;
            }
          }
        }

        section {
          &.section-0 {
            margin-top: 100px;
          }
        }

        .carousel-container {
          max-width: unset;
          margin-top: 30px;

          .MuiBox-root {
            padding: 0;
            margin: 0 auto 50px;
            max-width: 935px;

            h4 {
              font-size: 36px;
              line-height: 44px;
              margin: 0 0 20px;
            }

            .body-18,
            p {
              font-size: 18px;
              line-height: 30px;
            }
          }

          .cards-wrapper {
            gap: 25px;
            padding: 0;

            &.single-card-wrapper {
              justify-content: center;
            }

            .card-wrapper {
              padding: 35px;
              border-radius: 10px;
              background: #fff;
              max-width: 450px;
              max-width: calc(100% / 3 - 25px * 2 / 3);

              .card {
                height: 100%;

                .link {
                  max-width: unset;
                  max-height: unset;
                  height: 100%;

                  picture {
                    max-width: 384px;
                    max-height: 311px;
                    width: 100%;
                    height: auto;

                    img {
                      object-fit: cover;
                      display: block;
                      width: 100%;
                      height: auto;
                      max-width: 384px;
                      max-height: 311px;
                      border-radius: unset;
                    }
                  }

                  .content {
                    position: relative;
                    margin: 33px 0 0;
                    padding: 0;

                    .content-title {
                      color: #0063BE;
                      font-size: 36px;
                      font-weight: bold;
                      letter-spacing: 0;
                      line-height: 44px;
                      text-align: left;
                      margin: 0;
                    }

                    .content-description {
                      margin: 14px 0 0;

                      color: #003565;
                      font-size: 18px;
                      letter-spacing: 0;
                      line-height: 30px;
                      font-weight: normal;

                      p,
                      span {
                        color: #003565;
                        font-size: 18px;
                        letter-spacing: 0;
                        line-height: 30px;
                        font-weight: normal;
                      }
                    }
                  }

                  .read-more {
                    padding-top: 35px;
                    margin-top: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .text {
                      color: $orange-1;
                      font-size: 16px;
                      font-weight: normal;
                      letter-spacing: 0;
                      line-height: 19px;
                      text-transform: uppercase;
                      margin-right: 5px;
                    }

                    .arrow-container {
                      color: $orange-1;
                      transition: margin-left .5s ease;
                      letter-spacing: 0;

                      img {
                        max-height: 11px;
                        max-width: 13px;
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    &:hover {
                      .arrow-container {
                        margin-left: .5em;
                      }
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2n + 1) { //even
            background: #fff;

            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;

                .card {
                  a {
                    .content {
                      background: #F4F8FA;
                    }
                  }
                }
              }
            }
          }

          & ~ .carousel-container {
            margin-top: 0;

            .MuiBox-root {
              padding-top: 0;
            }
          }
        }
      }

      &.main-page {
        margin-bottom: 50px;
        max-width: 100%;
        margin-inline: 0;

        .carousel-container {
          padding-inline: $border-padding-on-side;
        }
      }

      .gallery-items-outer-container {
        margin: 0;
        padding: 50px $border-padding-on-side;

        .gallery-items-inner-container {
          max-width: 935px;
          margin-inline: auto;
          gap: 20px;

          .section-article-container {

            .section-article-inner-container {
              .section-article-title {
                .section-article-string-2 {
                  font-size: 36px;
                  line-height: 44px;

                }
              }
            }
          }

          .symptom-gallery-container {
            gap: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .symptom-gallery-item {
              .no-link-image {
                gap: 30px;

                img {
                  display: block;
                  width: 100%;
                  height: auto;
                  max-width: 130px;
                  max-height: 130px;
                  margin: 0 0 auto;
                }

                .item-title {
                  font-size: 18px;
                  line-height: 30px;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .single-card-gallery-outer-container {
        a {
          sup {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      .simple-section {
        .simple-section-title {
          a {
            sup {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        .description-container {
          gap: 30px;

          ul {
            li {
              line-height: 30px;
              font-size: 18px;
            }
          }
        }
      }
    }

    &.dynamic-Enfermedad-de-Crohn-page {
      .lower-section {
        &.content-page {
          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: auto;
                    margin-inline: auto;
                    min-width: 225px;
                  }
                }
              }
            }
          }

          .single-card-gallery-outer-container {
            .single-card-gallery-container {
              gap: 30px;

              & > section {
                .cards-wrapper {
                  .card-wrapper {
                    padding-inline: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Nutrición-page {
      max-width: 100%;
      width: 100%;

      .lower-section {
        &.main-page {
          max-width: 100%;
          margin: 0;
          width: 100%;

          .carousel-container {
            padding: 50px $border-padding-on-side;
            margin: 0;

            .cards-wrapper {
              max-width: 1400px;
              margin-left: auto;
              margin-right: auto;
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    &.dynamic-Bienestar-y-apoyo-page,
    &.dynamic-Su-bienestar-page {
      .lower-section {
        &.main-page {
          .hero-section-text {
            & ~ .simple-section {
              gap: 20px;
              max-width: 935px;
              padding: 50px 0 0;
              margin-right: auto;
              margin-left: auto;

              h4 {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                p,
                span {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }
          }

          .carousel-container {
            background: transparent;
            margin-top: 50px;
            margin-bottom: 50px;

            .cards-wrapper {
              max-width: 1400px;
              margin: 0 auto;
              flex-wrap: wrap;
              padding-inline: 40px;

              .card-wrapper {
                background: #fff;
                max-width: calc(100% / 3 - 25px * 2 / 3);

                .card {
                  a {
                    .content {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-autoinmunes-page {
      .lower-section {
        &.content-page {

          & > .simple-section {
            padding: 50px $border-padding-on-side;
            max-width: unset;

            & > * {
              max-width: 935px;
              margin-inline: auto;
              width: 100%;

              &.simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              &.description-container {
                display: flex;
                flex-direction: column;
                gap: 30px;

                p,
                span,
                li,
                strong,
                a {
                  font-size: 18px;
                  line-height: 30px;
                  letter-spacing: 0;
                }

                a {
                  color: $orange-1;
                }

                strong {
                  font-weight: bold;
                }
              }
            }
          }

          .full-width-content-with-cta {
            padding: 50px $border-padding-on-side;
            background: #fff !important;

            .full-width-content-with-cta-inner {
              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: unset;
                    min-width: 225px;
                  }
                }
              }
            }
          }

          .content-with-simple-image {
            flex-direction: row;
            display: flex;
            gap: 90px;
            align-items: center;
            padding: 50px $border-padding-on-side;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .content-container {
              gap: 60px;

              .simple-section {
                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          .simple-section-with-multiple-title-and-body {
            background: #fff;

            & + .full-width-content-with-cta {
              background: transparent;
              padding-top: 0;
              padding-bottom: 50px;
            }
          }

          .text-top-symptom-carousel-bottom {
            .text-top-symptom-carousel-bottom-inner {
              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                        padding: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          .content-with-image {
            .content-with-image-inner-container {
              align-items: center;
            }
          }

          .content-top-multi-boxes-bottom-outer {
            padding-bottom: 40px;
            .content-top-multi-boxes-bottom-inner {
              .box-wrapper {
                & > .simple-section {
                  justify-content: space-between;

                  .description-container {
                    max-height: 300px;
                  }

                  .simple-section-title {
                    position: relative;
                    max-height: 90px;
                    height: 100%;

                    &::after {
                      content: "";
                      height: 16px;
                      width: 16px;
                      background: #0063BE;
                      position: absolute;
                      border-radius: 3px;
                      left: 40px;
                      bottom: -66px;
                    }

                    &::before {
                      content: "";
                      height: auto;
                      width: 4px;
                      background: #0063BE;
                      position: absolute;
                      border-radius: 3px;
                      left: 46px;
                      bottom: -66px;
                      top: 118px;
                    }
                  }

                  &:nth-child(even) {
                    .simple-section-title {
                      &::after {
                        background: #31B7CF;
                      }

                      &::before {
                        background: #31B7CF;
                        top: 30px;
                      }
                    }
                  }
                }
              }
            }

            & + .content-with-image {
              padding-top: 0;
              background: #fff;

              .content-with-image-inner-container {
                .text-column {
                  .text-container {
                    .simple-section-title {
                      display: none;
                    }
                  }
                }
              }

              & + .content-with-image {
                background: transparent;

                & + .content-with-simple-image {
                  background: #fff;

                  & + .content-with-image {
                    background: transparent;

                    & + .content-with-image {
                      background: #fff;
                    }
                  }
                }
              }
            }
          }

          .content-with-pros-and-cons-boxes-outer {
            padding-bottom: 60px;
            background: transparent;

            .content-with-pros-and-cons-boxes-inner {
              .box-wrapper,
              .row-wrapper {
                .pros-cons-box {
                  .description-container {
                    background: #fff;
                  }
                }
              }
            }

            & + .content-with-pros-and-cons-boxes-outer {
              padding-top: 0;
              background: transparent;
              padding-bottom: 60px;

              & + .simple-section {
                padding-top: 0;
                background: transparent;
              }
            }
          }

          #references {
            background: transparent;
          }

          /*.content-with-multiple-images {
            max-width: 100%;
            padding-inline: $border-padding-on-side;
          }*/
        }
      }
    }

    &.dynamic-El-microbioma-page {
      .lower-section {
        &.content-page {
          & > * {
            &:not(.hero-section-text) {
              padding: 50px $border-padding-on-side;
            }
          }

          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            gap: 80px;
            flex-direction: row;
            align-items: center;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .simple-image-container {
              [data-rmiz-wrap="visible"] {
                overflow-x: hidden;
                max-width: 100%;
                display: flex;
                flex-direction: column-reverse;
                gap: 10px;
                border-radius: 20px;


                .single-card-image-text {
                  display: flex;
                  width: 100%;
                  padding-inline: 25px;

                  .single-card-image-title {
                    font-size: 12px;
                    line-height: 15px;
                    margin-inline: auto;
                    width: 100%;
                    text-align: center;
                  }
                }

                img {
                  max-height: unset;
                  width: 100%;
                  height: auto;
                }
              }
            }

            .content-container {
              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }

            & + .content-with-image {
              & + .content-with-simple-image {
                .simple-image-container {
                  [data-rmiz-wrap="visible"] {
                    background: transparent;
                  }
                }
              }
            }
          }

          .single-card-outer-container {
            margin: 0;
            padding-bottom: 60px;

            .single-card-container {
              padding: 0;

              .single-card-inner-container {
                gap: 60px;
                flex-direction: column;

                [data-rmiz-wrap="visible"] {
                  gap: 10px;
                  flex-direction: column-reverse;
                  overflow-x: hidden;
                  max-width: 100%;
                  position: relative;
                  padding: 40px;
                  border-radius: 20px;
                  background: #fff;
                  align-items: center;

                  img {
                    border-radius: unset;
                    max-height: unset;
                    height: auto;
                    width: 100%;
                    max-width: unset;
                  }
                }

                .single-card-text-container {
                  column-gap: 40px;
                  row-gap: 20px;
                  max-width: unset;
                  flex-direction: row;
                  flex-wrap: wrap;

                  .single-card-title {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 !important;
                    max-width: 100%;
                    padding-right: calc(50% + 20px);
                  }

                  .single-card-description {
                    gap: 30px;
                    flex-direction: column;
                    max-width: calc(50% - 20px);

                    p {
                      font-size: 18px;
                      line-height: 30px;
                      width: 100%;
                    }

                    &:nth-of-type(1) {
                      margin-right: 10px;
                    }

                    &:last-child {
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            & + .content-with-simple-image {
              padding-top: 0;
              background: transparent;

              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  background: #fff;
                  border-radius: 20px;

                  img {
                    margin-inline: 25px;
                    margin-bottom: 10px;
                    max-width: calc(100% - 50px);
                    border-radius: unset;
                  }
                }
              }

              & + .content-with-image {
                background: #fff;

                & + .content-with-simple-image {
                  background: transparent;
                }
              }
            }
          }

          .simple-section-with-multiple-body {
            padding: 50px $border-padding-on-side;
            background: #fff;

            .simple-section {
              gap: 40px;

              [data-rmiz-wrap="visible"] {
                overflow-x: hidden;
                max-width: unset;
                width: 100%;
                align-items: center;

                img {
                  max-height: unset;
                  width: 100%;
                  height: auto;
                }
              }

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
                max-width: 935px;
                width: 100%;
                margin-inline: auto;
              }

              .description-container {
                gap: 15px;
                max-width: 935px;
                width: 100%;
                margin-inline: auto;

                p,
                span {
                  font-size: 18px;
                  line-height: 30px;
                }

                ul {
                  li {
                    font-size: 28px;
                    line-height: 32px;
                  }
                }
              }

              &:last-child {
                [data-rmiz-wrap="visible"] {
                  display: flex;
                  order: 2;
                }

                .description-container {
                  order: 1;
                }
              }

              &:nth-child(1) {
                [data-rmiz-wrap="visible"] {
                  display: none;
                }
              }
            }
          }

          #references {
            background: transparent;
          }
        }
      }
    }

    &.dynamic-the-microbiome-in-IBD-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            display: flex;
            flex-direction: row;
            gap: 80px;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .simple-image-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .simple-section-title {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                background: #F4F8FA;
                border-radius: 20px;

                img {
                  display: block;
                  height: auto;
                  width: 100%;
                }
              }
            }

            .content-container {
              gap: 50px;
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                }
              }

              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: auto;
                    min-width: 225px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Mi-tratamiento-page,
    &.dynamic-acceso-para-profesionales-de-la-salud-page,
    &.dynamic-kabicare-page {
      .lower-section {
        &.main-page {
          .hero-section-text {
            & + .simple-section {
              padding: 50px 0;
              max-width: 935px;
              margin-inline: auto;
            }
          }

          .carousel-container {
            .cards-wrapper {
              flex-wrap: wrap;

              .card-wrapper {
                background: #fff;

                .card {
                  a {
                    gap: 35px;

                    .content {
                      gap: 15px;
                      background: transparent;

                      .content-description {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Mi-tratamiento-page {
      .lower-section {
        &.main-page {
          .carousel-container {
            padding-top: 50px;
            padding-bottom: 50px;

            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;
              }
            }
          }
        }
      }
    }

    &.dynamic-idacio-page {
      .lower-section {
        &.main-page {
          .carousel-container {
            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;
              }
            }
          }

          .two-column-body {
            display: flex;

            section {
              &:nth-child(2){
                margin-top: 64px;
              }
            } 
          }

          h5, h4 {
            text-align: left;
          }
        }
      }
    }

    &.dynamic-acceso-para-profesionales-de-la-salud-page  {
      .lower-section {
        &.main-page {
          .section-article-container {
            max-width: 935px;
            margin-inline: auto;
            padding: 50px 0 50px;

            .section-article-inner-container {
              gap: 20px;

              .section-article-title {
                max-width: 100%;

                .section-article-string-1 {
                  font-size: 36px;
                  line-height: 44px;
                }
              }

              .section-article-description {
                gap: 20px;

                p {
                  font-size: 18px;
                  line-height: 30px;
                }

                ul {
                  li {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          & > .simple-section {
            gap: 20px;

            .description-container {
              gap: 30px;

              p,
              span {
                font-size: 18px;
                line-height: 30px;
              }

              ul {
                gap: 20px;
              }
            }
          }

          .carousel-container {
            padding-top: 50px;
            padding-bottom: 50px;

            .cards-wrapper {
              .card-wrapper {
                background: #F4F8FA;
              }
            }
          }
        }
      }
    }

    &.dynamic-kabicare-page {
      .lower-section {
        &.main-page {
          margin-bottom: 0;

          .hero-section-text {
            & + .simple-section {
              h4 {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 20px;

                p,
                li {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }
          }

          .carousel-container {
            padding-bottom: 50px;
            padding-top: 50px;

            .cards-wrapper {
              .card-wrapper {
                background: #f4f8fa;
              }
            }

            & + .simple-section {
              background: transparent;
              margin-inline: auto;
              padding: 50px 0 0;

              .simple-section-subtitle {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }
          }

          .content-with-image {
            padding: 50px $border-padding-on-side;
            margin: 0;
            max-width: 100%;

            .content-with-image-inner-container {
              flex-direction: row;
              gap: 80px;
              max-width: 1400px;
              margin-inline: auto;
              align-items: center;

              .text-column {
                width: 100%;

                .text-container {
                  gap: 20px;

                  .simple-section-title {
                    font-size: 36px;
                    line-height: 44px;
                  }

                  .description-container {
                    gap: 30px;

                    p,
                    span {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }
              }

              .image-container {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &.dynamic-Vida-saludable-page {
      .lower-section {
        &.main-page {
          max-width: 100%;

          .carousel-container {
            .cards-wrapper {
              max-width: 1400px;
              margin-inline: auto;

              .card-wrapper {
                width: 100%;
                max-width: calc(100% / 3 - 25px * 2 / 3);
              }
            }
          }
        }
      }
    }

    &.dynamic-Mindfulness-y-estrés-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              max-width: 935px;
              margin-inline: auto;
              gap: 20px;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }
            }
          }

          .text-left-symptom-carousel-right {
            .text-left-symptom-carousel-right-inner {
              section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    a {
                      .item-title {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }

                    .no-link-image {
                      .item-title {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Relaciones-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              max-width: 935px;
              margin-inline: auto;

              .simple-section-title {
                line-height: 44px;
                font-size: 36px;
              }

              .description-container {
                gap: 30px;
                display: flex;
                flex-direction: column;
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    max-width: 445px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Intimidad-page {
      .lower-section {
        &.content-page {
          flex-direction: row;
          flex-wrap: wrap;

          .hero-section-text {
            width: 100%;

            & + .simple-section {
              padding-inline: 0;
              max-width: 935px;
              margin-inline: auto;
              gap: 20px;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                p {
                  font-size: 18px;
                  line-height: 30px;
                  color: #003565;
                }
              }
            }
          }

          .content-with-simple-image {
            flex-direction: row-reverse;
            padding: 50px $border-padding-on-side;
            gap: 80px;
            width: 100%;

            & > * {
              max-width: 660px;
              width: 100%;
            }

            .simple-image-container {
              margin-right: auto;

              [data-rmiz-wrap="visible"] {
                margin: 0 !important;
                pointer-events: none;
                width: 100%;

                img {
                  border-radius: 20px;
                }
              }
            }

            .content-container {
              margin-left: auto;
              gap: 50px;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }

            & + .content-with-image {
              background: #fff;
              padding: 50px 40px 50px;
              width: 100%;

              .content-with-image-inner-container {
                gap: 80px;

                .image-container {
                  max-width: 660px;
                  width: 100%;
                }

                .text-column {
                  max-width: 660px;
                  width: 100%;

                  .text-container {
                    .description-container {
                      gap: 30px;

                      p {
                        font-size: 18px;
                        line-height: 30px;
                      }

                      ul {
                        gap: 15px;

                      }
                    }
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            width: 50%;
            background: #fff;
            padding: 0 calc(25px / 2) 50px 40px;


            & + .full-width-content-with-cta {
              padding-left: calc(25px / 2);
              padding-right: 40px;

              .full-width-content-with-cta-inner {
                margin-left: 0;
                margin-right: auto;
              }
            }

            .full-width-content-with-cta-inner {
              max-width: 695px;
              padding: 50px 60px;
              justify-content: space-between;
              margin-left: auto;
              margin-right: 0;

              .simple-section {
                .simple-section-title {
                  font-size: 24px;
                  line-height: 29px;
                }
              }

              .call-to-action-container {
                .cta-container {
                  width: unset;
                  margin-inline: auto;
                  min-width: 215px;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Afrontar-los-retos-page {
      .lower-section {
        &.content-page {
          & > .simple-section {
            width: 100%;
            padding-inline: 0;
            gaP: 20px;

            & > * {
              max-width: 935px;
              margin-inline: auto;
            }

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              gap: 30px;

              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }

          .full-width-content-with-cta {
            padding-inline: 40px;

            .full-width-content-with-cta-inner {
              .simple-section {
                h4 {
                  text-align: center;
                  width: 100%;
                }
              }

              .call-to-action-container {
                .cta-container {
                  min-width: 215px;
                  width: unset;
                  margin-inline: auto;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Apoyo-a-padres-o-cuidadores-page {
      .lower-section {
        &.content-page {
          & > .simple-section {
            gap: 20px;
            max-width: 935px;
            padding-inline: 0;
            margin-inline: auto;

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              gap: 30px;

              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }

          .text-left-symptom-carousel-right {
            .text-left-symptom-carousel-right-inner {
              gap: 160px;

              .left-side-container {
                max-width: 305px;
                width: 100%;

                .simple-section {
                  .description-container {
                    ul {
                      li {
                        line-height: 30px;

                        a {
                          font-size: 18px;
                          line-height: 30px;
                        }
                      }
                    }
                  }
                }
              }

              section  {
                max-width: 935px;
                width: 100%;

                .symptom-gallery-container {
                  gap: 30px;

                  .symptom-gallery-item {
                    .no-link-image {
                      .item-title {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                  font-size: 36px;
                  line-height: 44px;
                }
              }

              .call-to-action-container {
                .cta-container {
                  width: unset;
                  min-width: 215px;
                  margin-inline: auto;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Apoyo-comunitario-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            margin: 0;
            width: 100%;
            display: flex;
            gap: 80px;

            &.image-on-right {
              flex-direction: row-reverse;

              .simple-image-container {
                margin-left: 0;
                margin-right: auto;
              }

              .content-container {
                margin-left: auto;
                margin-right: 0;
              }
            }

            .simple-image-container {
              margin-left: auto;
              display: block;
              max-width: 660px;
              width: 100%;

              .simple-section-title {
                display: none;
              }
            }

            .content-container {
              max-width: 660px;
              width: 100%;
              margin-right: auto;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  p,
                  span,
                  a {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Apoyar-a-otras-personas-page {
      .lower-section {
        &.content-page {
          .simple-section {
            max-width: 100%;
            width: 100%;

            .simple-section-title {
              max-width: 935px;
            }

            .description-container {
              gap: 50px;

              & > * {
                max-width: 935px;
                margin-inline: auto;
                width: 100%;
              }

              .video-container {
                max-width: 1400px;
                width: calc(100vw - 200px);
              }

              span,
              a {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }

    &.dynamic-Hablar-con-su-equipo-asistencial-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            gap: 165px;
            align-items: center;

            &.image-on-right {
              flex-direction: row-reverse;

              .simple-image-container {
                margin-left: 0;
                margin-right: auto;
              }

              .content-container {
                margin-left: 0;
                margin-right: 0;
              }
            }

            .simple-image-container {
              margin-left: auto;
              margin-right: 0;

              .simple-section-title {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                max-height: 175px;
                max-width: 175px;
                min-width: 175px;
                width: 100%;

                img {
                  max-height: 175px;
                  max-width: 175px;
                  width: 100%;
                  height: auto;
                  display: block;
                }
              }
            }

            .content-container {
              margin-left: 0;
              margin-right: 0;
              max-width: 935px;
              width: 100%;

              .simple-section {
                .simple-section-title {
                  display: none;
                }

                .description-container {
                  max-width: unset;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }

                  ul {
                    li {
                      * {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Establecimiento-de-objetivos-page {
      .lower-section {
        &.content-page {
          flex-direction: row;
          flex-wrap: wrap;

          .hero-section-text {
            width: 100%;
          }

          .simple-section {
            width: 100%;
            padding-bottom: 50px;
            max-width: 1400px;

            .simple-section-title {
              max-width: 935px;
              margin-inline: auto;
            }

            .description-container {
              gap: 20px;
              max-width: 935px;
              margin-inline: auto;

              ul {
                li {
                  * {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          .full-width-content-with-cta {
            background: transparent;
            max-width: 50%;
            width: 100%;
            padding: 0 12.5px 50px $border-padding-on-side;


            .full-width-content-with-cta-inner {
              padding: 50px 60px;
              max-width: 695px;
              margin-left: auto;
              margin-right: 0;

              .simple-section {
                padding: 0;

                .simple-section-title {
                  font-size: 24px;
                  line-height: 29px;
                }
              }

              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    width: unset;
                    min-width: 215px;
                  }
                }
              }
            }

            & + .full-width-content-with-cta {
              padding: 0 $border-padding-on-side 50px 12.5px;

              .full-width-content-with-cta-inner {
                margin-left: 0;
                margin-right: auto;
              }
            }
          }
        }
      }
    }

    &.dynamic-Herramientas-de-coaching-page {
      .lower-section {
        background: #F4F8FA;
        max-width: 100%;
        width: 100%;

        & > * {
          max-width: unset;
          width: 100%;

          &:nth-child(odd) {
            background: #fff;
          }

          &:nth-child(even) {
            background: transparent;
          }
        }

        .coaching-tool-hero-section {
          padding: 25px 25px 50px;
          margin: 0;

          .simple-section-title {
            color: #00C2DF;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0;
            font-weight: bold;
            margin: 0;
          }
        }

        .section-article-container {
          padding: 50px $border-padding-on-side;

          h4 {
            text-align: left;
          }

          .section-article-inner-container {
            gap: 20px;
            max-width: 935px;
            margin-inline: auto;

            .section-article-title {
              gap: 10px;
              max-width: unset;

              .section-article-string-1 {
                font-size: 18px;
                line-height: 21px;
              }

              .section-article-string-2 {
                font-size: 36px;
                line-height: 44px;
              }
            }

            .section-article-description {
              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }

        .fullwidth-transparent {
          padding: 50px $border-padding-on-side;
          margin: 0;

          .single-card-gallery-container {
            gap: 50px;
            max-width: 1400px;
            margin-inline: auto;

            .single-card-text-container {
              gap: 20px;

              .single-card-title {
                margin: 0 !important;
                font-size: 36px;
                line-height: 44px;
              }

              .single-card-description {
                font-size: 18px;
                line-height: 30px;
              }
            }

            & > section {
              .cards-wrapper {
                margin: 0;
                gap: 25px;

                .card-wrapper {
                  border-radius: 20px;

                  .card {
                    flex: 1 0 227px;

                    .link {
                      picture {
                        aspect-ratio: 227 / 257;
                        border-radius: 20px 0 0 20px;
                      }
                    }
                  }

                  h4 {
                    padding: 94px 27px 25px 15px;
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: normal;
                    color: #003565;
                    font-style: italic;
                    position: relative;
                    text-align: left;

                    &::before {
                      font-size: 60px;
                      line-height: 85px;
                    }

                    &::after {
                      left: -30px;
                      width: 30px;
                    }
                  }
                }
              }
            }
          }

          & + .simple-section {
            padding: 0 $border-padding-on-side 50px;

            .description-container {
              max-width: 935px;
              margin-inline: auto;
              gap: 30px;

              p {
                font-size: 18px;
                line-height: 30px;
              }
            }

            & + .section-article-container {
              padding-bottom: 50px;

              & + .section-article-container {
                padding-bottom: 0;
                padding-inline: $border-padding-on-side;

                .column-container {
                  padding-bottom: 50px;

                  &::after {
                    left: 0;
                    right: 0;
                  }
                }
              }
            }
          }
        }

        .column-container {
          padding: 50px 40px;
          flex-direction: row;
          gap: 25px;
          background: #F4F8FA;

          &::after {
            left: Max(40px, unquote("calc((100vw - 1400px) / 2)"));
            right: Max(40px, unquote("calc((100vw - 1400px) / 2)"));
          }

          & > div {
            width: 100%;
            max-width: calc(1400px / 3 - 25px * 2 / 3);

            &:first-child {
              margin-left: auto;
            }

            &:last-child {
              margin-right: auto;
            }

            & > .MuiBox-root {
              flex-direction: column-reverse;
              gap: 25px;

              .text-container {
                .title {
                  font-size: 18px;
                  line-height: 30px;
                }

                .subtitle {
                  font-size: 24px;
                  line-height: 29px;
                }

                .body-18 {
                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }

              img {
                display: block;
              }
            }
          }
        }
      }
    }

    &.dynamic-Comprender-el-tratamiento-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              .simple-section-title {
                margin-top: 10px;
              }

              .simple-section-subtitle {
                font-size: 18px;
                line-height: 21px;
              }

              .cta-small-container {
                margin-top: 30px;

                a {
                  .cta-small {
                    font-size: 16px;
                    line-height: 19px;
                  }
                }
              }

              .description-container {
                gap: 30px;
                margin-top: 20px;
              }
            }
          }

          .content-with-simple-image {
            display: flex;
            padding: 50px $border-padding-on-side;
            flex-direction: row;
            gap: 130px;
            align-items: center;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .simple-image-container {
              margin-left: auto;
              margin-right: 0;
              max-width: 635px;
              width: 100%;

              [data-rmiz-wrap="visible"] {
                img {
                  display: block;
                  width: 100%;
                  height: auto;
                }

                .single-card-image-text {
                  width: 100%;

                  .single-card-image-title {
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                  }
                }
              }
            }

            .content-container {
              margin-left: 0;
              margin-right: auto;
              max-width: 635px;
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Comer-bien-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            gap: 80px;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            .simple-image-container {
              width: 100%;

              .single-card-image-text {
                width: 100%;

                .single-card-image-title {
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                }
              }
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    line-height: 30px;
                    font-size: 18px;
                  }
                }
              }
            }
          }

          .section-article-container {
            padding: 50px $border-padding-on-side;

            .section-article-inner-container {
              gap: 20px;
              max-width: 100%;

              .section-article-description {
                max-width: 100%;

                p,
                li,
                strong,
                span,
                a {
                  font-size: 18px;
                  line-height: 30px;
                }

              }

              .section-article-title {
                max-width: 100%;
              }
            }
          }

          .full-width-content-with-cta {
            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                }
              }
            }

            & + .section-article-container {
              .section-article-inner-container {
                margin-inline: auto;
                width: 100%;

                .section-article-description {
                  & > div {
                    ul {
                      margin-top: 20px;

                      li {
                        span {
                          font-size: 28px;
                          line-height: 32px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .text-top-symptom-carousel-bottom {
            .text-top-symptom-carousel-bottom-inner {
              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                        padding: 0;
                      }

                      .item-title {
                        height: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Mantenerse-activo-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              max-width: 1400px;
              margin-inline: $border-padding-on-side;

              .simple-section-title,
              .cta-small-container {
                max-width: 935px;
                margin-inline: auto;
                width: 100%;
              }

              .description-container {
                & > p {
                  max-width: 935px;
                  margin-inline: auto;
                  width: 100%;
                }

                .video-container {
                  width: calc(100vw - (2 * #{$border-padding-on-side}));
                }
              }

              .cta-small-container {
                a {
                  .body-18 {
                    margin-right: 10px;
                  }

                  .arrow-container {
                    color: $orange-1;
                    transition: margin-left .5s ease;
                    letter-spacing: 0;
                  }

                  &:hover {
                    .arrow-container {
                      margin-left: .5em;
                    }
                  }
                }
              }
            }
          }

          .simple-section {
            .description-container {
              display: flex;
              flex-direction: column;
              gap: 25px;
            }

            [data-rmiz-wrap="visible"] {
              overflow-x: hidden;
            }
          }

          .text-left-symptom-carousel-right {
            padding-bottom: 20px;
            padding-inline: $border-padding-on-side;

            .text-left-symptom-carousel-right-inner {
              gap: 30px;

              .left-side-container {
                gap: 0;
                width: 100%;
                max-width: calc(455 / 1400 * 100%);
                padding-bottom: 50px;
                border-bottom: 2px solid rgba(0, 99, 190, .5);

                .simple-section {
                  .simple-section-title {
                    width: 100%;
                  }

                  &:nth-child(2) {
                    border-top: 2px solid rgba(0, 99, 190, .5);
                    padding-top: 50px;
                    margin-top: 50px;
                  }

                  .description-container {
                    ul {
                      gap: 20px;

                      li {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }

              section {
                display: flex;
                align-items: center;

                .symptom-gallery-container {
                  background: transparent !important;
                  gap: 50px;
                  justify-content: unset;
                  padding: 0 0 20px;

                  .symptom-gallery-item {
                    max-width: calc(25% - 50px * 3 / 4);

                    .no-link-image {
                      flex-direction: column;
                      gap: 20px;

                      .item-title {
                        font-size: 19px;
                        line-height: 30px;
                        margin-inline: auto;
                        width: 100%;
                        text-align: center;
                      }

                      img {
                        margin: auto;
                        display: block;
                        max-width: 150px;
                        max-height: 100%;
                        width: 100%;
                        height: auto;
                      }
                    }
                  }
                }
              }
            }

            & ~ .text-left-symptom-carousel-right {
              .text-left-symptom-carousel-right-inner {
                gap: 30px;

                .left-side-container {
                  padding-top: 30px;
                  padding-bottom: 0;
                  border: unset;
                }

                & > section {
                  align-items: flex-start;

                  .symptom-gallery-container {
                    align-items: flex-start;
                    gap: 50px;
                    padding: 0;
                  }
                }
              }
            }
          }

          .content-with-image {
            &.notebook-image {
              & + .simple-section {
                padding-inline: $border-padding-on-side;
                gap: 0;
                background: #fff;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: $border-padding-on-side;
                  right: $border-padding-on-side;
                  border-top: 2px solid rgba(0, 99, 190, .5);
                }

                .simple-section-title {
                  max-width: 935px;
                  margin-inline: auto;
                  width: 100%;
                  font-size: 36px;
                  line-height: 44px;
                  letter-spacing: 0;
                  margin-bottom: 20px;
                }

                .description-container {
                  max-width: 935px;
                  margin-inline: auto;
                  width: 100%;
                  margin-bottom: 50px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 0;
                  }
                }

                [data-rmiz-wrap="visible"] {
                  img {
                    max-width: 100%;
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }
          }

          #references {
            padding: 50px $border-padding-on-side;
            background: transparent;
          }
        }
      }
    }

    &.dynamic-Viajar-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              & + .content-with-image {
                .content-with-image-inner-container {
                  .image-container {
                    align-items: center;
                  }
                }
              }
            }
          }

          .content-with-image {
            padding-inline: $border-padding-on-side;

            &.mobile-image {
              .content-with-image-inner-container {
                .image-container {
                  display: flex;
                  align-items: center;

                  .styled-section-image {
                    max-width: 347px;

                    .styled-section-image-inner {
                      .image-wrapper {
                        height: unset;

                        & > img {
                          width: 456px;
                          height: auto;
                          margin-left: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.notebook-image {
              padding-bottom: 50px;

              .content-with-image-inner-container {
                .text-column {
                  gap: 50px;
                }

                .image-container {
                  display: flex;
                  align-items: end;
                }
              }

              & + .simple-section {
                gap: 20px;
                padding-inline: $border-padding-on-side;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                  max-width: calc(50% - 40px);
                  width: 100%;
                }

                .description-container {
                  max-width: calc(50% - 40px);
                  width: 100%;
                  margin-left: 0;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }

            & + .simple-section {
              padding: 50px $border-padding-on-side;
            }
          }

          .full-width-content-with-cta {
            background: #fff;
            padding-top: 0;

            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                  max-width: 935px;
                  margin-inline: auto;
                }
              }

              .call-to-action-container {
                .cta-container {
                  .cta-button {
                    padding-inline: 46px;
                    width: unset;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Introducción-a-KabiCare-page {
      .lower-section {
        &.content-page {
          .content-with-image {
            &.mobile-image {
              .content-with-image-inner-container {
                .text-column {
                  .text-container {
                    .description-container {
                      ul {
                        li {

                          ul {
                            margin-top: 20px;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            li {
                              width: 100%;
                              max-width: calc(50% - 10px);

                              &::before {
                                margin-top: 7px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.tablet-image {
              & + .simple-section {
                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }
              }
            }
          }

          & > .simple-section {
            & + .content-with-image {
              background: #F4F8FA;
            }
          }
        }
      }
    }

    &.dynamic-Inyección-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            gap: 80px;
            justify-content: center;

            &.image-on-right {
              flex-direction: row-reverse;
            }

            &.image-on-left {
              flex-direction: row;
            }

            .simple-image-container {
              width: 100%;
              display: flex;

              [data-rmiz-wrap="visible"] {
                margin-inline: auto;
              }
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          .content-with-image {
            padding-inline: $border-padding-on-side;
          }

          & > .simple-section {
            & > * {
              max-width: 935px;
              padding-inline: 0;
              margin-inline: auto;
              width: 100%;
            }

            [data-rmiz-wrap="visible"] {
              overflow-x: hidden;

              img {
                max-width: 935px;
              }
            }
          }
        }
      }
    }

    &.dynamic-Guía-de-autoadministración-page {
      .lower-section {
        &.content-page {
          & > .hero-section-text {
            & + .simple-section {
              .description-container {
                gap: 20px;
              }

              & ~ .simple-section {
                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                  max-width: 935px;
                  width: 100%;
                }

                .simple-section-subtitle {
                  font-size: 18px;
                  line-height: 30px;
                  max-width: 935px;
                  width: 100%;
                }

                .description-container {
                  align-items: center;
                  & > * {
                    &:not(.video-container) {
                      max-width: 935px;
                      width: 100%;
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }

          .simple-section {
            .description-container {
              gap: 50px;

              .video-container {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-Ayuda-y-apoyo-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & ~ .simple-section {
              & > * {
                max-width: 935px;
                width: 100%;
                margin-inline: auto;
              }

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 20px;

                ul {
                  li {
                    p,
                    span,
                    strong {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-IBD-diets-page {
      .lower-section {
        &.content-page {
          flex-direction: row;
          flex-wrap: wrap;

          .hero-section-text {
            width: 100%;

            & + .simple-section {
              max-width: 100%;
              padding-inline: $border-padding-on-side;

              .simple-section-title,
              .simple-section-subtitle {
                max-width: 935px;
                margin-inline: auto;
              }

              .simple-section-subtitle {
                font-size: 18px;
                line-height: 30px;
                letter-spacing: 0;
                font-weight: normal;
                color: #003565;
              }

              .description-container {
                & > * {
                  &:not(.video-container) {
                    max-width: 935px;
                    margin-inline: auto;
                  }
                }
              }
            }
          }

          .simple-section {
            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .description-container {
              p,
              ul,
              li,
              span,
              strong {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }

          .content-with-multiple-images {
            padding: 50px $border-padding-on-side;
            max-width: 100%;

            row-gap: 40px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);

            & > .simple-section {
              &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
              }

              &:nth-child(2) {
                grid-area: 2 / 1 / 3 / 2;
              }
            }

            .multi-image-container {
              grid-area: 1 / 2 / 3 / 3;
            }
          }

          .full-width-content-with-cta {
            padding: 50px 12.5px 50px $border-padding-on-side;
            max-width: 50%;

            .full-width-content-with-cta-inner {
              .simple-section {
                .simple-section-title {
                  text-align: center;
                }
              }

              .call-to-action-container {
                .cta-button {
                  min-width: 215px;
                  width: unset;
                }
              }
            }

            & + .full-width-content-with-cta {
              padding: 50px $border-padding-on-side 50px 12.5px;
              background: #fff;
            }
          }

          #references {
            background: #F4F8FA;
          }
        }
      }
    }

    &.dynamic-idacio-page {
      .lower-section {
        &.main-page {
          margin-bottom: 0;

          .hero-section-text {
            & + .simple-section {
              padding: 50px $border-padding-on-side;
              max-width: 100%;

              .simple-section-title {
                margin-inline: auto;
                max-width: 935px;
                width: 100%;
              }

              .description-container {
                gap: 30px;
                margin-inline: auto;
                max-width: 935px;
                width: 100%;
              }
            }
          }

          .content-with-simple-image {
            background: #fff;
            padding: 50px $border-padding-on-side;
            flex-direction: row-reverse;
            gap: 180px;
            max-width: 100%;

            .simple-image-container {
              width: 100%;
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          .carousel-container {
            padding: 50px $border-padding-on-side;

            .cards-wrapper {
              .card-wrapper {
                background: #fff;
              }
            }
          }

          .text-top-symptom-carousel-bottom {
            .text-top-symptom-carousel-bottom-inner {
              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                        padding: 0;
                      }

                      .item-title {
                        height: unset;
                      }
                    }
                  }
                }
              }
            }
          }

          .text-left-symptom-carousel-right {
            padding: 50px 0;

            .text-left-symptom-carousel-right-inner {
              max-width: 100%;
              display: flex;
              width: 100%;
              padding: 0;
              margin: 0 auto;
              gap: 50px;
              flex-direction: row;
              align-items: center;
              max-width: 935px;

              & > section {
                .symptom-gallery-container {
                  .symptom-gallery-item {
                    .no-link-image {
                      img {
                      }

                      .item-title {
                        font-size: 14px;
                        line-height: 18px;

                        a {
                          display: inline-flex;
                          position: relative;
                          width: 12px;
                          height: 14px;

                          sup {
                            position: absolute;
                            margin-top: -3px;
                            top: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .text-left-symptom-carousel-right {
            .text-left-symptom-carousel-right-inner {
              .left-side-container {
                display: flex;
                flex-direction: column;
                gap: 50px;
                width: 100%;

                .cta-small-container {
                  padding-top: 5px;

                  a {
                    color: $orange-1;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0;
                    font-weight: normal;
                    text-transform: uppercase;

                    p {
                      padding-right: 10px;
                      line-height: 19px;
                    }
                  }
                }

                .section-article-container {
                  padding: 0;

                  .section-article-description {
                    & > div {
                      display: flex;
                      flex-direction: column;
                      gap: 20px;
                    }

                    p {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }

                & > section {
                  gap: 20px;

                  h4 {
                    font-size: 36px;
                    line-height: 44px;
                  }

                  & > div {
                    gap: 30px;

                    p {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }
              }

              & > section {
                width: 100%;

                &:last-child {
                  & > div {
                    border-radius: 10px;
                  }

                  .symptom-gallery-container {
                    padding: 25px;
                    gap: 15px;
                    display: flex;

                    .symptom-gallery-item {
                      width: 100%;

                      img {
                        max-width: 63px;
                        max-height: 63px;
                      }

                      .item-title {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 22px;
                        font-weight: bold;
                        margin-left: 20px;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2n) {
              .text-left-symptom-carousel-right-inner {
                & > section {
                  &:last-child {
                    & > div {
                      background: #fff;
                    }
                  }
                }
              }
            }

            &:nth-child(2n + 1) {
              .text-left-symptom-carousel-right-inner {
                & > section {
                  &:last-child {
                    & > div {
                      background: #f4f8fa;
                    }
                  }
                }
              }
            }

            &.accordion-carousel {
              .text-left-symptom-carousel-right-inner {
                &.flex-row-reverse {
                  flex-direction: row-reverse;
                }

                & > section {
                  .symptom-gallery-container {
                    background: transparent !important;
                    padding: 0;

                    .symptom-gallery-item {
                      border-bottom: 2px solid rgba(0, 99, 190, 0.5);

                      .no-link-image {
                        flex-wrap: wrap;
                        padding-bottom: 15px;
                        position: relative;

                        .item-alt {
                          font-size: 14px;
                          line-height: 18px;
                          max-width: 100%;
                          opacity: 0;
                          margin-top: -300px;
                          z-index: -1;

                          a {
                            display: inline-block;
                          }
                        }

                        .item-title {
                          max-width: 100%;
                          width: 100%;
                          cursor: pointer;
                          font-size: 18px;
                          line-height: 22px;

                          &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            width: 12px;
                            height: 12px;
                            margin-top: 2px;
                            border-top: 2px solid $orange-1;
                            border-left: 2px solid $orange-1;
                            transform: rotate(135deg);
                          }

                          &.open {
                            & ~ .item-alt {
                              opacity: 1 !important;
                              transition: opacity 1s linear 0.75s, margin-top 1s linear, z-index 0.1s linear;
                              margin-top: 0;
                              z-index: 1;
                            }

                            &::after {
                              transform: rotate(225deg);
                              transition-duration: 1s;
                            }

                            &.closing {
                              & ~ .item-alt {
                                opacity: 0 !important;
                                transition: opacity 0.25s linear, margin-top 1s linear 0.25s, z-index 0.1s linear;
                                margin-top: -300px;
                                z-index: 0;
                              }

                              &::after {
                                transform: rotate(135deg);
                                transition-duration: 1s;
                              }
                            }
                          }
                        }

                        img {
                          background: #fff;
                          padding: 5px;
                          max-width: 73px;
                          max-height: 73px;
                          border-radius: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Medicamentos-biológicos-y-biosimilares-page {
      .lower-section {
        &.content-page {
          .content-with-simple-image {
            padding: 30px $border-padding-on-side;
            margin: 0;
            background: transparent;
            display: flex;
            flex-direction: column-reverse;
            gap: 0;

            .simple-image-container {
              max-width: 935px;
              width: 100%;
              margin-inline: auto;
              display: flex;

              [data-rmiz-wrap="visible"] {
                margin: auto;
              }
            }

            .content-container {
              max-width: 935px;
              width: 100%;
              margin-inline: auto;
              gap: 25px;

              .simple-section {
                gap: 20px;

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }

            & + .simple-section {
              padding: 50px $border-padding-on-side;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
                max-width: 935px;
                width: 100%;
                margin-inline: auto;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 30px;
                max-width: 935px;
                width: 100%;
                margin-inline: auto;

                p,
                span {
                  font-size: 18px;
                  line-height: 30px;
                }

                ul {
                  gap: 20px;

                  li {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          & > .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              li {
                font-size: 18px;
                line-height: 30px;
                color: #003565;
              }

              ul {
                gap: 20px;
              }
            }
          }

          .single-card-gallery-outer-container {
            padding-inline: $border-padding-on-side;
            max-width: unset;

            .single-card-gallery-container {
              max-width: 935px;

              .single-card-text-container {
                .single-card-description {
                  gap: 30px;

                  p {
                    line-height: 30px;
                    font-size: 18px;
                    letter-spacing: 0;
                    color: #003565;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Datos-clínicos-page {
      .lower-section {
        &.content-page {
          & > .simple-section {
            padding-inline: $border-padding-on-side;

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
              max-width: 935px;
              width: 100%;
              margin-inline: auto;
            }

            .simple-section-subtitle {
              font-size: 24px;
              line-height: 29px;
              max-width: 935px;
              width: 100%;
              margin-inline: auto;
            }

            .description-container {
              gap: 30px;
              max-width: 935px;
              width: 100%;
              margin-inline: auto;

              p,
              span {
                font-size: 18px;
                line-height: 30px;
              }

              ul {
                gap: 20px;
              }
            }

            [data-rmiz-wrap="visible"] {
              max-height: unset;
              overflow-x: unset;

              img {
                max-height: unset;
                max-width: 935px;
              }
            }

            &:nth-of-type(4) {
              padding-bottom: 50px;
            }
          }

          .hero-section-text {
            & + .simple-section {
              padding-inline: 0;
            }
          }

          .content-with-simple-image {
            padding: 0 $border-padding-on-side 50px;
            flex-direction: row-reverse;
            gap: 56px;
            justify-content: center;

            .simple-image-container {
              width: 100%;
              max-width: calc((935px / 2) - (56px / 2));
            }

            .content-container {
              width: 100%;
              max-width: calc((935px / 2) - (56px / 2));

              .simple-section {
                gap: 20px;

                .simple-section-title {
                  font-size: 24px;
                  line-height: 29px;
                }

                .description-container {
                  gap: 30px;

                  p,
                  span {
                    font-size: 18px;
                    line-height: 30px;
                  }

                  ul {
                    gap: 20px;
                  }
                }
              }
            }

            & ~ .simple-section {
              padding-top: 50px;

              [data-rmiz-wrap="visible"] {
                img {
                  max-height: unset;
                  width: 100%;
                  height: auto;
                }
              }

              &:nth-of-type(6) {
                padding: 50px $border-padding-on-side;
              }

              &:nth-of-type(10) {
                padding-bottom: 50px;
              }
            }
          }

          .content-with-multiple-images {
            flex-direction: column;
            padding: 50px $border-padding-on-side;
            max-width: 100%;

            .multi-image-container {
              max-width: 935px;
              margin-inline: auto;
              width: 100%;

              .simple-section {
                [data-rmiz-wrap="visible"] {
                  overflow-x: unset;

                  img {
                    height: auto;
                    width: 100%;
                    max-height: unset;
                  }
                }
              }
            }

            & > .simple-section {
              gap: 20px;
              max-width: 935px;
              margin-inline: auto;
              width: 100%;

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .simple-section-subtitle {
                font-size: 24px;
                line-height: 29px;
              }

              .description-container {
                gap: 30px;

                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }

            & + .simple-section {
              padding: 50px $border-padding-on-side;
              gap: 20px;

              .description-container {
                gap: 20px;

                ul {
                  gap: 15px;
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Presentaciones-de-administración-page {
      .lower-section {
        &.content-page {
          .carousel-container {
            padding: 50px $border-padding-on-side;
            background: transparent;

            .cards-wrapper {
              .card-wrapper {
                background: #fff;

                .card {
                  .link {
                    .content {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }

          .simple-section {
            padding-inline: 0;

            .simple-section-subtitle {
              font-size: 24px;
              line-height: 29px;
            }

            & > * {
              width: 100%;
              max-width: 935px;
              margin-inline: auto;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 30px;

              p {
                font-size: 18px;
                line-height: 30px;
                margin: 0;
              }
            }
          }
        }
      }
    }

    &.dynamic-Apoyo-para-pacientes-page {
      .lower-section {
        &.content-page {
          .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              strong,
              li {
                font-size: 18px;
                line-height: 30px;
                color: #003565;
              }

              ul {
                gap: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-Inscripción-de-pacientes-page {
      .lower-section {
        &.content-page {
          .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              strong,
              li,
              a {
                font-size: 18px;
                line-height: 30px;
              }

              a {
                color: $orange-1;
              }

              ul {
                gap: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-Cuidado-de-su-medicamento-page {
      .lower-section {
        &.content-page {
          .gallery-items-outer-container {
            & + .gallery-items-outer-container {
              padding-bottom: 30px;

              & + .simple-section {
                .description-container {
                  max-width: 935px;
                  margin-inline: auto;

                  p,
                  span,
                  a {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Programa-KabiCare-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            & + .simple-section {
              .description-container {
                gap: 30px;
                display: flex;
                flex-direction: column;

                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }
          }

          .content-with-image {
            & ~ .simple-section {
              padding: 50px $border-padding-on-side;

              & > * {
                max-width: 935px;
                margin-inline: auto;
              }

              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }

              .description-container {
                gap: 30px;

                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }
          }

          #references {
            & + .attachment-section {
              border: unset;
              padding-bottom: 0;

              .attachment-section-inner-container {
                border-bottom: 2px solid rgba(0, 99, 190, 0.5);
                padding-bottom: 50px;
              }

              & + .attachment-section {
                padding-top: 50px;
              }
            }
          }
        }
      }
    }

    &.dynamic-non--infectious-uveitis-page {
      .lower-section {
        &.content-page {
          .section-article-container {
            padding: 50px $border-padding-on-side;
          }

          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            flex-direction: row;
            gap: 80px;

            .simple-image-container {
              width: 100%;
            }

            .content-container {
              width: 100%;

              .simple-section {
                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }
          }

          .content-with-image {
            .content-with-image-inner-container {
              .text-column {
                & > .simple-section {
                  &:nth-child(1) {
                    .cta-small-container {
                      display: none;
                    }
                  }
                }
              }

              .image-container {
                align-items: center;
                justify-content: center;

                .styled-section-image {
                  width: auto;

                  .color-rectangle {
                    display: none;
                  }

                  .styled-section-image-inner {
                    margin: 0;
                    padding: 0;
                    border: unset;
                    box-shadow: unset;
                    border-radius: unset;

                    .image-wrapper {
                      &::before {
                        display: none;
                      }
                    }

                    .plus-sign {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.dynamic-Mitos-y-verdades-page {
      .lower-section {
        &.content-page {
          & > .simple-section {
            max-width: 100%;
            padding: 50px $border-padding-on-side;

            & > * {
              max-width: 935px;
              margin-inline: auto;
              width: 100%;
            }

            .simple-section-title {
              font-size: 36px;
              line-height: 44px;
            }

            .simple-section-subtitle {
              font-size: 24px;
              line-height: 32px;
            }

            .description-container {
              p {
                font-size: 18px;
                line-height: 30px;
                letter-spacing: 0;
              }
            }
          }

          .content-with-simple-image {
            padding: 50px $border-padding-on-side;
            flex-direction: row;
            gap: 80px;
            align-items: center;

            .simple-image-container {
              width: 100%;

              [data-rmiz-wrap="visible"] {
                display: block;
                border-radius: 20px;

                img {
                  max-width: unset;
                  border-radius: 20px;
                }
              }
            }

            .content-container {
              width: 100%;

              .simple-section {
                gap: 10px;

                .simple-section-title {
                  font-size: 36px;
                  line-height: 44px;
                }

                .cta-small-container {
                  a {
                    p {
                      font-size: 18px;
                      line-height: 21px;
                    }
                  }
                }

                .description-container {
                  gap: 30px;

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }
            }

            &:nth-child(even) {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  background: #fff;
                }
              }
            }

            &:nth-child(odd) {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  background: #F4F8FA;
                }
              }
            }
          }

          .single-card-outer-container {
            margin: 0;
            padding: 50px $border-padding-on-side;

            .single-card-container {
              padding: 0;
              min-height: unset;
              border-radius: unset;

              .single-card-inner-container {
                gap: 60px;
                flex-direction: column;

                .single-card-text-container {
                  gap: 20px;
                  max-width: unset;

                  .single-card-title {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 !important;
                    max-width: calc(50% - 30px);
                  }

                  .single-card-description {
                    gap: 50px;
                    width: 100%;
                    flex-direction: row;

                    p {
                      font-size: 18px;
                      line-height: 30px;
                      width: 100%;
                    }
                  }
                }

                [data-rmiz-wrap="visible"] {
                  position: relative;
                  padding: 0;
                  bottom: unset;
                  top: unset;
                  right: unset;
                  z-index: unset;
                  width: 100%;
                  border-radius: 20px;

                  img {
                    border-radius: 20px;
                    max-width: unset;
                    width: 100%;
                  }
                }
              }
            }
          }

          .content-with-image {
            .content-with-image-inner-container {
              align-items: center;
            }
          }
        }
      }
    }

    &.dynamic-contactenos-page,
    &.dynamic-politica-de-cookies-page,
    &.dynamic-terminos-y-condiciones-page,
    &.dynamic-eventos-adversos-page,
    &.dynamic-politica-de-privacidad-page {
      .lower-section {
        &.content-page {
          .hero-section-text {
            padding: 50px $border-padding-on-side;
            min-height: unset;

            .hero-section-text-inner-container {
              padding: 0;
              position: relative;
              max-width: 935px;
              margin-inline: auto;
              top: unset;
              left: unset;
              bottom: unset;
            }
          }

          .simple-section {
            .description-container {
              gap: 30px;

              p,
              span,
              strong,
              a,
              li {
                font-size: 18px;
                line-height: 30px;
              }

              h4 {
                font-size: 30px;
                line-height: 36px;
              }

              h2 {
                font-size: 36px;
                line-height: 44px;
              }

              ul,
              ol {
                gap: 20px;
              }
            }
          }
        }
      }
    }

    &.dynamic-Hacer-una-solicitud-page {
      .lower-section {
        &.main-page {
          margin: 0;

          .carousel-container {
            background: #fff;
            padding: 50px $border-padding-on-side;

            .cards-wrapper {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .MuiPaper-root {
    ul {
      li {
        color: #003565;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0;
        font-weight: normal;
      }
    }
  }
}

.description-container,
.body-18,
.section-article-description,
.single-card-description,
.item-alt {
  sup {
    font-size: 10px !important;
    line-height: 14px !important;
    color: #003565;
  }
}

@media (min-width: 2799px) {
  .dynamic-page {
    &.dynamic-Establecimiento-de-objetivos-page {
      .lower-section {
        &.content-page {
          max-width: 1400px;

          .full-width-content-with-cta {
            padding-left: 0;

            & + .full-width-content-with-cta {
              padding-left: 15px;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
