.home {
  .upper-section {
    overflow: hidden;
    position: relative;
  }

  .hero {
    .hero-image-container {
      position: relative;
      aspect-ratio: 3/2;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        content: "";
        width: 100%;
        height: 8%;
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
        left: 0;
      }
    }

    .hero-img {
      z-index: -2;
      position: relative;
    }

    .ellipse,
    .ellipse-2 {
      background-color: $blue-6;
      height: 55vw;
      position: absolute;
      top: 67vw;
      left: -26.3%;
      width: 150%;
      border-radius: 50%;
      z-index: -1;
      overflow-x: hidden;
    }

    .ellipse-2 {
      left: -39%;
      top: 60vw;
      width: 150%;
      opacity: 0.5;
    }

    .fill {
      height: 400px;
      display: block;
    }
  }

  .hero-content {
    position: relative;
    padding: 25px 25px 20px;
    background-color: #fff;
    z-index: 2;
    color: $blue-1;
    font-weight: 300;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0;

    &-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #003565;

        & ~ a {
          margin-top: 15px;
        }
      }

      .site-title {
        margin: 0;
        color: #00c2df;
        line-height: 1;
        font-size: 30px;

        & + p {
          margin-top: 14px;
        }
      }

      .site-title + p,
      .cta-link-wrapper {
        margin: 0;

        a {
          text-align: center;
          display: inline-block;
          color: #fff;
          background: $orange-1;
          border-radius: 20px;
          text-transform: uppercase;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          padding: 11px 20px 10px;
          width: 100%;
        }
      }
    }
    

    img {
      width: 239px;
      height: 56px;
    }

    .go-down {
      display: none;
    }
  }

  .lower-section {
    background-color: white;

    img {
      border-radius: 30px;
    }

    .gallery {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      & > div {
        border-radius: 30px;
        margin: 13px 24px;
        display: block;
        max-height: 550px;
        max-width: 535px;
        position: relative;

        &.photo {
          max-height: unset;
          max-width: unset;
          height: 365px;
          width: 354px;
          cursor: pointer;

          picture {
            img {
              height: 365px;
              width: 354px;
              object-fit: cover;
            }
          }
        }

        img {
          display: flex;
          width: 325px;
          height: 325px;
        }

        & > .text {
          position: absolute;
          bottom: 0px;
          background: rgba(255, 255, 255, 0.85);
          border-radius: 0px 0px 29px 29px;
          width: 100%;
          padding: 12px 10.27px 13px 20.55px;
          margin: 0;
          min-height: 120px;

          h4 {
            margin: 0px 0;
          }

          p {
            margin: 4px 0 0 0;
            font-weight: 300;
            font-size: 18px;
            line-height: 26.5px;
          }
        }
      }

      .move {
        display: none;
      }
    }

    .home-page-carousel { 
      display: grid;
      grid-auto-flow: column;
      gap: 1rem;
      overflow: auto visible;
      position: relative;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scroll-padding: 1rem;
      max-width: fit-content;
      padding-left: 1rem;
      padding-right: 1rem;
      
      &::-webkit-scrollbar {
        width: 0; 
      }

      .carousel-item {
        border-radius: 10px;
        cursor: pointer;
        object-fit: contain;
        overflow: hidden;
        position: relative;
        width: 40vw;
        scroll-snap-align: start;
        position: relative;
        aspect-ratio: 16/8;

        picture {
          width: 100%;
          height: auto;
          left: 0;
          position: absolute;
          top: 0;
          z-index: 1;
          

          img {
            border-radius: unset;
            width: 100%;
            aspect-ratio: unset;
            height: auto;
            width: 100%;
          }
        }

        .text {
          display: flex;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
          background: linear-gradient(180deg , rgba(0,99,190,0) 35%, rgba(0,99,190,0.6) 100%);

          h2 {
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 25px;
            margin: auto auto 4px;
          }
        }
      }
    }
  }
}
