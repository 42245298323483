.page-container {
    .content-wrap {
        .goals {
            background-color: $grey-1;
            max-width: unset;
            
            .page-outer-container {
                max-width: 730px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                padding: 50px 0 20px;
            }

            .page-inner-container {
                .main-title {
                    margin: 0;
                    line-height: 1;
                    color: $blue-2;
                    font-size: 30px;
                    font-weight: 600;
                }
            } 

            .page-header {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding-bottom: 5px;
                width: 100%;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                .button-create {
                    min-height: 40px;
                    height: 40px;
                    font-size: 16px;
                    width: 100%;

                    span {
                        font-size: 16px;
                        letter-spacing: 0;
                    }
                }
            }

            .content-container {
                width: 100%;
            }

            .toolbox-list {
                max-width: unset;
                
                .Tabs {
                    button {
                        height: 50px;
                    }
                }

                .button-text {
                    background: transparent;
                    min-width: 120px;
                    width: unset;
                    height: 40px;
                    box-shadow: none;
                   
                    span {
                        color: $orange-1;
                        font-size: 16px;
                        letter-spacing: 0;
                    }
                }
            }

            .custom-goal-label {
                margin-bottom: 12px;
            }

            .subtitle {
                font-size: 16px;
                font-weight: 500;
                max-width: 595px;
                line-height: 1.3;
                letter-spacing: 0;

                * {
                    margin: 0;
                }
            }
        }
    }
}
