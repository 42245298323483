$gap: 16px;

.symptom-gallery-item {
  display: flex;

  a,
  .no-link-image {
    display: flex;
    flex-direction: row;
    align-items: center;

    .item-image {
      margin: 0 10px 0 0;
      width: 100px;
      height: 100px;
    }

    .item-title {
      color: $blue-1;
      font-size: 23px;
      letter-spacing: 0.4px;
      line-height: 34px;
      font-weight: 500;
    }
  }
}
