$mobile-menu: 28px;

.header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  z-index: 12;
  box-shadow: 4px 4px 10px rgba(24, 44, 76, 0.0993225);
  background-color: white;

  &.home {
    position: sticky;
    top: 0;
  }

  .side-container {
    display: flex;

    .left-side {
      margin: auto;

      img {
        width: 110px;
        height: auto;
      }
    }
  }

  .hamburger-menu {
    fill: $blue-1;
    margin: auto 7.4vw auto 0;

    &-icon {
      width: 20px;
      height: 16px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      z-index: 15;
    }

    &-icon span {
      border-radius: 999px;
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #0063be;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    &-icon span:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    &-icon span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      background-color: #00C2DF;
      transform-origin: left center;
    }

    &-icon span:nth-child(3) {
      bottom: 0;
      transform-origin: left center;
    }
  }

  .menu {
    background-color: white;
    min-height: 100vh;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 12;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      box-shadow: none;
      
      .side-container a img {
        opacity: 0;
        animation: fade-out 0.5s ease .5s forwards;
      }
    }

    &.slide-left {
      animation: slide-left-full 0.5s ease 1 normal;
    }

    &-links {
      padding-top: 38px;
      cursor: auto !important;

      .menu-item {
        padding: 0 $mobile-menu;

        p, a {
          font-size: 16px;
          margin: 0;
        }

        a {
          display: inline-block;
          margin: 12px 0;
        }
      }
      
      .MuiAccordionSummary-root {
        padding-right: calc(12px + #{$mobile-menu});
      }
    }

    .collapsible-menu {
      &-details {
        p, a  {
          color: #003565;
          margin: 8px 0;
        }

        .lvl1 {
          padding: 10px 0;
        }
      }

      .MuiAccordionSummary-expandIcon {
        color: #0063be;
      }

      .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(180deg) !important;
      }
    }

    .auth-button {
      padding: $mobile-menu;
      display: flex;
      flex-direction: column;
      gap: 15px;

      button {
        width: 100%;
        font-size: 16px;
        height: 40px;

        span {
          font-size: inherit;
        }
      }
    }
  }

  &.menu-open {
    padding: 0;
    position: sticky;
    top: 0;

    & ~ .content-wrap {
      z-index: -1;
    }

    .hamburger-menu-icon {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0px;
          left: 1px;
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 14px;
          left: 1px;
          background: #00C2DF;
        }
      }
    }
  }

  .left-side,
  .right-side,
  .menu {
    div,
    a {
      color: $blue-1;
      cursor: pointer;
      line-height: 22px;
      font-size: 18px;
      font-weight: 500;
      text-align: start;
      text-decoration: none;

      &:hover {
        color: $orange-1;
      }

      &.active {
        color: $orange-1;
      }
    }
  }

  .menu div {
    cursor: default;
  }

  .left-side {
    display: flex;
    padding: 0 0 0 24px;

    & > a {
      margin: auto;

      picture {
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .home-logo {
      display: flex;
    }

    .mobile-logo {
      display: flex;
      height: 71px;
      margin: 26px 0 18px 5.8vw;
      width: 71px;

      img {
        max-width: 100%;
      }
    }
  }

  .right-side {
    align-items: baseline;
    display: none;
    flex-direction: column;
    margin: 52px 0 42px 0;
    position: relative;

    .menu-element-levels {
      & > div {
        margin: 0 12px;

        &:nth-last-child(2) {
          margin-right: 24px;
        }

        &:hover {
          & > .label {
            color: $orange-1;
          }

          .level-2,
          .bridge {
            display: flex;
          }
        }

        & > .bridge {
          display: none;
          height: 120px;
          position: absolute;
          width: 120px;
          right: calc(50% - 60px);
        }
      }
    }

    .level-1 {
      z-index: 9;
    }

    .level-2 {
      background: $white-1;
      border-radius: 20px;
      flex-direction: column;
      right: calc(50% - 120px);
      margin-top: 40px;
      padding: 20px 0;
      position: absolute;
      width: 240px;

      &::before {
        border-color: transparent transparent $white-1 transparent;
        border-style: solid;
        border-width: 0 40px 40px 40px;
        content: "";
        left: calc(50% - 40px);
        position: absolute;
        top: -30px;
      }

      .label {
        display: flex;

        img {
          position: absolute;
          right: 20px;
          margin-top: -3px;
        }
      }

      & > div {
        padding: 10px 15px;

        &:hover {
          & > .label {
            color: $orange-1;
          }

          .level-3 {
            display: flex;
          }
        }
      }
    }

    .level-3 {
      background: linear-gradient(206.41deg, $blue-2 -43.36%, $blue-3 171.94%);
      border-radius: 10px;
      box-shadow: 0 4px 6px $black-2;
      flex-direction: column;
      left: 50px;
      padding: 20px 15px 20px 25px;
      position: absolute;
      top: 0;
      width: 200px;
      z-index: -1;

      & > div {
        color: $white-1;
        margin: 10px 0;
      }

      a {
        color: $white-1;

        &:hover {
          color: $orange-1;
        }
      }

      &:hover {
        display: flex;
      }
    }
  }
}


.backdrop-filter {
  backdrop-filter: blur(10px);
  background: $linear-blue-1;
}


