@media (min-width: 1280px) {
  .tab-data-wrapper {
    padding-top: 25px;

    .alternate-background {
      > div {
        .link-wrapper {
          padding: 40px;

          p {
            font-size: 18px;

            &.date {
              min-width: 150px;
            }
          }

          .link-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            font-size: 20px;

            svg {
              width: 1em;
            }
          }
        }
      }
    }
  }

  .page-container {
    .content-wrap {
      display: flex;

      main {
        flex: 1;
      }

      .medications {
        padding: 100px Max(40px, unquote("(100vw - 1400px) / 2"));
        max-width: unset;

        .page-outer-container {
          margin-inline: auto;
          max-width: 935px;

          .page-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 40px;

            .header-wrapper {
              gap: 20px;

              .page-inner-container {
                .main-title {
                  font-size: 40px;
                  line-height: 48px;
                }
              }

              .subtitle {
                font-size: 18px;
                line-height: 30px;
              }
            }

            button {
              width: unset;
              padding: 11px 20px 10px;
              margin-top: 0;
              min-width: 215px;
            }
          }

          .content-container {
            .toolbox-list {
              max-width: unset;

              .tab-data-wrapper {
                border-radius: 20px;
                padding: 40px;
                margin-top: 30px;
                width: 100%;

                .table-header-wrapper {
                  .table-header {
                    font-size: 36px;
                    line-height: 44px;
                  }

                  .table-column-row {
                    display: flex;

                    & > div {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }

                .alternate-background {
                  gap: 20px;
                  margin-top: 20px;

                  .MuiBox-root {
                    .link-wrapper {
                      p {
                        font-size: 18px;
                        line-height: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .goals {
        .page-outer-container {
          .content-container {
            .toolbox-list {
              .tab-data-wrapper {
                .table-empty-wrapper {
                  padding: 40px;
                  border-radius: 20px;

                  .table-empty {
                    .row-empty {
                      font-size: 18px;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .flare {
        position: absolute;
        right: -30px;
        top: -60px;
        margin: 0;

        & > div {
          padding: 36px 47px;

          &.open {
            padding: {
              right: 21px;
              left: 19px;
            }
          }
        }

        .align-center {
          text-align: center;
        }
      }

      .body-18 {
        text-align: left;

        &.text-align-center {
          text-align: center;
        }
      }

      .no-padding-mobile {
        padding: 0 165px;
      }

      .center-mobile {
        display: block;
      }

      .justify-flex-end {
        justify-content: flex-end;
        margin-left: auto;
      }

      .margin-left-auto {
        margin-left: auto;
      }

      .justify-end-align-center {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        justify-content: flex-end;
        align-items: center;
      }

      .space-between-desktop {
        display: flex;
        justify-content: space-between;
        width: inherit;
      }

      .mobile-breadcrumbs {
        display: none;
      }
    }
  }

  .form-container {
    .edit-form {
      max-width: 635px;
      width: 100%;

      button {
        width: 196px;
      }

      &.create {
        button {
          width: 255px;
        }
      }
    }
  }

  .Dialog__Toolbox {
    .MuiDialog-container {
      .MuiDialog-paper {
        .MuiDialogContent-root {
          & > .MuiBox-root {
            form {
              &.toolbox {
                &.medications {
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: 32px;

                  > div,
                  datepicker,
                  timepicker {
                    &:nth-child(1) {
                      grid-area: 1 / 1 / 2 / 3;
                    }
                    &:nth-child(2) {
                      grid-area: 2 / 1 / 3 / 3;
                    }
                    &:nth-child(3) {
                      grid-area: 3 / 1 / 3 / 2;
                    }
                    &:nth-child(4) {
                      grid-area: 3 / 2 / 4 / 3;
                    }

                    &.ActionButton__Wrapper {
                      grid-area: 4 / 1 / 5 / 3;
                    }
                  }

                  .TimePicker {
                    margin-top: 0;
                  }
                }
              }
            }

            form {
              &.toolbox {
                &.goals {
                  > div,
                  datepicker {

                    &:nth-child(1) {
                      grid-area: 1 / 1 / 2 / 3;
                    }

                    &.ActionButton__Wrapper {
                      grid-area: 4 / 1 / 5 / 3;
                    }
                  }

                  .TimePicker {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .column-reverse-mobile {
    flex-direction: row;
    margin-right: inherit;
    margin-left: inherit;
  }

  .toolbox-modal,
  .toolbox-modal.goals {
    .popup-button-container {
      button {
        margin: 23px 0 0 0;
        width: 255px;

        &.hide-mobile {
          display: block;
        }

        &.hide-desktop {
          display: none;
        }
      }
    }

    &.goals.delete {
      padding: 51px 51px 48px;
    }

    .modal-content {
      width: 329px;
    }
  }

  .ActionButton__Wrapper {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;

    button {
      max-width: 215px;

      &:nth-child(1) {
        order: 3;
      }

      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 1;
      }
    }
  }

  .ToggleCheckbox {
    .checkbox-label ~ .MuiTypography-body1 {
      font-size: 16px !important;
    }
  }

  .Dialog__Toolbox {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        h2 {
          font-size: 36px;
          margin-bottom: 10px;
        }
      }

      .MuiDialogContent-root {
        .MuiTypography-body1 {
          font-size: 18px;
          line-height: 1.5;
          max-width: 90%;
        }

        form {
          &.goals {
            &.edit-form {
              &>.MuiGrid-root {
                .DatePicker,
                .TimePicker {
                  max-width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.MuiDialog-scrollPaper {
  .MuiDialog-paper {
    width: 100%;
    max-width: 695px;
  }
}
