.cta {
  &-double-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 0;

    .cta-container {
      padding: 45px 30px;
      border-radius: 0;

      .cta-title {
        text-align: center;
      }

      &:last-of-type{
        background-color: $grey-2;
      }
    }
  }

  &-small-container {
    display: flex;
    padding-top: 10px;

    a {
      display: flex;
      align-items: center;
    }

     p {
       text-align: start;
       color: $orange-1;
       padding-right: 20px;
       font-weight: bold;
     }

  }

  &-container {
    text-align: center;
    align-items: center;
    background: $grey-1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 58px 30px;
  }

  &-title {
    margin-bottom: 26px;
    margin-top: 0;
  }

  &-description {
    color: $white-1;
    letter-spacing: 0.4px;
    margin-bottom: 35px;
    text-align: center;

    &:empty {
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  &-button {
    background: $orange-1;
    box-shadow: 0 2px 12px rgba(24, 44, 76, 0.139505);
    border-radius: 30px;
    padding: 15px 32px;
    max-width: 100%;
    min-width: 190px;
    text-align: center;

    span {
      color: $white-1;
      font-weight: bold;
      font-size: 21px;
      letter-spacing: 3.19565px;
      line-height: 25px;
    }
  }
}
