$card-wrapper-gap: 30px;

@media (min-width: 768px) {
  .dynamic-page {
    .MuiBreadcrumbs-root {
      display: block;
      margin-bottom: 64px;
      margin-top: 34px;
    }

    .upper-section {
      min-height: 415px;

      .hero {
        &-container {
          padding-bottom: 75px;

          .logo-container {
            display: block;
            height: 565px;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 63px;
            width: 565px;
            z-index: 1;
    
            img {
              height: auto;
              width: 100%;
            }
          }

          > .MuiFormControl-root {
            width: 350px;
          }
        }

        &-title {
          font-size: 55px;
          line-height: 50px;
        }
      }

      &::after {
        bottom: -71%;
        height: 90%;
      }
    }

    .lower-section {
      &.content-page {
        text-align: left;
      }

      &.main-page{
        text-align: left;
        display: block;
        padding-bottom: 108px;
      }

      .logo-container {
        display: block;
        height: 242px;
        width: 220px;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -41px;
        z-index: 1;

        img {
          height: auto;
          width: 100%;
        }
      }

      .MuiBreadcrumbs-root {
        display: block;
        margin-top: 25px;
        font-size: 20px;
      }

      .item-image {
        max-width: 1110px;
        max-height: 100%;
      }

      > div,
      > section {
        max-width: 1110px;
        margin: auto;
        margin-top: 42px;

        h4 {
          margin-block-start: 0;
        }
      }

      .column-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
        margin-top: 10px;
      }

      .white-card-split-section-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
      }

      .fullwidth-white {
        width: 100%;
        max-width: none;
        display: flex;
        justify-content: center;
        background: white;
        padding-bottom: 0;
      }

      .fullwidth-transparent {
        .single-card-gallery-container {
          padding: 0;
          background: transparent;

          .single-card-text-container {
            .single-card-description {
              font-weight: normal;
            }

            .single-card-title {
              margin-bottom: 9px !important;
              text-transform: unset;
              color: #0063BE;
              line-height: 26px;
              font-size: 20px;
            }
          }

          & > section {
            .cards-wrapper {
              gap: 30px;
              margin-top: 24px;

              .card-wrapper {
                max-width: 100%;
                flex: 1;

                .card {
                  picture {
                    img {
                      max-width: unset;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cards-wrapper {
        margin-bottom: 30px;
        justify-content: flex-start;
        column-gap: 32px;
        flex-direction: row;
        flex-wrap: wrap;

        .card-wrapper {
          display: flex;
          flex-direction: column;

          .card {
            margin: 0 auto 0 auto;

            .link {
              max-width: 350px;
              max-height: 350px;

              picture {
                max-width: 350px;
                max-height: 350px;

                img {
                  max-width: 350px;
                  max-height: 350px;
                }
              }
            }

            img {
              height: 288px;
              width: 288px;
            }
          }
        }
      }

      .single-card {
        &-gallery-container {
          .cards-wrapper {
            .card {
              display: flex;
              align-items: flex-end;

              img {
                height: auto;
                width: auto;
                max-width: 318px;
                border-radius: 0;
              }
            }
          }
        }
      }

      .section-article {
        &-title {
          margin-bottom: 26px;
        }

        &-string-2 {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .video-container {
        &.loaded {
          margin-bottom: 88px;
        }
      }

      .references {
        display: block;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }
}
