@media (min-width: 1280px) {
  .symptom-gallery-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.more-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
