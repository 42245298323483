@media (min-width: 1280px) {
  .page-container {
    .content-wrap {
      main {
        &.forgot-password,
        &.change-password {
          padding: 100px Max(calc((100vw - 1400px) / 2), 40px);
          max-width: 100%;

          .page-outer-container {
            gap: 35px;
            max-width: 935px;
            margin: 0 auto;

            .page-inner-container {
              h1 {
                font-size: 40px;
                line-height: 48px;
              }
            }

            .content-container {
              form {
                  .input-container {
                    max-width: 445px;
                  }

                  button {
                    padding: 11px 25px 10px;
                    max-width: 215px;
                    margin: 30px 0 0;
                  }
                }
            }
          }
        }
      }
    }
  }
}
