@media (min-width: 1280px) {
  .header {
    background: white;
    backdrop-filter: blur(20px);
    height: 100px;
    min-height: unset;
    padding: 12px 40px;
    box-shadow: unset;

    .side-container {
      display: flex;
      margin: 0 auto;

      .left-side {
        margin: auto 20px auto 0;
        padding: 0;
      }
    }

    .left-side .right-side {
      display: flex;
      max-width: fit-content;
    }

    .right-side {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 0;

      .menu-element-levels {
        align-items: center;
        display: flex;

        &:nth-last-child(2) {
          margin: 0 auto;

          & > div {
            margin: 0 8px;
          }
        }

        & > div {
          &:nth-last-child(2).account-button-container {
            margin-right: 16px;

            .account-button {
              width: 55px;
              height: 55px;
              min-width: 55px;

              &:hover {
                color: $orange-1;
              }
            }
          }
        }

        .level-1 {
          position: relative;

          .bridge {
            //animation: fade-out-arrow 0.5s ease 1 normal, move-out-arrow 0.5s ease 1 normal;

            height: 20px;
            left: 0;
            right: unset;
            top: 20px;
            width: 250px;

            /*&::after {
              content: "";
              height: 120px;
              left: 0;
              position: absolute;
              top: 40px;
              transform: rotate(45deg);
              width: 120px;
            }*/
          }

          & > a {
            font-size: Clamp(12px, unquote("1vw"), 18px);
          }

          .children-indicator {
            display: none;
            height: 10px;
            margin-left: 5px;
            position: relative;
            width: 10px;

            span {
              background-color: #0063be;
              display: inline-block;
              height: 2px;
              position: absolute;
              top: 6px;
              transition: all .2s ease;
              width: 6px;

              &:first-of-type {
                left: 0;
                transform: rotate(45deg);
              }

              &:last-of-type {
                right: 0;
                transform: rotate(-45deg);
              }
            }

            &:hover {
              span {
                &:first-of-type {
                  transform: rotate(-45deg);
                }

                &:last-of-type {
                  transform: rotate(45deg);
                }
              }
            }
          }

          &.has-children {
            .children-indicator {
              display: inline-block;
            }
          }

          &:hover {
            & > a {
              color: $orange-1;
            }

            .children-indicator {
              span {
                background-color: $orange-1;

                &:first-of-type {
                  transform: rotate(-45deg);
                }

                &:last-of-type {
                  transform: rotate(45deg);
                }
              }
            }
          }

        }

        .level-2 {
          animation: fade-out 0.2s ease 1 normal, move-out 0.2s ease 1 normal;
          box-shadow: 0 4px 6px $black-2;
          cursor: auto;
          right: unset;
          margin-top: 10px;
          width: 250px;

          &::before {
            display: none;
          }

          & > div {
            padding: 10px 30px;
          }

          .has-children {
            cursor: auto;
            flex-direction: column;
            padding-bottom: 0;
            padding-right: 10px;
            position: relative;
            width: calc(100% - 40px);

            & > a {
              padding-bottom: 10px;
            }

            .children-indicator-container {
              padding: 5px 15px;
              z-index: 2;
              position: absolute;
              right: -37px;
              top: -2px;

              .children-indicator {
                pointer-events: none;

                &.right-side {
                  position: relative;
                  right: unset;
                }
              }
            }

            .children-indicator {
              span {
                background-color: #0063be;
                top: 10px;

                &:first-of-type {
                  left: 0;
                  transform: rotate(45deg);
                }

                &:last-of-type {
                  right: 0;
                  transform: rotate(-45deg);
                }
              }

              &.right-side {
                position: absolute;
                right: 30px;
              }
            }

            &:not(.open) {
              &.make-a-request-menu-item {
                .level-3 {
                  & > div {
                    &:nth-child(2) {
                      a {
                        transition: color .1s ease;
                        transition-delay: .05s;
                      }
                    }
                  }
                }
              }
            }

            //&:hover {
            &.open {
              & > a {
                color: $orange-1;
              }

              .children-indicator {
                span {
                  background-color: $orange-1;

                  &:first-of-type {
                    transform: rotate(-45deg);
                  }

                  &:last-of-type {
                    transform: rotate(45deg);
                  }
                }
              }

              &.nutrición-menu-item {
                .level-3 {
                  max-height: 212px; //with 4 menu items
                  //max-height: 125px; //with 3 menu items
                  height: 212px; //with 4 menu items
                  //height: 125px; //with 3 menu items
                }
              }

              &.su-bienestar-menu-item {
                .level-3 {
                  max-height: 232px;
                  height: 232px;
                }
              }

              &.mi-progreso-menu-item {
                .level-3 {
                  max-height: 170px;
                  height: 170px;
                }
              }

              &.kabicare-menu-item {
                .level-3 {
                  max-height: 148px;
                  height: 148px;
                }
              }

              &.idacio-menu-item {
                .level-3 {
                  max-height: 192px;
                  height: 192px;
                }
              }

              &.solicitar-la-visita-de-un-representante-menu-item,
              &.hacer-una-solicitud-menu-item {
                .level-3 {
                  max-height: 106px;
                  height: 106px;
                }
              }

              .level-3 {
                //animation: get-rekt 1s ease 1 normal;
                //animation-direction: reverse;
                max-height: 168px;
                transition-property: max-height;
                transition-duration: 500ms;
                transition-timing-function: ease-out;
                display: flex;

                & > div {
                  opacity: 0;
                  animation: faszom .15s ease .05s forwards;
                  //display: block;
                  color: #003565;

                  a {
                    color: #003565;
                    transition: color 500ms linear;

                    &.active {
                      color: $orange-1;
                    }
                  }

                  &:nth-child(2) {
                    animation: faszom .15s ease .15s forwards;
                  }

                  &:nth-child(3) {
                    animation: faszom .15s ease-out .225s forwards;
                  }

                  &:nth-child(4) {
                    animation: faszom .15s ease-out .3s forwards;
                  }

                  &:nth-child(5) {
                    animation: faszom .15s ease-out .375s forwards;
                  }
                }
              }
            }
          }
        }

        .level-3 {
          //animation: fade-out .2s ease 1 normal, slide-right .2s ease 1 normal;
          //animation-fill-mode: forwards;
          //border-radius: 0 20px 20px 20px;
          min-height: 100%;
          height: 168px;
          //max-height: 100%;
          //animation: more-rekt-this-time 1s ease;

          background: #f4f8fa;
          border-radius: 0;
          margin: 0 -50px 0 -30px;
          padding: 0 30px;
          //animation: get-rekt 1s ease 1 normal, more-rekt-this-time 1s ease 1 normal;

          transition-duration: 500ms;
          transition-property: max-height;
          transition-timing-function: ease-out;
          max-height: 0;


          z-index: unset;
          top: unset;
          width: unset;
          left: unset;
          position: relative;
          box-shadow: unset;

          display: none;

          & > div {
            //opacity: 0;
            //animation: faszom .3s ease .1s forwards;

            //display: none;
            //color: transparent;

            &:nth-child(4) {
              a {
                transition: color .15s ease;
                transition-delay: 0s;
                color: transparent;
              }
            }

            &:nth-child(3) {
              a {
                transition: color .15s ease;
                transition-delay: .10s;
                color: transparent;
              }
            }

            &:nth-child(2) {
              a {
                transition: color .15s ease;
                transition-delay: .20s;
                color: transparent;
              }
            }

            &:nth-child(1) {
              a {
                transition: color .15s ease;
                transition-delay: .30s;
                color: transparent;
              }
            }






            & > a {
              //color: #003565;

              color: transparent;
              transition: color 500ms linear;
            }
          }
        }
      }

      .auth-button-container {
        margin: 0 0 0 10px;
        display: flex;
      }

      .account-button-container {
        border-radius: 50%;
        overflow: hidden;
        height: 40px;
        width: 40px;
        margin-right: 20px;
      }

      button {
        &.auth-button {
          border-width: 1px;
          box-shadow: none;
          filter: none;
          height: unset;
          margin: 0 10px;
          padding: 5px 12.5px;
          width: unset;

          &:first-of-type {
            margin-left : 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          span {
            font-size: .625rem;
            letter-spacing: 0;
            line-height: 16px;
          }

          &.account-button {
            padding: 0;
            margin: 0;
            min-width: unset;
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .hamburger-menu {
      display: none;
    }

    .menu {
      display: none;
    }

    .level-2,
    .level-3 {
      display: none;
    }
  }
}
