@media (min-width: 1280px) {
  .page-container {
    .content-wrap {
      .register-form {
        &.register-hcp-form {
          .register-form-items {
            margin: 56px 0 70px;
          }
        }
      }
    }
  }
}
