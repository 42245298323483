.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.no-padding {
    &.MuiDialog-root {
        .MuiDialog-paper {
            .MuiDialogContent-root {
                padding: 0;
            }
        }
    }
}

h2 {
    font-weight: 600;
    font-size: 33px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $blue-1;
}

h4 {
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.4px;
    color: $blue-1;
}

h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $blue-1;
}

h6 {
    font-weight: normal;
    font-size: 23px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $blue-1;
}

.box-shadow {
    box-shadow: 4px 4px 10px rgba(24, 44, 76, 0.0993225);
}

.hidden {
    display: none;
}

.ellipse-main {
    background-color: $blue-2;
    height: 80vw;
    position: absolute;
    top: 54vw;
    left: -26.3%;
    width: 150%;
    border-radius: 50% 50% 0 0;
    z-index: 0;
    overflow-x: hidden;
    display: none;
}

.show {
    display: block;
}

.backdrop, .MuiBackdrop-root {
    background: linear-gradient(180deg, rgba(0, 99, 190, 0.59) -54.04%, rgba(7, 182, 208, 0.59) 191.82%);
    backdrop-filter: blur(10px);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 14;
}

.loader {
    position: relative;
    left: calc(50% - 20px);
    top: 50%;
}

body {
    &::-webkit-scrollbar {
        width: 0;
    }
}

.page-container {
    margin: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .content-wrap {
        display: block;
        flex: 1 0 auto;
    }
}

.align {
    text-align: center;
}

.align-center {
    text-align: center;
}

.align-right-desktop {
    text-align: center;
}

.breadcrumbs {
    display: none;
}

.background-white {
    background: white;
}

.font-weight-normal {
    font-weight: 400;
}

.font-weight-light {
    font-weight: 300;
}

.white {
    color: white;
}

.MuiAccordionSummary-expandIcon {
    &.Mui-expanded {
        transform: rotate(270deg) !important;
    }
}

form {
    align-items: flex-start;

    > * {
        width: 100%;
    }

    .submit-button {
        span {
            position: relative;
        }

        .button-label {
            width: 100%;
        }

        .MuiCircularProgress-indeterminate {
            position: absolute;
            right: 20px;
        }
    }
}
