$border-padding-on-side: Max(40px, unquote("(100vw - 1400px) / 2"));

.dynamic-page {
  overflow: hidden;
  padding: 0;
  background: #f4f8fa;

  h1 {
    font-family: Interstate;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 34px;
    letter-spacing: 0.4px;
    color: $blue-2;
  }

  h2 {
    text-align: left;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #00c2df;
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $blue-1;
  }

  .subtitle {
    font-weight: 400;
    max-width: 610px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
    color: $blue-7;
  }

  .body-18 {
    text-align: left;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .MuiBreadcrumbs-root {
    display: none;
  }

  .upper-section {
    align-items: center;
    background: linear-gradient(180deg, $blue-8 40.56%, $blue-10 157.78%);
    display: flex;
    justify-content: center;
    margin-left: -25px;
    margin-right: -25px;
    min-height: 250px;
    mix-blend-mode: normal;
    overflow: hidden;
    position: relative;

    .hero {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px 25px;

        .logo-container {
          display: none;
        }

        > .MuiFormControl-root {
          width: 325px;
        }
      }

      &-title {
        color: $white-1;
        font-size: 33px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 41px;
        text-align: center;
      }
    }

    &::after {
      background: #f4f8fa;
      border-radius: 100%;
      bottom: -10%;
      content: "";
      height: 20%;
      left: -10%;
      max-width: 120%;
      position: absolute;
      right: -10%;
      width: 120%;
    }
  }

  .lower-section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &.no-max-width {
      max-width: unset;
      width: 100%;

      & > * {
        max-width: unset;
        background: #fff;

        &:nth-child(2n) {
          background: #f4f8fa;
        }
      }

      .below-hero {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 50px 25px;

        &-inner-container {
          max-width: 100%;
          margin: 0 auto;

          h4 {
            margin: 0;
            text-align: left;
            font-size: 22px;
            line-height: 26px;
            font-weight: bold;
            letter-spacing: 0;
          }

          .description-container {
            p {
              font-size: 16px;
              line-height: 22px;
              margin: 0;
              color: #003565;
              font-weight: normal;
              letter-spacing: 0;
            }
          }

          .cta-small-container {
            padding: 0;

            .cta-small {
              margin: 0;
              padding: 0 10px 0 0;
              text-transform: uppercase;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0;
              font-weight: normal;
            }
          }

          .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
        }
      }

      .content-with-image {
        margin: 0;
        padding: 50px 25px;
        display: flex;

        &.split-title {
          .content-with-image-inner-container {
            .text-column {
              .text-container {
                h4 {
                  &.simple-section-subtitle {
                    font-size: 12px;
                    line-height: 15px;
                    color: #003565;
                    text-transform: uppercase;
                    order: 1;
                    margin-bottom: -10px;
                  }

                  &.simple-section-title {
                    order: 2;
                  }
                }

                .description-container {
                  order: 3;
                }

                .cta-small-container {
                  order: 4;
                }
              }
            }
          }
        }

        .content-with-image-inner-container {
          margin: 0 auto;
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          & > * {
            width: 100%;
          }

          .image-container {
            h4,
            .description-container {
              display: none;
            }
          }

          .text-column {
            display: flex;
            flex-direction: column;
            gap: 50px;
          }

          .text-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            & > * {
              display: none;
            }

            .cta-small-container,
            .description-container {
              display: flex;
            }

            h4 {
              display: block;
              font-size: 22px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 26px;
              text-align: left;
              margin: 0;
            }

            .description-container {
              flex-direction: column;
              gap: 20px;

              p {
                margin: 0;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
              }

              ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 0;

                li {
                  margin: 0;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;

                  strong {
                    font-weight: bold;
                  }
                }
              }
            }

            .cta-small-container {
              margin: 0;
              padding: 0;

              a {
                p {
                  margin: 0;

                  &.cta-small {
                    padding-right: 10px;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }

        &.image-on-left {
          .content-with-image-inner-container {
            flex-direction: column;

            .right-rectangle {
              background: #0063be;
            }
          }
        }

        &.image-on-right {
          .content-with-image-inner-container {
            flex-direction: column;

            .plus-sign {
              &::before,
              &::after {
                background: #f7a900;
              }
            }

            .styled-section-image-inner {
              border-color: #fff;
            }

            .left-rectangle {
              background: #0063be;
            }
          }
        }

        &.tablet-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 20px;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/tablet_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.notebook-image {
          .content-with-image-inner-container {
            .image-container {
              max-width: 340px;
              margin: auto;
              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .left-plus-sign {
                    top: 2em;
                  }

                  .right-plus-sign {
                    bottom: 2em;
                  }

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 0;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/laptop_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      top: 10px;
                      bottom: 3px;
                      left: -23px;
                      right: -23px;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.mobile-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                max-width: 219px;
                //min-height: 373px;
                margin: 0 auto;

                .styled-section-image-inner {
                  max-width: 219px;
                  //min-height: 373px;
                  display: flex;
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    margin-inline: auto;

                    padding: 10px;
                    aspect-ratio: 298 / 575;
                    justify-content: center;
                    align-items: center;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/mobile_border_cropped.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;

                      aspect-ratio: 298 / 575;
                    }

                    img {
                      border-radius: 0;

                      aspect-ratio: 298 / 575;
                      max-height: calc(298 / 575 * 100%);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .section-article-container {
        margin: 0;
        padding: 50px 25px;
        display: flex;

        .section-article-inner-container {
          max-width: 100%;
          // margin: 0 auto;

          .section-article-title,
          .section-article-description {
            max-width: 100%;
          }

          .section-article-title {
            gap: 8px;
            margin-bottom: 15px;

            .section-article-string-1 {
              color: #003565;
              font-weight: bold;
              font-size: 12px;
              line-height: 1;
            }

            .section-article-string-2 {
              color: #0063be;
              font-size: 22px;
              line-height: 1;
              font-weight: bold;
            }
          }

          .section-article-description {
            & > div {
              &:not(.cta-small-container) {
                gap: 20px;
                display: flex;
                flex-direction: column;

                p {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }

            .cta-small-container {
              padding-top: 25px;
              font-size: 18px;
              line-height: 30px;
              letter-spacing: 0;
              font-weight: normal;

              a {
                .cta-small {
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 19px;
                  font-weight: normal;
                  text-transform: uppercase;
                  padding-right: 10px;
                }
              }
            }
          }
        }
      }

      .single-card-gallery-outer-container {
        max-width: calc(100% - 50px);
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
        display: flex;
        border-radius: 10px;

        .single-card-gallery-container {
          padding: 0;
          background: transparent;
          border-radius: unset;
          max-width: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          gap: unset;
          width: 100%;

          .single-card-text-container {
            padding: 0 25px;

            .single-card-title {
              margin: 0 !important;
              font-weight: bold;
              font-size: 22px;
              letter-spacing: 0;
              line-height: 26px;
              color: #0063be;
              text-transform: unset;
            }

            .single-card-description {
              p,
              span {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0;
                font-weight: normal;
              }
            }
          }

          .cards-wrapper {
            margin: 0;
            gap: 20px;
            justify-content: space-around;

            .card-wrapper {
              width: 100%;
            }
          }
        }
      }

      .text-left-symptom-carousel-right {
        margin: 0;
        padding: 50px 25px;

        .text-left-symptom-carousel-right-inner {
          max-width: 100%;
          display: flex;
          width: 100%;
          padding: 0;
          margin: 0 auto;
          gap: 50px;
          flex-direction: column;

          .left-side-container {
            display: flex;
            flex-direction: column;
            gap: 50px;
            width: 100%;

            .cta-small-container {
              padding-top: 5px;

              a {
                color: $orange-1;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0;
                font-weight: normal;
                text-transform: uppercase;

                p {
                  padding-right: 10px;
                  line-height: 19px;
                }
              }
            }

            .section-article-container {
              padding: 0;

              .section-article-description {
                & > div {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                }

                p {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }

            & > section {
              display: flex;
              flex-direction: column;
              gap: 20px;

              h4 {
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
                font-weight: bold;
                text-align: left;
                margin: 0;
              }

              & > div {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }
          }

          & > section {
            width: 100%;

            &:last-child {
              & > div {
                border-radius: 10px;
              }

              .symptom-gallery-container {
                padding: 25px;
                gap: 15px;
                display: flex;

                .symptom-gallery-item {
                  width: 100%;

                  img {
                    max-width: 63px;
                    max-height: 63px;
                  }

                  .item-title {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: bold;
                    margin-left: 20px;
                  }
                }
              }
            }
          }
        }

        &:nth-child(2n) {
          .text-left-symptom-carousel-right-inner {
            & > section {
              &:last-child {
                & > div {
                  background: #fff;
                }
              }
            }
          }
        }

        &:nth-child(2n + 1) {
          .text-left-symptom-carousel-right-inner {
            & > section {
              &:last-child {
                & > div {
                  background: #f4f8fa;
                }
              }
            }
          }
        }

        &.accordion-carousel {
          .text-left-symptom-carousel-right-inner {
            & > section {
              .symptom-gallery-container {
                background: transparent !important;
                padding: 0;

                .symptom-gallery-item {
                  border-bottom: 2px solid rgba(0, 99, 190, 0.5);

                  .no-link-image {
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;
                    padding-bottom: 15px;
                    position: relative;

                    .item-alt {
                      font-size: 12px;
                      line-height: 15px;
                      max-width: calc(100% - 93px);
                      opacity: 0;
                      margin-top: -300px;
                      margin-left: 10px;
                      z-index: -1;

                      a {
                        display: inline-block;
                      }
                    }

                    .item-title {
                      margin-left: 10px;
                      max-width: calc(100% - 93px);
                      width: 100%;
                      cursor: pointer;

                      &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        width: 12px;
                        height: 12px;
                        margin-top: 2px;
                        border-top: 2px solid $orange-1;
                        border-left: 2px solid $orange-1;
                        transform: rotate(135deg);
                      }

                      &.open {
                        & ~ .item-alt {
                          opacity: 1 !important;
                          transition: opacity 1s linear 0.75s, margin-top 1s linear, z-index 0.1s linear;
                          margin-top: 0;
                          z-index: 1;
                        }

                        &::after {
                          transform: rotate(225deg);
                          transition-duration: 1s;
                        }

                        &.closing {
                          & ~ .item-alt {
                            opacity: 0 !important;
                            transition: opacity 0.25s linear, margin-top 1s linear 0.25s, z-index 0.1s linear;
                            margin-top: -300px;
                            z-index: 0;
                          }

                          &::after {
                            transform: rotate(135deg);
                            transition-duration: 1s;
                          }
                        }
                      }
                    }

                    img {
                      background: #fff;
                      padding: 5px;
                      max-width: 73px;
                      max-height: 73px;
                      border-radius: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .text-top-symptom-carousel-bottom {
        padding: 50px 25px;

        .text-top-symptom-carousel-bottom-inner {
          display: flex;
          flex-direction: column;
          gap: 60px;

          .top-side-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 22px;
                letter-spacing: 0;
                text-align: left;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p,
                li,
                span,
                a,
                strong {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  margin: 0;
                  color: #182c4c;
                }

                a {
                  color: $orange-1;

                  sup {
                    color: #182c4c;
                  }
                }

                strong {
                  font-weight: bold;
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;
                }
              }
            }
          }

          & > section {
            .symptom-gallery-container {
              .symptom-gallery-item {
                .no-link-image,
                a {
                  flex-wrap: wrap;
                  row-gap: 15px;
                  column-gap: 20px;

                  img {
                    padding: 10px;
                    border-radius: 20px;
                    background: #fff;
                    margin-right: 0;
                  }

                  .item-title {
                    max-width: calc(100% - 127px);
                    width: 100%;
                    text-align: left;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-weight: bold;
                  }

                  .item-alt {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;

                    a {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .simple-section-with-multiple-title-and-body {
        padding: 50px 25px;
        margin: 0;

        .simple-section {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .simple-section-title,
          .simple-section-subtitle {
            margin: 0;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            p,
            span {
              margin: 0;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;

              li {
                margin: 0;
              }
            }

            .manual-body-1,
            .manual-body-2 {
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
          }
        }
      }

      .content-top-multi-boxes-bottom-outer {
        padding: 50px 25px;
        margin: 0;

        &:nth-child(even) {
          .content-top-multi-boxes-bottom-inner {
            .box-wrapper {
              & > .simple-section {
                .description-container {
                  background: #fff;
                }
              }
            }
          }
        }

        .content-top-multi-boxes-bottom-inner {
          display: flex;
          flex-direction: column;
          gap: 40px;

          & > .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .simple-section-title {
              font-size: 22px;
              letter-spacing: 0;
              line-height: 26px;
              margin: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                margin: 0;
              }
            }
          }

          .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                margin: 0;
                color: #182c4c;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                border-radius: 0 0 20px 20px;
                padding: 20px;
                background: #f4f8fa;
                position: relative;
                margin-top: 14px;

                &::before {
                  height: 14px;
                  width: 100%;
                  border-radius: 20px 20px 0 0;
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: -14px;
                  background: #0063be;
                }

                p,
                span {
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin: 0;
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                  }
                }
              }

              &:nth-child(even) {
                .description-container {
                  &::before {
                    background: #31b7cf;
                  }
                }
              }
            }
          }
        }
      }

      .content-with-pros-and-cons-boxes-outer {
        margin: 0;
        padding: 50px 25px;

        .content-with-pros-and-cons-boxes-inner {
          display: flex;
          flex-direction: column;
          gap: 30px;

          .row-wrapper {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title,
              .simple-section-subtitle {
                margin: 0;
                letter-spacing: 0;
                color: #182c4c;
              }

              .simple-section-title {
                font-size: 22px;
                line-height: 26px;
              }

              .simple-section-subtitle {
                font-size: 16px;
                line-height: 22px;
                color: #182c4c;
                font-weight: normal;
              }
            }

            .pros-cons-box {
              border-radius: 20px;

              &.pro-icon {
                .header-wrapper {
                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-checkmark-circle-outline.png");
                  }
                }
              }

              &.con-icon {
                .header-wrapper {
                  background: #31b7cf;

                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-close-circle-outline.png");
                  }
                }
              }

              .header-wrapper {
                background: #0063be;
                border-radius: 20px 20px 0 0;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;

                .header-icon {
                  height: 25px;
                  width: 25px;
                  position: relative;

                  &::after {
                    content: "";
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  }
                }

                .simple-section-title {
                  margin: 0;
                  color: #fff;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }

              .description-container {
                padding: 15px 20px;
                background: #f4f8fa;
                border-radius: 0 0 20px 20px;

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0;
                  }
                }
              }
            }
          }

          .content-wrapper {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title,
              .simple-section-subtitle {
                margin: 0;
                letter-spacing: 0;
              }

              .simple-section-title {
                font-size: 22px;
                line-height: 26px;
              }

              .simple-section-subtitle {
                font-size: 16px;
                line-height: 22px;
                color: #182c4c;
                font-weight: normal;
              }

              &:nth-child(2) {
                .simple-section-title {
                  font-size: 18px;
                  line-height: 22px;
                  color: #182c4c;
                }
              }
            }
          }

          .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .pros-cons-box {
              border-radius: 20px;

              &.pro-icon {
                .header-wrapper {
                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-checkmark-circle-outline.png");
                  }
                }
              }

              &.con-icon {
                .header-wrapper {
                  background: #31b7cf;

                  .header-icon {
                    background-image: url("../../../resources/images/common/Icon-ionic-ios-close-circle-outline.png");
                  }
                }
              }

              .header-wrapper {
                background: #0063be;
                border-radius: 20px 20px 0 0;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;

                .header-icon {
                  height: 25px;
                  width: 25px;
                  position: relative;

                  &::after {
                    content: "";
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                  }
                }

                .simple-section-title {
                  margin: 0;
                  color: #fff;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }

              .description-container {
                padding: 15px 20px;
                background: #f4f8fa;
                border-radius: 0 0 20px 20px;

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0;
                  }
                }
              }
            }
          }
        }
      }

      .full-width-content-with-cta {
        margin: 0;
        padding: 0;
        display: flex;

        & + .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            background: #00c2df;
          }
        }

        .full-width-content-with-cta-inner {
          max-width: 1400px;
          margin: 0 auto;
          padding: 50px 25px;
          background: #0063be;
          border-radius: unset;
          display: flex;
          flex-direction: column;
          gap: 25px;

          & > section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            h4 {
              color: #fff;
              text-align: left;
              font-size: 22px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 26px;
              margin: 0;
            }

            .description-container {
              margin: 0;

              p {
                color: #fff;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                font-weight: normal;
                margin: 0;
              }

              sup {
                color: $orange-1;

                a {
                  color: $orange-1;
                }
              }
            }
          }

          .call-to-action-container {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 25px;

            .cta-container {
              margin: 0;
              padding: 0;
              width: 100%;
              background: transparent;

              .cta-title {
                &:empty {
                  display: none;
                }
              }

              .cta-button {
                background: #fff;
                box-shadow: none;
                border-radius: 20px;
                padding: 11px 30px 10px;
                width: 100%;

                span {
                  color: $orange-1;
                  font-size: 16px;
                  line-height: 19px;
                  letter-spacing: 0;
                  font-weight: normal;
                  text-transform: uppercase;
                  display: block;
                }
              }

              &:last-child {
                .cta-button {
                  background: $orange-1;

                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      & > .simple-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 50px 25px;
        margin: 0;

        & > * {
          max-width: 100%;
          margin: 0 auto;

          &.cta-small-container {
            width: 100%;
          }

          p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0;
            font-weight: normal;
          }
        }

        .simple-section-subtitle {
          color: #003565;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0;
          font-weight: normal;
        }

        .simple-section-title {
          width: 100%;
        }

        .video-container {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          width: calc(100vw - 50px);
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }

      .single-card-outer-container {
        padding: 50px 25px;

        .single-card-container {
          &.white-card {
            &.has-body-content {
              .single-card-description {
              }
            }

            .single-card-title {
              margin: 0;
            }

            .single-card-description {
              margin-inline: 0;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              font-weight: normal;
              color: #003565;
            }

            .single-card-inner-container {
              gap: 15px;
              flex-direction: column-reverse;

              div {
                &[data-rmiz-wrap="visible"] {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .content-with-multiple-images {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 50px 25px;
        gap: 25px;

        &:nth-child(even) {
          .multi-image-container {
            background: #fff;
          }
        }

        .multi-image-container {
          display: flex;
          flex-direction: column;
          gap: 40px;
          padding: 40px;
          border-radius: 1px;
          background: #f4f8fa;

          .simple-section {
            .description-container {
              display: none;
            }

            div {
              &[data-rmiz-wrap="visible"] {
                display: flex;
                flex-direction: column;

                img {
                  display: block;
                  max-width: 100%;
                  height: auto;
                  width: 100%;
                }

                .single-card-image-text {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .single-card-image-title {
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 0;
                    font-weight: bold;
                    color: #0063be;
                  }

                  .single-card-image-alt {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    font-weight: normal;
                    color: #003565;
                  }
                }
              }
            }
          }
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            p {
              margin: 0;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              font-weight: normal;
              color: #003565;
            }
          }
        }
      }
    }

    &:not(.no-max-width) {
      .content-with-image {
        &.tablet-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 20px;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/tablet_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.notebook-image {
          .content-with-image-inner-container {
            .image-container {
              max-width: 340px;
              margin: auto;
              .styled-section-image {
                .styled-section-image-inner {
                  position: relative;

                  .left-plus-sign {
                    top: 2em;
                  }

                  .right-plus-sign {
                    bottom: 2em;
                  }

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 0;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/laptop_border.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      position: absolute;
                      top: 10px;
                      bottom: 3px;
                      left: -23px;
                      right: -23px;
                    }

                    img {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }

        &.mobile-image {
          .content-with-image-inner-container {
            .image-container {
              .styled-section-image {
                max-width: 219px;
                //min-height: 373px;
                margin: 0 auto;

                .styled-section-image-inner {
                  max-width: 219px;
                  //min-height: 373px;
                  display: flex;
                  position: relative;

                  .image-wrapper {
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    margin-inline: auto;

                    padding: 10px;
                    aspect-ratio: 298 / 575;
                    justify-content: center;
                    align-items: center;

                    &::before {
                      content: "";
                      background-image: url("../../../resources/images/common/mobile_border_cropped.png");
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      top: 17px;
                      left: 17px;
                      right: 17px;
                      bottom: 17px;
                      position: absolute;

                      aspect-ratio: 298 / 575;
                    }

                    img {
                      border-radius: 0;

                      aspect-ratio: 298 / 575;
                      max-height: calc(298 / 575 * 100%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .fullwidth-transparent {
      .single-card-gallery-container {
        & > section {
          .cards-wrapper {
            .card-wrapper {
              .card {
                .card-image-title {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &.main-page {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .logo-container {
      display: none;
    }

    .select {
      margin-top: 25px;
      margin-bottom: 63px;
    }

    .MuiAccordion-root {
      box-shadow: none;
      background-color: transparent;

      .MuiAccordionSummary-root {
        flex-direction: row-reverse;
        justify-content: flex-end;
        min-height: auto;

        .MuiAccordionSummary-content {
          margin: 0;
        }

        .MuiIconButton-root {
          margin-right: 0px;
        }

        .MuiSvgIcon-root {
          font-size: 22px;
          color: $blue-7;
        }
      }

      h5 {
        font-size: 12px;
        line-height: 19px;
        font-weight: 600;
      }
    }

    .text-align-center {
      text-align: center;
    }

    > section {
      margin-bottom: 30px;

      h4 {
        text-align: start;
      }
    }

    li {
      &::marker {
        color: $blue-1;
      }
    }

    a,
    .link {
      color: $blue-1;
      font-weight: 500;
    }

    a {
      cursor: pointer;
    }

    .carousel-container {
      margin-top: 53px;
    }

    .column-container {
      margin-top: 50px;

      .text-container {
        height: 223px;
      }

      img {
        height: 100%;
        width: 100%;
      }

      h4 {
        margin-block-end: 10px;
        margin-block-start: 10px;
      }

      .subtitle {
        font-size: 21px;
      }

      .title {
        margin-bottom: 19px;
      }
    }

    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      margin: 0;
    }

    .cards-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 30px;
      margin-top: 30px;
      text-align: center;

      .card-wrapper {
        max-width: 368px;

        h4 {
          margin-top: 20px;
        }

        .card {
          border-radius: 30px;
          display: flex;
          justify-content: center;

          .link {
            display: flex;
            max-width: 100%;
            position: relative;
            justify-content: center;
            flex-direction: column;

            .card-image-title {
              margin-bottom: 10px;
            }

            picture {
              display: flex;
            }

            .content {
              background: rgba(255, 255, 255, 0.85);
              border-radius: 0 0 29px 29px;
              bottom: 0;
              left: 0;
              padding: 15px 18px;
              position: absolute;
              right: 0;
              min-height: 120px;
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              text-align: start;

              &-title {
                color: $blue-1;
                font-weight: bold;
                margin-bottom: 15px;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0.4px;
              }

              &-description {
                font-weight: 400;
                color: $blue-7;
                font-size: 16px;
                line-height: 23px;

                p,
                span {
                  margin: 0;
                  width: 100%;
                  display: block;
                }
              }
            }
          }

          img {
            border-radius: 30px;
            height: 288px;
            width: 288px;
            object-fit: cover;
          }
        }
      }
    }

    .cta-container,
    .cta-double-container {
      max-width: none;
    }

    .single-card {
      &-gallery-container {
        .cards-wrapper {
          .card {
            img {
              height: auto;
              width: auto;
              max-width: 270px;
              border-radius: 0;
            }
          }
        }
      }
    }

    .video-container {
      &.loaded {
        margin-bottom: 23px;
      }
    }

    #references {
      margin: 0;
      padding: 50px 25px;
      display: flex;

      & > .MuiAccordion-root {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
        max-width: 100%;

        .MuiButtonBase-root {
          padding: 0;

          .MuiIconButton-root {
            display: none;
          }
        }

        .MuiAccordionDetails-root {
          padding: 0;
          max-width: 100%;

          div {
            max-width: 100%;
          }
        }

        h5 {
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 0;
          font-weight: bold;
          margin: 0;
          text-align: left;
        }

        .MuiIconButton-root {
          margin-top: 10px;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 15px;
          list-style-position: outside;
          padding: 0;

          list-style: decimal inside none;
          padding-left: 35px;

          li {
            margin: 0;
            display: list-item;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: normal;

            //padding-left: 35px;
            position: relative;
            text-align: left;

            list-style: decimal;

            &::marker {
              color: #182C4C;
            }

            &::before {
              position: absolute;
              top: 0;
              left: 0;

              display: none;
            }

            a {
              color: $orange-1;
              word-break: break-word;
            }
          }
        }
      }
    }

    .attachment-section {
      padding: 50px 0;
      margin: 0 25px;

      .attachment-section-inner-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .attachment-title {
          margin: 0;
          padding: 0;

          h4 {
            margin: 0;
            color: #0063be;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0;
            font-weight: bold;
          }
        }

        .attachment-body {
          & > div {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            strong,
            li {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              color: #003565;
              font-weight: normal;
              margin: 0;
            }

            strong {
              font-weight: bold;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }

        .attachment-cards-wrapper {
          margin: 10px 0 0;
          gap: 25px;
          flex-wrap: nowrap;
          flex-direction: column;

          .card-wrapper {
            max-width: unset;
            padding: 25px;
            background: #fff;
            border-radius: 10px;

            .card {
              border-radius: unset;
              .link {
                display: flex;
                flex-direction: column;
                gap: 25px;

                img {
                  width: 100%;
                  height: auto;
                  border-radius: unset;
                }

                .content {
                  position: relative;
                  padding: 0;
                  min-height: unset;
                  top: unset;
                  bottom: unset;
                  left: unset;
                  right: unset;

                  .content-title {
                    margin: 0;
                    color: #0063be;
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: 0;
                    font-weight: bold;
                  }
                }

                .read-more {
                  margin-top: auto;
                  padding-top: 25px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  width: 100%;

                  .text {
                    text-align: left;
                    color: #f7a900;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: normal;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    margin-right: 5px;
                  }

                  .arrow-container {
                    color: $orange-1;
                    transition: margin-left 0.5s ease;
                    letter-spacing: 0;

                    img {
                      max-height: 11px;
                      max-width: 13px;
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }

                  &:hover {
                    .arrow-container {
                      margin-left: 0.5em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .condition-select {
      margin: 0;
      background: #fff;
      padding: 0 0 50px;
      display: flex;
      flex-direction: column-reverse;
      gap: unset;
      position: relative;

      .condition-select-inner-container {
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-inline: 25px;
        margin-top: 25px;

        h1 {
          margin: 0 0 15px;
          line-height: 36px;
          font-size: 30px;
          letter-spacing: 0;
          font-weight: bold;
          text-align: left;
          width: 100%;
        }

        .subtitle {
          position: absolute;
          max-width: 530px;
          width: 100%;
          top: 0;
          bottom: 220px;
          left: $border-padding-on-side;
          z-index: 1;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          color: #00c2df;
          font-size: 40px;
          line-height: 48px;
          font-weight: bold;
          letter-spacing: 0;

          display: none;
        }

        .select {
          margin: 0 auto;
          max-width: 325px;
          width: 100%;
          min-width: 325px;
          height: unset;
          margin-right: auto;

          .MuiInputBase-root {
            height: unset;
          }

          .MuiSelect-root {
            color: #003565;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0;
            font-weight: normal;
            padding: 16px 20px 15px;

            &:focus {
              background: #fff;
            }
          }

          .select-square {
            background: transparent;
            width: 50px;
            height: 49px;
            display: flex;
            z-index: 1;
            align-items: center;
            border-radius: 0 10px 10px 0;
            pointer-events: none;
            justify-content: center;

            .chevron {
              &::before {
                color: #003565;
                width: 6px;
                height: 6px;
                border-width: 1px 1px 0 0;
                top: -5px;
                vertical-align: baseline;
                bottom: unset;
              }
            }
          }

          fieldset {
            border: 1px solid #d3d3d3;
          }
        }

        p {
          &:empty {
            margin: 0;
          }
        }
      }

      .hero-section-image-container {
        max-height: unset;

        img {
          display: block;
          object-fit: cover;
          width: 100%;
          height: auto;
          min-height: 220px;
          max-height: 550px;
        }

        &::after {
          content: "";
          bottom: 0;
          left: 0;
          right: 0;
          height: 25px;
          width: 100%;
          position: absolute;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.75) 67%,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }

    .hero-section-text {
      //padding: 25px 25px 15px;

      max-width: 100%;
      padding: 0 0 50px;
      display: flex;
      flex-direction: column-reverse;
      //gap: 25px;
      background: #fff;
      margin: 0;

      .hero-section-text-inner-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        padding-inline: 25px;
        margin-top: 25px;

        h1 {
          margin: 0;
          text-align: left;
          font-size: 30px;
          line-height: 36px;
          font-weight: bold;
          letter-spacing: 0;
        }

        .subtitle {
          margin: 0;
          text-align: left;
          font-size: 16px;
          line-height: 22px;
          color: #003565;
          letter-spacing: 0;
        }

        p {
          font-size: 16px;
          line-height: 1.3;
          margin: 0;
          color: #003565;
          font-weight: normal;
          letter-spacing: 0;
        }
      }

      .hero-section-image-container {
        position: relative;

        .hero-section-image {
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          min-height: calc(220 / 375 * 100vw);
          object-position: calc(100 / 3 * 2%) 50%;
        }

        &::after {
          content: "";
          bottom: 0;
          left: 0;
          right: 0;
          height: 25px;
          width: 100%;
          position: absolute;
          background: linear-gradient(
            180deg,
            rgba(244, 248, 250, 0) 0%,
            rgba(244, 248, 250, 0.75) 67%,
            rgba(244, 248, 250, 1) 100%
          );
        }
      }

      & ~ .simple-section {
        gap: 15px;

        .simple-section-title {
          letter-spacing: 0;
          font-size: 22px;
          line-height: 26px;
        }

        .description-container {
          p {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0;
            font-weight: normal;
            color: #003565;
          }
        }
      }
    }

    section {
      &.section-0 {
        margin: 0;
      }
    }

    .carousel-container {
      max-width: 100%;
      margin-top: 30px;

      .MuiBox-root {
        margin: 0 0 25px;
        padding: 0 25px;

        h4 {
          font-size: 22px;
          line-height: 26px;
          margin: 0 0 15px;
          letter-spacing: 0;
          font-weight: bold;
        }

        .body-18,
        p {
          font-size: 16px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: 0;
          color: #003565;
          margin: 0;
        }
      }

      .cards-wrapper {
        gap: 25px;
        padding: 25px 25px 50px;
        margin: 0;

        .card-wrapper {
          padding: 25px;
          border-radius: 10px;
          background: #fff;
          max-width: 100%;
          width: 100%;

          .card {
            height: 100%;

            .link {
              max-width: 100%;
              max-height: unset;
              height: 100%;

              picture {
                min-width: 271px;
                min-height: 220px;
                width: 100%;
                height: 100%;

                img {
                  object-fit: cover;
                  display: block;
                  width: 100%;
                  height: auto;
                  aspect-ratio: 271 / 220;
                  border-radius: unset;
                }
              }

              .content {
                position: relative;
                margin: 25px 0 0;
                padding: 0;
                min-height: unset;

                .content-title {
                  color: #0063be;
                  font-size: 22px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 26px;
                  text-align: left;
                  margin: 0;
                }

                .content-description {
                  margin: 15px 0 0;

                  color: #003565;
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 22px;
                  font-weight: normal;

                  p,
                  span {
                    color: #003565;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: normal;
                  }
                }
              }

              .read-more {
                padding-top: 20px;
                margin-top: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .text {
                  color: $orange-1;
                  font-size: 16px;
                  font-weight: normal;
                  letter-spacing: 0;
                  line-height: 19px;
                  text-transform: uppercase;
                  margin-right: 5px;
                  text-align: left;
                }

                .arrow-container {
                  color: $orange-1;
                  transition: margin-left 0.5s ease;
                  letter-spacing: 0;
                  min-width: 13px;

                  img {
                    max-height: 11px;
                    max-width: 13px;
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }

                &:hover {
                  .arrow-container {
                    margin-left: 0.5em;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-of-type(even) {
        background: #fff;

        .cards-wrapper {
          .card-wrapper {
            background: #f4f8fa;

            .card {
              a {
                .content {
                  background: #f4f8fa;
                }
              }
            }
          }
        }
      }

      & ~ .carousel-container {
        margin-top: 0;

        .MuiBox-root {
          padding-top: 50px;
        }
      }
    }

    .gallery-items-outer-container {
      margin: 0;
      padding: 50px 25px;

      .gallery-items-inner-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .section-article-container {
          padding: 0;

          .section-article-inner-container {
            width: 100%;

            .section-article-title {
              margin: 0;

              .section-article-string-2 {
                font-size: 22px;
                line-height: 26px;
              }
            }
          }
        }

        .symptom-gallery-container {
          gap: 20px;

          .symptom-gallery-item {
            .no-link-image {
              display: flex;
              flex-direction: row;
              gap: 20px;

              img {
                display: block;
                width: 100%;
                height: auto;
                max-width: 73px;
                max-height: 73px;
                margin: 0 0 auto;
              }

              .item-title {
                color: #003565;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0;
                font-weight: normal;
                text-align: left;
              }
            }
          }
        }
      }
    }

    .single-card-gallery-outer-container {
      a {
        sup {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .simple-section {
      .simple-section-title {
        a {
          sup {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      .description-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        ul {
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 15px;

          li {
            margin: 0;
            letter-spacing: 0;
            line-height: 22px;
            font-size: 16px;
            color: #003565;
            font-weight: normal;
          }
        }
      }
    }
  }

  &.dynamic-Bienestar-y-apoyo-page,
  &.dynamic-Su-bienestar-page {
    .lower-section {
      &.main-page {
        max-width: 100%;
        margin: 0;
        width: 100%;

        .hero-section-text {
          & ~ .simple-section {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 50px 25px 0;

            h4 {
              margin: 0;
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0;
              font-weight: bold;
            }

            .description-container {
              p,
              span {
                margin: 0;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
              }
            }
          }
        }

        .carousel-container {
          background: transparent;

          .cards-wrapper {
            .card-wrapper {
              background: #fff;

              .card {
                a {
                  .content {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-autoinmunes-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          display: flex;
          flex-direction: column-reverse;
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            p {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              font-weight: normal;
              color: #003565;
            }

            ul {
              padding: 0;

              li {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                font-weight: normal;
                color: #003565;
                margin: 0;

                strong {
                  color: #003565;
                  font-weight: bold;
                }
              }
            }
          }

          & + .content-with-image {
            .content-with-image-inner-container {
              .text-container {
                [data-rmiz-wrap="visible"] {
                  //display: inline-flex;
                }
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }

        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;

          .simple-image-container {
            width: 100%;

            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              max-width: 100%;
              border-radius: 20px;

              img {
                width: 100%;
                height: auto;
                display: block;
              }

              button {
                display: none;
              }
            }
          }

          .content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 22px;
                letter-spacing: 0;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }
            }
          }
        }

        .content-with-pros-and-cons-boxes-outer {
          padding-bottom: 40px;

          & + .content-with-pros-and-cons-boxes-outer {
            background: #fff;
            padding-top: 0;
            padding-bottom: 40px;

            & + .simple-section {
              padding-top: 0;
            }
          }
        }

        .content-with-multiple-images {
          .multi-image-container {
            .simple-section {
              [data-rmiz-wrap="visible"] {
                button {
                  display: none;
                }
              }

              .simple-section-title,
              .description-container {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-El-microbioma-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          //1st child of lower-section
          display: flex;
          flex-direction: column-reverse;

          & + .content-with-simple-image {
            & + .content-with-simple-image {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  overflow-x: hidden;
                  max-width: 100%;

                  img {
                    max-height: unset;
                    width: 100%;
                    height: auto;
                    margin-inline: 25px;
                    margin-bottom: 10px;
                    max-width: calc(100% - 50px);
                  }
                }
              }
            }
          }
        }

        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;
          gap: 20px;
          display: flex;
          flex-direction: column;

          &:nth-child(odd) {
            .simple-image-container {
              [data-rmiz-wrap="visible"] {
                background: #f4f8fa;
              }
            }
          }

          &:nth-child(even) {
            .simple-image-container {
              [data-rmiz-wrap="visible"] {
                background: #fff;
              }
            }
          }

          .simple-image-container {
            width: 100%;

            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              overflow-x: scroll;
              max-width: 100%;
              display: flex;
              flex-direction: column-reverse;
              gap: 10px;
              border-radius: 10px;

              button {
                display: none;
              }

              .single-card-image-text {
                .single-card-image-alt {
                  display: none;
                }

                .single-card-image-title {
                  letter-spacing: 0;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 15px;
                  margin-inline: 25px;
                  margin-top: 10px;
                }
              }

              img {
                display: block;
                max-height: 400px;
              }
            }
          }

          .content-container {
            width: 100%;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  font-weight: normal;
                  color: #003565;
                  margin: 0;
                }
              }
            }
          }

          & + .content-with-image {
            & + .content-with-simple-image {
              .simple-image-container {
                [data-rmiz-wrap="visible"] {
                  overflow-x: hidden;
                  background: transparent;

                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }
            }
          }
        }

        .single-card-outer-container {
          .single-card-container {
            .single-card-inner-container {
              gap: 20px;

              [data-rmiz-wrap="visible"] {
                gap: 10px;
                flex-direction: column-reverse;
                overflow-x: scroll;
                max-width: 100%;

                img {
                  display: block;
                  margin: 0;
                  border-radius: 10px;
                  max-height: 400px;
                  height: unset;
                  width: unset;
                }

                button {
                  display: none;
                }

                .single-card-image-text {
                  .single-card-image-alt {
                    display: none;
                  }

                  .single-card-image-title {
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0;
                    font-weight: bold;
                  }
                }
              }

              .single-card-text-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .single-card-title {
                  font-size: 22px;
                  letter-spacing: 0;
                  line-height: 26px;
                  color: #0063be;
                  text-transform: unset;
                }

                .single-card-description {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        .simple-section-with-multiple-body {
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin: 0;

          .simple-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            [data-rmiz-wrap="visible"] {
              display: none;
              order: 1;
              //align-items: unset;
              max-width: 100%;
              overflow-x: scroll;
              flex-direction: column-reverse;
              gap: 10px;

              img {
                max-height: 400px;
                display: block;
              }

              button {
                display: none;
              }

              .single-card-image-text {
                .single-card-image-alt {
                  display: none;
                }

                .single-card-image-title {
                  font-size: 12px;
                  line-height: 15px;
                  letter-spacing: 0;
                  font-weight: bold;
                }
              }
            }

            .simple-section-title {
              order: 2;
              margin: 0;
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0;
            }

            .description-container {
              order: 3;
              display: flex;
              flex-direction: column;
              gap: 10px;

              p,
              span {
                margin: 0;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
              }

              ul {
                padding: 0;

                li {
                  margin: 0;
                  font-size: 20px;
                  line-height: 24px;
                  letter-spacing: 0;
                  font-weight: bold;
                }
              }
            }

            &:nth-child(1) {
              [data-rmiz-wrap="visible"] {
                display: flex;
              }
            }
          }
        }

        #references {
          //background: transparent;
        }
      }
    }
  }

  &.dynamic-the-microbiome-in-IBD-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              img {
                display: block;
                height: auto;
                width: 100%;
              }

              button {
                display: none;
              }
            }
          }

          .content-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 22px;
                letter-spacing: 0;
                line-height: 26px;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 22px;
                  color: #003565;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Mi-tratamiento-page {
    .lower-section {
      &.main-page {
        .hero-section-text {
          & + .simple-section {
            margin: 0;
            padding: 50px 25px 25px;

            h4 {
              margin: 0;
              display: none;
            }

            .description-container {
              p {
                line-height: 30px;
                font-size: 18px;
              }
            }
          }
        }

        .carousel-container {
          background: #fff;
          margin: 0;

          .cards-wrapper {
            .card-wrapper {
              background: #f4f8fa;

              .card {
                a {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .content {
                    background: transparent;
                    margin: 0;
                    border-radius: unset;
                  }

                  .read-more {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-acceso-para-profesionales-de-la-salud-page {
    .lower-section {
      &.main-page {
        .carousel-container {
          margin: 0;

          .cards-wrapper {
            .card-wrapper {
              .card {
                a {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .content {
                    margin: 0;
                    border-radius: unset;

                    .content-description {
                      display: flex;
                      flex-direction: column;
                      gap: 25px;
                      margin: 0;

                      p {
                        margin: 0;
                      }
                    }
                  }

                  .read-more {
                    padding: 0;
                  }
                }
              }
            }
          }
        }

        .section-article-container {
          margin: 0;
          padding: 50px 25px 25px;

          .section-article-inner-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .section-article-title {
              margin: 0;

              .section-article-string-1 {
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
                color: #0063be;
                font-weight: bold;
                text-align: left;
                text-transform: unset;
              }
            }

            .section-article-description {
              display: flex;
              flex-direction: column;
              gap: 25px;

              p {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                color: #003565;
                font-weight: normal;
              }

              ul {
                padding: 0;

                li {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  color: #003565;
                  font-weight: normal;
                }
              }

              p,
              li {
                text-align: left;
              }
            }
          }
        }

        & > .simple-section {
          padding: 50px 25px;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 15px;

          .simple-section-title {
            margin: 0;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            li {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              margin: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }
      }
    }
  }

  &.dynamic-kabicare-page {
    .lower-section {
      &.main-page {
        .hero-section-text {
          & ~ .simple-section {
            margin: 0;
            padding: 50px 25px 25px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            h4 {
              margin: 0;

              .simple-section-subtitle {
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
                font-weight: normal;
              }
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 25px;

              p {
                line-height: 22px;
                font-size: 16px;
                margin: 0;
                letter-spacing: 0;
                font-weight: normal;
              }

              ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                  line-height: 22px;
                  font-size: 16px;
                  margin: 0;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }
          }
        }

        .carousel-container {
          margin: 0;

          & ~ .simple-section {
            padding-bottom: 50px;
            background: #fff;
          }

          .cards-wrapper {
            .card-wrapper {
              background: #f4f8fa;

              .card {
                a {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  picture {
                    img {
                    }
                  }

                  .content {
                    background: transparent;
                    margin: 0;
                    border-radius: unset;
                  }

                  .read-more {
                    padding: 0;
                  }
                }
              }
            }
          }
        }

        .content-with-image {
          padding: 50px 25px;
          background: #f4f8fa;

          .content-with-image-inner-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .text-column {
              .text-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .simple-section-title {
                  color: #0063be;
                  font-weight: bold;
                  font-size: 22px;
                  line-height: 26px;
                  letter-spacing: 0;
                  text-align: left;
                  margin: 0;
                }

                .description-container {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p,
                  span {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    color: #003565;
                    font-weight: normal;
                    margin: 0;
                  }
                }

                [data-rmiz-wrap="visible"] {
                  display: none;
                }
              }
            }

            .image-container {
              .simple-section-title,
              .simple-section-subtitle {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Viajar-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            & + .content-with-image {
              & + .simple-section {
                background: #fff;
                padding-top: 0;

                .description-container {
                  max-width: 1400px;
                }
              }
            }
          }
        }
        .content-with-image {
          &.tablet-image {
            background: transparent;
          }

          &.notebook-image {
            background: #fff;
            padding-bottom: 30px;

            .content-with-image-inner-container {
              .text-column {
                gap: 30px;
              }
            }

            & + .simple-section {
              padding-top: 0;
              gap: 15px;

              .simple-section-title {
                display: block;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 26px;
                text-align: left;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Enfermedad-de-Crohn-page,
  &.dynamic-colitis-ulcerativa-page {
    .lower-section {
      &.content-page {
        .single-card-gallery-outer-container {
          .single-card-gallery-container {
            gap: 20px;

            & > section {
              .cards-wrapper {
                .card-wrapper {
                  padding-inline: 25px;

                  div {
                    &:not(.card) {
                      h4 {
                        margin-bottom: 10px;
                      }
                    }
                  }

                  &:last-of-type {
                    div {
                      &:not(.card) {
                        h4 {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Nutrición-page {
    .lower-section {
      &.main-page {
        .hero-section-text {
          & + .carousel-container {
            padding-top: 50px;
          }
        }
      }
    }
  }

  &.dynamic-Mindfulness-y-estrés-page {
    .lower-section {
      &.content-page {
        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            gap: 30px;

            section {
              .symptom-gallery-container {
                .symptom-gallery-item {
                  a {
                    .item-title {
                      color: #003565;
                      font-size: 14px;
                      line-height: 22px;
                    }
                  }

                  .no-link-image {
                    .item-title {
                      color: #003565;
                      font-size: 14px;
                      line-height: 22px;
                    }
                  }
                }
              }
            }
          }
        }

        .text-top-symptom-carousel-bottom {
          .text-top-symptom-carousel-bottom-inner {
            & > section {
              .symptom-gallery {
                .symptom-gallery-item {
                  &:nth-child(3n + 1) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                  }
                  &:nth-child(3n + 2) {
                    grid-column-start: 2;
                    grid-column-end: 3;
                  }
                  &:nth-child(3n + 3) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                  }

                  .no-link-image,
                  a {
                    gap: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    flex-wrap: nowrap;

                    img {
                      width: 95px;
                      height: 95px;
                    }

                    .item-title {
                      margin: 0;
                      max-width: unset;
                      font-size: 22px;
                      line-height: 26px;
                      letter-spacing: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Relaciones-page,
  &.dynamic-Intimidad-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            .simple-section-title {
              font-size: 22px;
              letter-spacing: 0;
            }

            .description-container {
              p {
                line-height: 22px;
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Intimidad-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 50px;

          .simple-image-container {
            border-radius: 10px;
            overflow: hidden;
            display: flex;

            h4 {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              max-width: 100%;

              img {
                max-width: 100%;
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          .content-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 22px;
                letter-spacing: 0;
                margin: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 30px;

                p {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  font-weight: normal;
                  color: #003565;
                }

                ul {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                  }
                }

                p,
                ul {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }

          & + .content-with-image {
            .content-with-image-inner-container {
              gap: 0;

              .image-container {
                visibility: hidden;
              }

              .text-column {
                .text-container {
                  .description-container {
                    p {
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: 0;
                      font-weight: normal;
                      color: #003565;
                    }

                    ul {
                      display: flex;
                      flex-direction: column;
                      gap: 15px;

                      li {
                        margin: 0;
                      }
                    }

                    p,
                    ul {
                      margin: 0;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Afrontar-los-retos-page {
    .lower-section {
      &.content-page {
        .simple-section {
          gap: 15px;

          .simple-section-title {
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0;
          }

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              color: #003565;
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            & > .simple-section {
              .simple-section-title {
                font-size: 36px;
                line-height: 44px;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Apoyo-a-padres-o-cuidadores-page {
    .lower-section {
      &.content-page {
        .simple-section {
          gap: 15px;

          .simple-section-title {
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0;
          }

          .description-container {
            gap: 20px;
            display: flex;
            flex-direction: column;

            p {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            gap: 25px;

            .left-side-container {
              .simple-section {
                .description-container {
                  ul {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    gap: 15px;

                    li {
                      margin: 0;
                      line-height: 20px;

                      a {
                        color: $orange-1;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: normal;
                        letter-spacing: 0;
                      }
                    }
                  }
                }
              }
            }

            section {
              .symptom-gallery-container {
                gap: 20px;

                .symptom-gallery-item {
                  .no-link-image {
                    align-items: flex-start;

                    .item-title {
                      font-size: 14px;
                      line-height: 22px;
                      color: #003565;
                      font-weight: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Apoyo-comunitario-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          padding: 50px 25px;

          .simple-image-container {
            display: none;
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 25px;

              .simple-section-title {
                margin: 0;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
              }

              .description-container {
                p,
                span,
                a {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  color: #003565;
                  word-break: break-word;
                }

                a {
                  color: $orange-1;

                  span {
                    color: $orange-1;
                  }
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Apoyar-a-otras-personas-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .video-container {
              max-width: 100%;
            }

            span,
            a {
              font-size: 16px;
              line-height: 22px;
            }

            a {
              color: $orange-1;
            }

            ul {
              padding: 0;
              display: flex;
              gap: 15px;
              flex-direction: row;
              flex-wrap: wrap;

              li {
                margin: 0;

                flex-direction: column;

                & > span {
                  margin-top: -20px;
                  margin-left: 35px;
                }

                ul {
                  padding-left: 35px;
                  margin-top: 15px;

                  li {
                    span {
                      word-break: break-word;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Hablar-con-su-equipo-asistencial-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;
          gap: 20px;
          display: flex;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              max-height: 83px;
              max-width: 83px;
              min-width: 83px;
              width: 100%;

              img {
                max-height: 83px;
                max-width: 83px;
                width: 100%;
                height: auto;
                display: block;
              }
            }
          }

          .content-container {
            .simple-section {
              .description-container {
                gap: 20px;
                display: flex;
                flex-direction: column;

                p {
                  margin: 0;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0;
                  color: #003565;
                }

                ul {
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  li {
                    margin: 0;

                    * {
                      margin: 0;
                      font-size: 14px;
                      line-height: 22px;
                      letter-spacing: 0;
                      color: #003565;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Establecimiento-de-objetivos-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            gap: 25px;
            display: flex;
            flex-direction: column;

            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;

              li {
                margin: 0;

                * {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  font-weight: normal;
                  color: #003565;

                  a {
                    color: $orange-1;
                  }
                }
              }
            }
          }
        }

        .full-width-content-with-cta {
          .full-width-content-with-cta-inner {
            .simple-section {
              .simple-section-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Herramientas-de-coaching-page {
    .lower-section {
      background: #f4f8fa;

      & > * {
        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: transparent;
        }
      }

      .coaching-tool-hero-section {
        padding: 25px 25px 50px;
        margin: 0;

        .simple-section-title {
          color: #00c2df;
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 0;
          font-weight: bold;
          margin: 0;
        }
      }

      .section-article-container {
        margin: 0;
        padding: 50px 25px;

        .title {
          color: #00c2df;
          font-size: 18px;
          line-height: 30px;
          text-align: left;
        }

        .subtitle {
          font-size: 24px;
          line-height: 29px;
          color: #0063be;
          font-weight: bold;
          text-align: left;
        }

        .section-article-inner-container {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .section-article-title {
            margin: 0;
            gap: 6px;

            .section-article-string-1 {
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 0;
              color: #003565;
              text-transform: uppercase;
            }

            .section-article-string-2 {
              color: #0063be;
              font-size: 22px;
              letter-spacing: 0;
              line-height: 26px;
            }
          }

          .section-article-description {
            p {
              color: #003565;
              font-weight: normal;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
            }
          }
        }
      }

      .fullwidth-transparent {
        padding: 50px 25px 30px;

        .single-card-gallery-container {
          padding: 0;
          border-radius: unset;
          background: transparent;
          display: flex;
          flex-direction: column;
          gap: 30px;

          .single-card-text-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .single-card-title {
              margin: 0;
              color: #0063be;
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0;
              text-transform: unset;
            }

            .single-card-description {
              font-size: 16px;
              line-height: 22px;
              color: #003565;
              letter-spacing: 0;
              margin: 0;
            }
          }

          & > section {
            .cards-wrapper {
              margin: 0;

              .card-wrapper {
                display: flex;
                flex-direction: row;
                border-radius: 10px;
                background: #f4f8fa;

                .card {
                  justify-content: unset;
                  border-radius: unset;
                  flex: 1 0 calc(120 / 325 * 100%);

                  .link {
                    picture {
                      aspect-ratio: 120 / 167;
                      border-radius: 10px 0 0 10px;
                      overflow: hidden;

                      img {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                      }
                    }

                    .read-more {
                      display: none;
                    }
                  }
                }

                h4 {
                  margin: 0;
                  padding: 48px 15px 15px;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 20px;
                  font-weight: normal;
                  color: #003565;
                  font-style: italic;
                  position: relative;
                  text-align: left;

                  &::before {
                    content: "\201C";
                    font-family: "Arial Black";
                    font-size: 40px;
                    position: absolute;
                    line-height: 56px;
                    top: 0;
                    left: 15px;
                  }

                  &::after {
                    content: "";
                    top: 0;
                    left: -20px;
                    bottom: 0;
                    position: absolute;
                    width: 20px;
                    background: linear-gradient(90deg, transparent, #f4f8fa);
                  }
                }
              }
            }
          }
        }

        & + .simple-section {
          background: #fff;
          padding: 0 25px 50px;
          margin: 0;

          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
              margin: 0;
              letter-spacing: 0;
              font-size: 16px;
              line-height: 22px;
            }
          }

          & + .section-article-container {
            padding-bottom: 30px;

            & + .section-article-container {
              padding: 0 25px;

              & > section {
                .title {
                  margin: 0;
                  letter-spacing: 0;
                }

                .subtitle {
                  margin: 20px 0 0;
                  font-size: 22px;
                  letter-spacing: 0;
                  line-height: 26px;
                }

                .body-18 {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                  }
                }
              }

              .column-container {
                padding: 30px 0 50px;

                &::after {
                  left: 0;
                  right: 0;
                }
              }
            }
          }
        }
      }

      .column-container {
        margin: 0;
        padding: 30px 25px 50px;
        background: #f4f8fa;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &::after {
          height: 1px;
          border: 1px solid rgba(0, 99, 190, 0.5);
          bottom: 0;
          left: 25px;
          right: 25px;
          position: absolute;
          content: "";
        }

        & > div {
          & > .MuiBox-root {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .text-container {
              margin: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
              height: unset;

              .title {
                margin: 0;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 30px;
                color: #00c2df;
                text-align: left;
              }

              .subtitle {
                color: #0063be;
                margin: 0;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 26px;
                text-align: left;
                font-weight: bold;
              }

              .body-18 {
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                  margin: 0;
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 22px;
                }
              }
            }

            img {
              display: block;
            }
          }
        }
      }

      #references {
        background: transparent;
      }
    }
  }

  &.dynamic-Comprender-el-tratamiento-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            gap: unset;

            .simple-section-title {
              order: 2;
              margin-top: 6px;
            }

            .simple-section-subtitle {
              order: 1;
              font-size: 12px;
              line-height: 15px;
              color: #003565;
              text-align: left;
              text-transform: uppercase;
              font-weight: bold;
              width: 100%;
            }

            .description-container {
              order: 3;
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }

            .cta-small-container {
              order: 4;
              margin-top: 25px;
              padding: 0;

              a {
                .cta-small {
                  margin-right: 10px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        .content-with-simple-image {
          padding: 50px 25px;
          margin: 0;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              .single-card-image-text {
                .single-card-image-title {
                  letter-spacing: 0;
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: bold;

                  a {
                    sup {
                      font-size: 10px;
                      line-height: 14px;
                      color: #003565;
                    }
                  }
                }
              }

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              button {
                display: none;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                line-height: 26px;
                font-size: 22px;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  color: #003565;
                  line-height: 22px;
                  font-size: 16px;
                  letter-spacing: 0;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Comer-bien-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 30px;
          margin: 0;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              display: flex;
              flex-direction: column-reverse;
              gap: 10px;

              button {
                display: none;
              }

              img {
                display: block;
                max-width: 100%;
              }
            }

            .single-card-image-text {
              .single-card-image-title {
                letter-spacing: 0;
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
              }

              .single-card-image-alt {
                display: none;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 26px;
                font-weight: bold;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  color: #003565;
                  letter-spacing: 0;
                  line-height: 22px;
                  font-size: 16px;
                  margin: 0;
                }
              }
            }
          }
        }

        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            & > section {
              .symptom-gallery-container {
                .symptom-gallery-item {
                  .no-link-image {
                    img {
                    }

                    .item-title {
                      font-size: 14px;
                      line-height: 18px;

                      a {
                        display: inline-flex;
                        position: relative;
                        width: 12px;
                        height: 14px;

                        sup {
                          position: absolute;
                          margin-top: -3px;
                          top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .section-article-container {
          .section-article-inner-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .section-article-title {
              margin: 0;

              .section-article-string-1,
              .section-article-string-2 {
                letter-spacing: 0;
              }
            }

            .section-article-description {
              p,
              li,
              strong,
              span,
              a {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                margin: 0;
              }

              ul {
                padding: 0;
              }
            }
          }
        }

        .full-width-content-with-cta {
          & + .section-article-container {
            .section-article-inner-container {
              .section-article-description {
                div {
                  ul {
                    li {
                      font-weight: bold;

                      span {
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-eating-well-with-ibd-page,
  &.dynamic-eating-well-with-IBD-page {
    .lower-section {
      &.content-page {
        .content-with-image {
          .content-with-image-inner-container {
            .text-column {
              .text-container {
                .cta-small-container {
                  display: none;
                  cursor: pointer;
                }

                &:nth-child(1) {
                  .cta-small-container {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Mantenerse-activo-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;
          }

          [data-rmiz-wrap="visible"] {
            overflow-x: scroll;

            button {
              display: none;
            }
          }
        }

        .text-left-symptom-carousel-right {
          padding-bottom: 30px;

          .text-left-symptom-carousel-right-inner {
            gap: 25px;

            .left-side-container {
              .simple-section {
                .description-container {
                  ul {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    li {
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: 0;
                      color: #003565;
                      margin: 0;
                    }
                  }
                }
              }
            }

            section {
              .symptom-gallery-container {
                padding: 0;
                background: transparent !important;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: flex-start;

                .symptom-gallery-item {
                  max-width: calc(50% - 5px);

                  .no-link-image {
                    .item-title {
                      font-size: 14px;
                      line-height: 22px;
                    }

                    .item-alt {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          & ~ .text-left-symptom-carousel-right {
            background: transparent;
            padding-top: 0;
            padding-bottom: 50px;

            .text-left-symptom-carousel-right-inner {
              gap: 25px;
            }
          }
        }

        .content-with-image {
          &.notebook-image {
            background: #fff;

            & + .simple-section {
              background: transparent;
            }
          }
        }

        #references {
          background: #fff;
        }
      }
    }
  }

  &.dynamic-Introducción-a-KabiCare-page,
  &.dynamic-introducción-a-kabicare-page {
    .lower-section {
      &.content-page {
        .content-with-image {
          &.mobile-image {
            .content-with-image-inner-container {
              .text-column {
                .text-container {
                  .description-container {
                    ul {
                      li {
                        flex-wrap: wrap;

                        span {
                          width: calc(100% - 35px);
                        }

                        ul {
                          padding-left: 35px;
                          margin-top: 10px;

                          li {
                            &::before {
                              background: url("../../../resources/images/common/list_item_plus.png");
                              background-repeat: no-repeat;
                              width: 13px;
                              height: 13px;
                              min-width: 13px;
                              min-height: 13px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.tablet-image {
            & + .simple-section {
              background: #fff;
              padding-top: 50px;

              .simple-section-title {
                //display: none;
              }

              .simple-section-subtitle {
                font-size: 18px;
                line-height: 30px;
                width: 100%;
              }
            }

            & + .notebook-image {
              background: #f4f8fa;
            }
          }
        }
      }
    }
  }

  &.dynamic-Inyección-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            p {
              font-size: 18px;
              line-height: 30px;
            }
          }

          [data-rmiz-wrap="visible"] {
            overflow-x: scroll;
            button {
              display: none;
            }
          }
        }

        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 30px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            img {
              display: block;
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                  margin: 0;
                  color: #003565;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Guía-de-autoadministración-page {
    .lower-section {
      &.content-page {
        & > .hero-section-text {
          & + .simple-section {
            .description-container {
              gap: 20px;
            }
          }
        }

        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .video-container {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  &.dynamic-Ayuda-y-apoyo-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & ~ .simple-section {
            .simple-section-title {
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0;
            }

            .description-container {
              display: flex;
              gap: 25px;
              flex-direction: column;

              ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;

                li {
                  margin: 0;
                  flex-wrap: wrap;

                  & > * {
                    margin-top: 15px;

                    &:first-child {
                      margin: 0;
                    }
                  }

                  & > p {
                    width: 100%;
                    max-width: 100%;
                    padding-left: 35px;
                  }

                  & > strong,
                  & > span {
                    max-width: calc(100% - 35px);
                    width: 100%;
                  }

                  p,
                  span,
                  strong {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    color: #003565;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-IBD-diets-page {
    .lower-section {
      &.content-page {
        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .video-container {
              margin-top: 10px;
              margin-bottom: 10px;
            }

            p {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              margin: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;

              li {
                margin: 0;

                span,
                strong {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  margin: 0;
                }
              }
            }
          }
        }

        .content-with-multiple-images {
          .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .simple-section-title {
              font-size: 22px;
              letter-spacing: 0;
              margin: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                margin: 0;
              }

              ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                  margin: 0;

                  span,
                  strong {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    margin: 0;
                  }
                }
              }
            }
          }

          .multi-image-container {
            .simple-section {
              .simple-section-title,
              .description-container {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                button {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-idacio-page {
    .lower-section {
      &.main-page {
        .hero-section-text {
          & + .simple-section {
            padding: 50px 25px;
            margin: 0;
            display: flex;
            flex-direction: column;

            .simple-section-title {
              margin: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                margin: 0;
              }
            }
          }
        }

        .content-with-simple-image {
          background: #fff;
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column-reverse;
          gap: 30px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                margin: 0;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0;
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }
            }
          }
        }

        #references {
          background: #fff;
        }

        .text-left-symptom-carousel-right {
          margin: 0 auto;
          padding: 50px 25px;
          max-width: 100%;
          background: #fff;

          .text-left-symptom-carousel-right-inner {
            max-width: 100%;
            display: flex;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            gap: 50px;
            flex-direction: column;
            align-items: center;

            & > section {
              .symptom-gallery-container {
                .symptom-gallery-item {
                  .no-link-image {
                    img {
                    }

                    .item-title {
                      font-size: 14px;
                      line-height: 18px;

                      a {
                        display: inline-flex;
                        position: relative;
                        width: 12px;
                        height: 14px;

                        sup {
                          position: absolute;
                          margin-top: -3px;
                          top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .text-left-symptom-carousel-right {
          .text-left-symptom-carousel-right-inner {
            .left-side-container {
              display: flex;
              flex-direction: column;
              gap: 50px;
              width: 100%;

              .cta-small-container {
                padding-top: 5px;

                a {
                  color: $orange-1;
                  font-size: 16px;
                  line-height: 19px;
                  letter-spacing: 0;
                  font-weight: normal;
                  text-transform: uppercase;

                  p {
                    padding-right: 10px;
                    line-height: 19px;
                  }
                }
              }

              .section-article-container {
                padding: 0;

                .section-article-description {
                  & > div {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                  }

                  p {
                    font-size: 18px;
                    line-height: 30px;
                  }
                }
              }

              & > section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h4 {
                  font-size: 22px;
                  line-height: 26px;
                  letter-spacing: 0;
                  font-weight: bold;
                  text-align: left;
                  margin: 0;
                }

                & > div {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0;
                    font-weight: normal;
                  }
                }
              }
            }

            & > section {
              width: 100%;

              &:last-child {
                & > div {
                  border-radius: 10px;
                }

                .symptom-gallery-container {
                  padding: 25px;
                  gap: 15px;
                  display: flex;

                  .symptom-gallery-item {
                    width: 100%;

                    img {
                      max-width: 63px;
                      max-height: 63px;
                    }

                    .item-title {
                      font-size: 16px;
                      letter-spacing: 0;
                      line-height: 22px;
                      font-weight: bold;
                      margin-left: 20px;
                    }
                  }
                }
              }

              /*.symptom-gallery {
                .symptom-gallery-item {
                  .no-link-image {
                    align-items: flex-start;

                    img {
                      display: none;
                    }

                    .item-title {

                    }

                    .item-alt {

                    }
                  }
                }
              }*/
            }
          }

          &:nth-child(2n) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #fff;
                  }
                }
              }
            }
          }

          &:nth-child(2n + 1) {
            .text-left-symptom-carousel-right-inner {
              & > section {
                &:last-child {
                  & > div {
                    background: #f4f8fa;
                  }
                }
              }
            }
          }

          &.accordion-carousel {
            .text-left-symptom-carousel-right-inner {
              & > section {
                .symptom-gallery-container {
                  background: transparent !important;
                  padding: 0;

                  .symptom-gallery-item {
                    border-bottom: 2px solid rgba(0, 99, 190, 0.5);

                    .no-link-image {
                      flex-wrap: wrap;
                      align-items: flex-start;
                      justify-content: flex-start;
                      padding-bottom: 15px;
                      position: relative;
                      width: 100%;

                      .item-alt {
                        font-size: 12px;
                        line-height: 15px;
                        max-width: 100%;
                        opacity: 0;
                        margin-top: -300px;
                        margin-left: 0;
                        z-index: -1;
                        padding-top: 15px;

                        a {
                          display: inline-block;
                        }

                        ul {
                          display: flex;
                          flex-direction: column;
                          gap: 15px;

                          li {
                            margin: 0;
                            text-align: left;
                          }
                        }
                      }

                      .item-title {
                        margin-left: 0;
                        max-width: 100%;
                        width: 100%;
                        cursor: pointer;
                        text-align: left;

                        &::after {
                          content: "";
                          position: absolute;
                          right: 0;
                          width: 12px;
                          height: 12px;
                          margin-top: 2px;
                          border-top: 2px solid $orange-1;
                          border-left: 2px solid $orange-1;
                          transform: rotate(135deg);
                        }

                        &.open {
                          & ~ .item-alt {
                            opacity: 1 !important;
                            transition: opacity 1s linear 0.75s, margin-top 1s linear, z-index 0.1s linear;
                            margin-top: 0;
                            z-index: 1;
                          }

                          &::after {
                            transform: rotate(225deg);
                            transition-duration: 1s;
                          }

                          &.closing {
                            & ~ .item-alt {
                              opacity: 0 !important;
                              transition: opacity 0.25s linear, margin-top 1s linear 0.25s, z-index 0.1s linear;
                              margin-top: -300px;
                              z-index: 0;
                            }

                            &::after {
                              transform: rotate(135deg);
                              transition-duration: 1s;
                            }
                          }
                        }
                      }

                      img {
                        background: #fff;
                        padding: 5px;
                        max-width: 73px;
                        max-height: 73px;
                        border-radius: 20px;

                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .carousel-container {
          margin: 0;
          background: transparent;

          .cards-wrapper {
            .card-wrapper {
              background: #fff;

              .card {
                .link {
                  .content {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Medicamentos-biológicos-y-biosimilares-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          & + .simple-section {
            padding-bottom: 0;
          }
        }

        .content-with-simple-image {
          padding: 20px 25px;
          margin: 0;
          background: transparent;
          display: flex;
          flex-direction: column-reverse;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }

          .content-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  color: #003565;
                }
              }
            }
          }

          & + .simple-section {
            background: #fff;

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p,
              span {
                margin: 0;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                color: #003565;
              }

              ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 0;

                li {
                  margin: 0;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                  color: #003565;
                }
              }
            }
          }
        }

        .single-card-gallery-outer-container {
          margin: 0;
          padding-top: 0;
          padding-inline: 25px;
          max-width: unset;
          border-radius: unset;

          .single-card-gallery-container {
            flex-direction: column-reverse;
            gap: 20px;

            & > section {
              .cards-wrapper {
                .card-wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  & > div {
                    &:not(.card) {
                      p {
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                      }
                    }
                  }
                }
              }
            }

            .single-card-text-container {
              padding: 0;

              .single-card-description {
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  margin: 0;
                  line-height: 22px;
                }
              }
            }
          }
        }

        & > .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            li {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
              margin: 0;
            }

            ul {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 15px;
            }
          }
        }

        #references {
          background: transparent;
        }
      }
    }
  }

  &.dynamic-Datos-clínicos-page {
    .lower-section {
      &.content-page {
        & > .simple-section {
          .simple-section-subtitle {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            color: #0063be;
            font-weight: bold;
            width: 100%;
          }

          .description-container {
            gap: 20px;
            display: flex;
            flex-direction: column;

            p,
            span {
              margin: 0;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
            }

            ul {
              padding: 0;
              gap: 15px;
              display: flex;
              flex-direction: column;

              li {
                margin: 0;
              }
            }
          }

          [data-rmiz-wrap="visible"] {
            max-height: 370px;
            overflow-x: scroll;

            img {
              display: block;
              max-height: 370px;
            }

            button {
              display: none;
            }
          }

          &:nth-of-type(4) {
            padding-bottom: 25px;
          }
        }

        .content-with-simple-image {
          padding: 0 25px 50px;
          display: flex;
          flex-direction: column-reverse;
          gap: 30px;
          background: #fff;
          margin: 0;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              display: flex;

              img {
                margin: 0;
                width: 100%;
                height: auto;
                display: block;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .simple-section-title {
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0;
                margin: 0;
              }

              .description-container {
                gap: 20px;
                display: flex;
                flex-direction: column;

                p,
                span {
                  margin: 0;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }

                ul {
                  padding: 0;
                  gap: 15px;
                  display: flex;
                  flex-direction: column;

                  li {
                    margin: 0;
                  }
                }
              }
            }
          }

          & ~ .simple-section {
            background: transparent;
            padding-top: 25px;
            padding-bottom: 0;

            [data-rmiz-wrap="visible"] {
              img {
                height: unset;
                width: unset;
                max-width: unset;
                max-height: 223px;
              }

              button {
                display: none;
              }
            }

            &:nth-of-type(6) {
              padding-top: 50px;
            }

            &:nth-of-type(10) {
              padding-bottom: 50px;
            }
          }
        }

        .content-with-multiple-images {
          background: #fff;

          .multi-image-container {
            background: transparent;
            padding: 0;
            order: 2;

            .simple-section {
              .simple-section-title {
                display: none;
              }

              .simple-section-subtitle {
                display: none;
              }

              .description-container {
                display: none;
              }

              [data-rmiz-wrap="visible"] {
                overflow-x: scroll;

                img {
                  height: unset;
                  width: unset;
                  max-width: unset;
                  max-height: 350px;
                }

                button {
                  display: none;
                }
              }
            }
          }

          & > .simple-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &:nth-of-type(1) {
              order: 1;
            }

            &:nth-of-type(2) {
              order: 3;
            }

            .simple-section-title {
              margin: 0;
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0;
            }

            .simple-section-subtitle {
              margin: 0;
              font-size: 18px;
              line-height: 21px;
              letter-spacing: 0;
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;

              p {
                margin: 0;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
              }
            }
          }

          & + .simple-section {
            padding: 50px 25px;
          }
        }

        .simple-section {
          &:nth-of-type(3) {
            .simple-section-title {
              order: 1;
            }

            .description-container {
              order: 3;
            }

            [data-rmiz-wrap="visible"] {
              order: 2;
            }
          }
        }

        #references {
          background: #fff;
        }
      }
    }
  }

  &.dynamic-Presentaciones-de-administración-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .simple-section-subtitle {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0;
            text-align: left;
            color: #0063be;
            font-weight: bold;
            width: 100%;
          }
        }

        .hero-section-text {
          & ~ * {
            background: transparent;
          }

          & + .simple-section {
            padding-bottom: 0;
          }
        }

        .carousel-container {
          margin: 0;
          padding: 30px 25px;

          .cards-wrapper {
            padding: 0;

            .card-wrapper {
              background: #fff;

              .card {
                .link {
                  .content {
                    background: transparent;
                  }
                }
              }
            }
          }

          & + .simple-section {
            padding-top: 0;
          }
        }

        #references {
          background: #fff;
        }
      }
    }
  }

  &.dynamic-Apoyo-para-pacientes-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            p,
            span,
            strong,
            li {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;

              li {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Inscripción-de-pacientes-page {
    .lower-section {
      &.content-page {
        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            p,
            span,
            strong,
            li {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;

              li {
                margin: 0;
                flex-wrap: wrap;
                justify-content: end;

                & > * {
                  max-width: calc(100% - 35px);
                  width: 100%;
                  margin-top: 20px;

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Cuidado-de-su-medicamento-page {
    .lower-section {
      &.content-page {
        .gallery-items-outer-container {
          & + .gallery-items-outer-container {
            padding-bottom: 25px;

            & + .simple-section {
              padding-top: 0;
              background: transparent;

              a {
                color: $orange-1;
              }

              & + .gallery-items-outer-container {
                background: #fff;
              }
            }
          }

          .gallery-items-inner-container {
            .symptom-gallery {
              .symptom-gallery-item {
                .item-title {
                  a {
                    display: inline;

                    sup {
                      font-size: 10px;
                      line-height: 14px;
                      color: #003565;
                    }
                  }
                }
              }
            }
          }
        }

        #references {
          background: transparent;
        }
      }
    }
  }

  &.dynamic-Programa-KabiCare-page {
    .lower-section {
      &.content-page {
        #references {
          order: 10;
          background: #fff;

          & + .attachment-section {
            background: #f4f8fa;
            border-bottom: 2px solid rgba(0, 99, 190, 0.5);
          }
        }

        .content-with-image {
          & ~ .simple-section {
            .description-container {
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
          }
        }

        .attachment-section {
          .attachment-section-inner-container {
            .attachment-body {
              & > div {
                ul {
                  li {
                    flex-wrap: wrap;

                    * {
                      max-width: calc(100% - 35px);
                      width: 100%;
                      margin-left: auto;
                      margin-top: 15px;

                      &:first-child {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-non--infectious-uveitis-page {
    .lower-section {
      &.content-page {
        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }
          }

          .content-container {
            .simple-section {
              .description-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-Mitos-y-verdades-page {
    .lower-section {
      &.content-page {
        & > .simple-section {
          .simple-section-subtitle {
            width: 100%;
            text-align: left;
            font-size: 18px;
            line-height: 24px;
            color: #00c2df;
            font-weight: bold;
            letter-spacing: 0;
          }
        }

        .content-with-simple-image {
          margin: 0;
          padding: 50px 25px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .simple-image-container {
            .simple-section-title {
              display: none;
            }

            [data-rmiz-wrap="visible"] {
              button {
                display: none;
              }

              img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 10px;
              }
            }
          }

          .content-container {
            .simple-section {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .simple-section-title {
                margin: 0;
                order: 2;
                font-size: 22px;
                letter-spacing: 0;
              }

              .cta-small-container {
                order: 1;
                padding: 0;

                a {
                  pointer-events: none;

                  p {
                    color: #003565;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0;
                  }

                  .arrow-container {
                    display: none;
                  }
                }
              }

              .description-container {
                order: 2;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 10px;

                p {
                  margin: 0;
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 22px;
                }
              }
            }
          }
        }

        .single-card-outer-container {
          .single-card-container {
            .single-card-inner-container {
              gap: 20px;

              .single-card-text-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .single-card-title {
                  color: #0063be;
                  font-size: 22px;
                  letter-spacing: 0;
                  line-height: 26px;
                  text-transform: unset;
                }

                .single-card-description {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  p {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                    color: #003565;
                  }
                }
              }

              [data-rmiz-wrap="visible"] {
                .single-card-image-text,
                button {
                  display: none;
                }

                img {
                  margin: 0;
                  display: block;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.dynamic-contactenos-page,
  &.dynamic-politica-de-cookies-page,
  &.dynamic-terminos-y-condiciones-page,
  &.dynamic-eventos-adversos-page,
  &.dynamic-politica-de-privacidad-page {
    .lower-section {
      &.content-page {
        .hero-section-text {
          padding: 25px;

          .hero-section-text-inner-container {
            padding: 0;
          }
        }

        .simple-section {
          .description-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p,
            span,
            strong,
            a,
            li {
              font-size: 16px;
              margin: 0;
              line-height: 22px;
              letter-spacing: 0;
              word-break: break-word;
            }

            a {
              color: $orange-1;
            }

            h4 {
              margin: 0;
              text-align: left;
              font-size: 22px;
              line-height: 26px;
              font-weight: bold;
              letter-spacing: 0;
            }

            h2 {
              margin: 0;
              text-align: left;
              font-size: 26px;
              line-height: 32px;
              font-weight: bold;
              letter-spacing: 0;
            }

            ul,
            ol {
              display: flex;
              flex-direction: column;
              gap: 15px;
              padding: 0;
              margin: 0;

              li {
                margin: 0;
                display: list-item;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 640px) {
  .dynamic-page {
    .lower-section {
      &.no-max-width {
        .content-with-image {
          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: 450px;

                .styled-section-image {
                  .styled-section-image-inner {
                    .image-wrapper {
                      &::before {
                        top: 8px;
                        bottom: 0;
                        left: -33px;
                        right: -33px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  .dynamic-page {
    .lower-section {
      &.no-max-width {
        .content-with-image {
          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: 592px;

                .styled-section-image {
                  .styled-section-image-inner {
                    .image-wrapper {
                      &::before {
                        top: 4px;
                        bottom: -8px;
                        left: -53px;
                        right: -53px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1365px) {
  .dynamic-page {
    .lower-section {
      &.no-max-width {
        .content-with-image {
          &.notebook-image {
            .content-with-image-inner-container {
              .image-container {
                max-width: 719px;

                .styled-section-image {
                  .styled-section-image-inner {
                    max-width: 600px;
                    margin-inline: auto;
                    .image-wrapper {
                      &::before {
                        top: 4px;
                        bottom: -8px;
                        left: -53px;
                        right: -53px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
