@media (min-width: 1280px) {
  .symptom-gallery-container {
    .symptom-gallery-item {
      flex-direction: row;
      justify-content: start;

      .item-title {
        text-align: left;
        margin-left: 50px;
        width: 100%;
      }

      .item-image {
        margin: 0 10px 0 0;
      }

      a,
      .no-link-image {
        flex-direction: row;
        width: 100%;
      }
    }
  }
}
