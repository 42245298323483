.page-container {
  .content-wrap {
    .contact-us-page {
      padding: 50px 25px;
      background: #f4f8fa;
      max-width: 100%;

      .contact-us-form {
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .contact-us-form-header {
          .main-title {
            color: #00c2df;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0;
            font-weight: bold;
            margin: 0;
          }
        }

        .contact-us-form-items {
          form {
            display: grid;
            gap: 25px;

            .submit-button {
              max-width: unset;
              width: 100%;
              height: unset;
              min-height: unset;
              padding: 11px 20px 10px;

              &:visited,
              &:hover {
                border: 1px solid $orange-1;
                background: transparent;
              }

              span {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 19px;
                font-weight: normal;
              }
            }
          }
        }

        .contact-us-form-pre-form {

        }

        .contact-us-form-post-form,
        .contact-us-form-post-form:hover,
        .contact-us-form-post-form:visited {
          text-decoration: unset;
          color: #182C4C;
        }
      }
    }
  }
}
