@media (min-width: 1280px) {
  .single-card {
    &-container {
      border-radius: 30px;
      flex-direction: row;
      padding: 90px 87px 83px 60px;

      .single-card-image {
        margin: auto 0 auto auto;
        max-width: calc(420 / 1109 * 100 * 1%);
  
        &.full {
          max-width: none;
        }
      }

      &.no-content {
        min-height: 500px;

        &.image {
          &-alignment {
            &-center {
              .single-card-inner-container {
                [data-rmiz-wrap="visible"] {
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  width: 100%;

                  img {
                    height: 100%;
                    width: auto;
                    margin: 0;
                  }
                }
              }
            }

            &-left {
              .single-card-inner-container {
                [data-rmiz-wrap="visible"] {
                  padding: 0;
                  display: flex;
                  justify-content: flex-start;
                  width: 100%;

                  img {
                    height: 100%;
                    width: auto;
                    margin: 0;
                  }
                }
              }
            }

            &-right {
              .single-card-inner-container {
                [data-rmiz-wrap="visible"] {
                  padding: 0;
                  display: flex;
                  justify-content: flex-end;
                  width: 100%;
                  margin: 0;

                  img {
                    height: 100%;
                    width: auto;
                  }
                }
              }
            }
          }

          &-height {
            &-300 {
              min-height: 300px;
            }

            &-400 {
              min-height: 400px;
            }

            &-500 {
              min-height: 500px;
            }

            &-600 {
              min-height: 600px;
            }

            &-700 {
              min-height: 700px;
            }

            &-800 {
              min-height: 800px;
            }

            &-900 {
              min-height: 900px;
            }

            &-1000 {
              min-height: 1000px;
            }
          }
        }
      }
    }

    &-inner-container {
      flex-direction: row;
      width: 100%;

      [data-rmiz-wrap="visible"] {
        bottom: 0;
        margin-left: auto;
        margin-top: auto;
        padding-left: calc(380 / 1109 * 100 * 1% + 60px);
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;



        img {
          height: auto;
          border-radius: unset;
        }

        [data-rmiz-btn-open] {
          left: calc(380 / 1109 * 100 * 1% + 60px);
          width: calc(100% - (380 / 1109 * 100 * 1% + 60px));
        }
      }
    }

    &-split-container {
      border-radius: 30px;
      flex-direction: row;
      padding: 40px;

      .single-card-text-container {
        max-width: none;
      }
    }

    &-gallery-container{
      padding: 35px 35px 1px 35px;
      border-radius: 30px;

      .single-card {
        &-text-container {
          max-width: none;
        }
      }

    }

    &-text-container {
      align-self: center;
      max-width: calc(380 / 1109 * 100 * 1%);
      z-index: 1;
    }

    &-title {
      margin: 0 0 35px !important;

      &:empty {
        margin: 0 !important;
      }
    }

    &-description {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

  }
}
