.page-container {
  .content-wrap {
    .login-page {
      padding: 50px 25px;
      max-width: unset;
      width: 100%;
      flex-wrap: unset;
      display: flex;
      background: #f4f8fa;

      .login-form {
        max-width: 100%;
        padding: 0;
        width: 100%;

        .login-left-side {
          width: 100%;

          .main-title {
            padding: 0;

            h1 {
              color: #00c2df;
              font-size: 30px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 36px;
              text-align: left;
            }
          }

          & > p {
            color: #003565;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 19px;
          }

          form {
            display: flex;
            flex-direction: column;
            margin: 15px 0 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .input-container {
              border-radius: 10px;
              margin: 10px 0;

              label {
                color: #d3d3d3;
              }

              input {
                border: 1px solid #d3d3d3;
                border-radius: 10px;
                padding: 15px 25px 14px;
              }

              input,
              label {
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0;
                line-height: 19px;
              }
            }

            button {
              margin: 10px 0 0;
              max-width: unset;
              width: 100%;
              border-radius: 20px;
              box-shadow: none;
              height: unset;
              padding: 10.5px 19px;
              width: 100%;

              span {
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
          }

          .link-holder {
            display: block;
            color: $orange-1;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 25px;
            padding: 0;
            text-align: center;
            text-decoration: unset;
          }

          .login-page-register-container {
            border-top: 2px solid #d3d3d3;
            margin-top: 15px;
            padding-top: 25px;

            .register-link {
              display: flex;
              justify-content: center;

              .no-account-yet,
              .register-text {
                color: #003565;
                display: inline-block;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0;
                line-height: 19px;

                &.highlighted {
                  color: $orange-1;
                }
              }

              .register-text {
                margin-left: 5px;
              }
            }
          }
        }

        .login-image-container {
          display: none;
        }

        .MuiBreadcrumbs-root {
          display: block;
        }

        & > div {
          padding-top: 0;
        }

        .main-title {
          margin-bottom: 0;
          padding-top: 55px;

          h3 {
            text-align: left;
          }
        }

        .login-form-items {
          margin-bottom: 15px;

          .input-container-outer {
            & > div {
              margin-top: 48px;
            }
          }
        }

        .submit-button-container {
          margin: 116px 0 0 0;
          max-width: 255px;
        }
      }
    }

    .login-form-items {
      .input-container-outer {
        & > div {
          height: auto;
          margin-top: 16px;
          max-width: unset;
        }

        input {
          height: auto;
          line-height: 29px;
          margin: 0;
          padding: 12px 16px 9px;
        }
      }
    }

    #component-helper-text {
      margin: 10px;

      &:empty {
        margin: 0;
      }
    }

    .main-title {
      margin-bottom: 48px;
    }

    .link-holder {
      & > div {
        font-size: 14px;
        height: auto;
        line-height: 36px;
        padding: 0;
      }
    }

    .register-card {
      & > div {
        box-sizing: border-box;
        margin: 50px 0 45px;
        max-height: unset;
        max-width: unset;
        min-height: unset;
        min-width: unset;
        padding: 42px 30px 40px;
      }

      .MuiTypography-h4 {
        margin: 0 10px;
        color: white;

        & > div {
          font-size: 33px;
          line-height: 41px;
          margin: 0;
          padding: 0;
        }
      }

      .register-card-content {
        margin: 21px 10px 0;

        p {
          font-weight: bold;
          margin: 0 0 30px;
        }

        ul {
          padding-inline-start: 2px;

          li {
            font-weight: bold;
            margin: 0 0 10px 15px;
          }
        }
      }

      .register-buttons-container {
        align-items: center;
        flex-direction: column;
        margin-top: 30px;

        & > div {
          margin-bottom: 26px;
          max-width: 275px;
          padding: 0;
          width: 100%;

          & > a {
            width: 100%;

            button {
              height: auto;
              padding: 15px 10px 14px;
              width: 100%;
            }
          }
        }

        .MuiButton-root:hover {
          color: white;
        }
      }
    }

    .MuiTypography-subtitle1 {
      font-weight: 300;
      margin: 0 10px;

      & > div {
        margin: 0;
      }
    }
  }
}
