@media (min-width: 1280px) {
    .MuiDialog-root {
        .MuiDialog-paper {
            width: 100%;
            max-width: 695px;
        }

        .MuiDialog-paper {
            .MuiDialogTitle-root {
                padding: 50px 50px 0;
            }

            .MuiDialogContent-root {
                padding: 0 50px;
            }

            .MuiDialogActions-root {
                display: block;
                padding: 0 50px 50px;
            }
        }
    }

    .restricted-popup, .form {
        &.MuiDialog-root {
            .MuiDialog-paper {
                padding: 30px;

                .MuiDialogTitle-root {
                    padding: 0;
                }

                .MuiDialogContent-root {
                    padding: 0 50px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .MuiDialogActions-root {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding: 0;
                }
            }
        }
    }

    .form {
        &.MuiDialog-root {
            .MuiDialog-paper {

                .MuiDialogTitle-root {
                    h2 {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                }

                .MuiDialogContent-root {
                    padding: 0 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
    
                .MuiDialog-paper {
                    .MuiDialogActions-root {
                        padding: 0 25px 25px;
                    }
                }
                }
        }
    }

    .ConfirmDialog {
        .MuiDialog-scrollPaper {
            .MuiDialog-paper {
                max-width: 450px;

                .MuiDialogTitle-root {
                    padding: 50px 50px 0;
                }

                .MuiDialogContent-root {
                    padding: 0 50px;
                }

                .MuiDialogActions-root {
                    display: block;
                    padding: 0 50px 50px;
                }

                .ActionButton__Wrapper {
                    margin-bottom: 0;
                }
            }
        }
    }
}
