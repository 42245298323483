@media (min-width: 1280px) {
  .footer {
    padding: 50px;
    position: relative;
    width: 100%;

    &::before {
      background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0 0 no-repeat padding-box;
      content: "";
      height: 40px;
      position: absolute;
      right: 0;
      top: -40px;
      width: 100%;
    }

    .footer-inner-container {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
    }

    .disclaimer {
      margin-bottom: 50px;
      opacity: .5;

      p,
      a {
        font-size: 16px;
        line-height: 30px;
        margin: 0;
        text-align: left;
      }
    }

    .bottom {
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      border: none;

      .logo {
        height: auto;
        margin: 0;
        max-width: 300px;
        padding: 0;
        width: 100%;

        img {
          height: auto;
          width: 100%;
          min-width: unset;
          max-width: unset;
        }
      }

      .content {
        align-items: flex-end;
        justify-content: space-between;
        padding: 0;

        .links {
          margin: 0;
          padding: 0;

          a {
            color: #0063BE;
            font-family: 'Interstate';
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 30px;
            padding: 0;
          }

          div {
            margin: 0 15px;
            width: unset;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .preparation-container {
          .date {
            p {
              color: #0063BE;
              font-size: 16px;
              font-weight: normal;
              line-height: 30px;
              letter-spacing: 0;
            }
          }

          .legal-number {
            color: #0063BE;
            letter-spacing: 0;
            font-size: 16px;
            line-height: 30px;
            font-weight: normal;
            margin-left: 30px;

            &:empty {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
