.page-container {
  .content-wrap {
    position: relative;

    main {
      padding: 0 25px;

      &.thank-you-for-registering {
        max-width: unset;
        padding: 50px 25px;

        .thank-you-inner-container {
          display: flex;
          flex-direction: column;
          gap: 25px;

          h1 {
            color: #00C2DF;
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0;
            font-weight: bold;
          }

          .text-wrapper {
            p {
              color: #003565;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0;
            }
          }
        }
      }
    }

    .register-main-page {
      .register-card {
        max-width: 935px;
        width: 100%;
        background: #f4f8fa;

        .register-box {
          display: flex;
          border: none;
          padding: 50px 0;
          border-radius: unset;
          margin-bottom: 0;
          margin-top: 0;
          flex-direction: column;

          .register-left-side {
            h1 {
              color: #00c2df;
              font-size: 30px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 36px;
              padding: 0 25px;
              text-align: left;
            }

            .register-card-content {
              margin: 15px 0 0;
              padding: 0 25px;

              p {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0;
                color: #003565;
                font-weight: normal;
              }

              ul {
                margin-top: 20px;
                padding: 0;

                li {
                  font-size: 16px;
                  line-height: 25px;
                  color: #003565;
                  font-weight: normal;
                  margin-top: 8px;
                  margin-bottom: 8px;
                  margin: 8px 0;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }

            .register-card-note {
              padding: 15px 0 25px;
              margin: 15px 25px 0;
              font-size: 16px;
              font-weight: normal;
              line-height: 25px;
              letter-spacing: 0;
              border-top: 2px solid #d3d3d3;

              .foot-note {
                margin: 0;
              }
            }
          }

          .button-outer-container {
            background: #0063be;
            display: flex;
            padding: 30px 25px;
            justify-content: center;

            .register-buttons-container {
              margin: 0;
              flex-direction: row;
              gap: 10px;
              display: flex;

              & > div {
                min-height: 139px;
                min-width: 156px;
                display: flex;
                background: #fff;
                border-radius: 10px;
                margin: 0;
              }

              .register-button {
                .button-content-container {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  padding: 18px;
                  align-items: center;

                  .button-label {
                    color: #0063be;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 16px;
                    text-align: center;
                    text-transform: uppercase;
                  }

                  img {
                    display: block;
                    height: auto;
                    max-width: 76px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .MuiTypography-h3,
    .MuiBreadcrumbs-root {
      text-align: center;
    }

    .register-form {
      margin-top: 100px;

      .MuiBreadcrumbs-root {
        display: none;
      }

      .main-title {
        margin: 0;
      }

      .register-form-items {
        form {
          margin-top: 30px;
          max-width: 100%;

          & > div {
            margin: 0;
            max-width: 100%;


            fieldset {
              border: 0;
              max-height: 50px;
              top: 0;
            }

            & > label {
              margin: 34px 0 36px;

              p {
                margin: 0;
              }

              .checkmark {
                height: 23px;
                left: 2px;
                width: 23px;
              }

              .container {
                padding: 0;
              }

              & > span {
                margin-left: 48px;

                a,
                a:visited,
                a:hover {
                  color: $orange-1;
                }
              }
            }
          }

          button {
            display: flex;
            margin: auto;
            max-width: 325px;
            width: 100%;

            span {
              font-weight: bold;
            }
          }
        }

        label {
          &.MuiFormLabel-root {
            &.Mui-focused {
              color: $black-1;
            }
          }
        }
      }

      .checkmark {
        background: transparent;

        &::after {
          top: 1px;
        }
      }
    }

    .register-page {
      background: #f4f8fa;
      width: 100%;
      max-width: 100%;
      padding: 0;
      display: flex;

      .register-form {
        margin: 0;
        max-width: 100%;
        width: 100%;
        padding: 50px 25px;

        &.register-hcp-form {
          max-width: 100%;

          .register-form-inner-container {
            padding: 0;

            form {
              max-width: unset;
              display: flex;
              flex-wrap: wrap;
              gap: 30px;
              justify-content: space-evenly;

              & > * {
                max-width: 361px;
                width: 100%;

                & > div {
                  margin: 0;
                }

                &.Mui-error {
                  max-width: unset;
                }

                .checkbox-label {
                  .checkmark {
                    top: 5px;
                  }
                }
              }
            }
          }
        }

        h1 {
          color: #00c2df;
          font-weight: bold;
          font-size: 30px;
          letter-spacing: 0;
          line-height: 36px;
          text-align: left;
        }

        form {
          display: grid;
          grid-template-columns: 1fr;
          margin-top: 19px;
          row-gap: 19px;

          button {
            height: unset;
            max-width: unset;
            padding: 10.5px 19px;
            margin-top: 20px;
            box-shadow: none;

            span {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0;
              font-weight: normal;
            }
          }

          & > div {
            & > label {
              display: flex;
              margin: 0;
              align-items: flex-start;
            }

            .input-container {
              border-radius: 10px;

              input {
                border: 1px solid #d3d3d3;
                border-radius: 10px;
                padding: 15px 25px 14px;
              }

              input,
              label {
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0;
                line-height: 19px;
              }
            }

            .checkbox-label {
              .checkmark {
                border: 1px solid #d3d3d3;
                outline: none;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                top: 5px;
                left: 0;

                &::after {
                  left: 4px;
                  width: 4px;
                  height: 8px;
                  border-width: 0 1px 1px 0;
                  border-color: #003565;
                }
              }

              .MuiFormControlLabel-label {
                p,
                a {
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 19px;
                  font-weight: normal;
                  color: #003565;
                }
              }
            }
          }
        }

        &.register-patient-form {
          .register-form-inner-container {
            .register-form-items {
              .inner-container {
                & > div {
                  display: flex;
                  flex-direction: column;
                  margin-top: 30px;

                  p {
                    &.Mui-error {
                      margin-left: 21px;

                      &:empty {
                        margin: 0;
                      }
                    }
                  }

                  &.select-grid {
                    margin-top: 0;

                    .select-controller {
                      margin-top: 30px;

                      .MuiInputLabel-shrink {
                        color: #003565;
                        top: -12px;
                        left: -1px;
                      }
                    }
                  }

                  .MuiFormControlLabel-label {
                    margin-left: calc(15px + 20px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.MuiMenu-paper {
  max-width: calc(100% - 50px) !important;

  li {
    white-space: normal;
  }
}
