@import "../_util/_color.scss";

.input-container {
  background: $white-1;
  height: 50px;
  max-width: 100%;
  position: relative;
  display: flex;
  border-radius: 10px;

  input {
    background: none;
    border: 0;
    box-sizing: border-box;
    color: $blue-5;
    display: block;
    font-family: inherit;
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    line-height: 140.5%;
    min-width: 0;
    padding: 15px 16px 15px 16px;
    border: 1px solid #d3d3d3;
    border-radius: 10px;

    &.full-width {
      max-width: 100%;
      width: 100%;
    }

    &:focus,
    &:not(:placeholder-shown) {
      outline: none;

      & ~ label {
        &.animated-label {
          color: #003565 !important;
          left: 0;
          padding-left: 0;
          transform: translate(0px, -35px) scale(0.75);
          width: 195px;
          white-space: nowrap;
        }
      }
    }

    & ~ label {
      color: $blue-5;
      font-size: 17px;
      font-weight: 500;
      line-height: 140.5%;
      padding: 15px 0 15px 0;
      pointer-events: none;
      position: absolute;
      top: -55px;

      &.animated-label {
        font-weight: 300;
        line-height: 140.5%;
        padding: 15px 16px 15px 16px;
        top: 0;
        transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      }
    }
  }

  & ~ p {
    &:not(:empty) {
      margin: 3px 14px 0;
      max-width: 443px;
    }

    &:empty {
      margin: 0;
    }
  }
}

.no-animation {
  .MuiFormLabel-root {
    color: #182c4c;
    font-size: 17px;
    font-weight: 500;
    line-height: 140.5%;
    padding: 15px 0 15px 0;
  }

  .MuiInputLabel-outlined {
    transform: translate(0px, -72px) scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(0px, -72px) scale(1);
  }

  .MuiFormLabel-root.Mui-focused {
    color: black;
  }
}

@media (min-width: 450px) {
  .no-animation {
    .MuiInputLabel-outlined {
      transform: translate(0px, -52px) scale(1);
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -52px) scale(1);
    }
  }
}
