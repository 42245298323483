@media (min-width: 1280px) {
    .page-container {
        .content-wrap {
            .edit-form.toolbox.medications,
            .edit-form.toolbox.appointments {
                &.edit,
                &.create {
                    > div {
                        &:nth-of-type(1) {
                            margin-bottom: 8px;
                        }
                    }
                }

                .date-picker {
                    flex-basis: 378px;
                    margin-right: 31px;

                    .MuiTextField-root {
                        width: 378px;
                    }
                }

                .time-picker {
                    flex-basis: 160px;

                    .MuiTextField-root {
                        width: 160px;
                    }
                }
            }
        }
    }
}
