@media (min-width: 1280px) {
  .cta {
    &-double-container {
      grid-template-columns: repeat(2, 1fr);
      margin-right: 0;
      margin-left: 0;
      padding: 0;

      .cta-container {
        padding: 58px 30px;
      }
    }
  }

  .cta-container {
    padding: 58px 30px;

    .cta-description {
      margin-bottom: 27px;
      max-width: 43%;

      &:empty {
        margin-bottom: 0;
      }

      p {
        margin: 0;
      }
    }
  }
}
