body {
  margin: 0;
  font-family: "Interstate";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: white;
  font-size: 20px;
  line-height: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Interstate';
  src: local('Interstate'),
   url("./resources/fonts/DIN2014-Bold.ttf") format('truetype');
   font-weight: bolder, 800;
   font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: local('Interstate'),
   url("./resources/fonts/interstate-thin-webfont.ttf") format('truetype');
   font-weight: 200;
   font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: local('Interstate'),
   url("./resources/fonts/DIN2014-ExtraLight.ttf") format('truetype');
   font-weight: 300;
   font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: local('Interstate'),
   url("./resources/fonts/DIN2014-Light.ttf") format('truetype');
   font-weight: 400;
   font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: local('Interstate'),
   url("./resources/fonts/DIN2014-Regular.ttf") format('truetype');
   font-weight: 500;
   font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: local('Interstate'),
   url("./resources/fonts/DIN2014-Italic.ttf") format('truetype');
   font-style: italic;
   font-weight: 500;
   font-display: swap;
}
